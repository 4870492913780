import { useEffect, useState } from "react";
import GroupButton from "../common/GroupButton";
import * as S from "./BarPlotChart.styles";
import { Bar } from "@ant-design/plots";
import { TYPE_BTN } from "../../common/constants/typeButton";

const BarPlotChart = ({ data, styleButton, isShowButton = true }) => {
  const [type, setType] = useState(TYPE_BTN.VOLUME);
  const [dataChart, setDataChart] = useState([]);

  useEffect(() => {
    const arrLocation = data && Object.values(data);
    let newDataChart = [];
    if (type === TYPE_BTN.VALUE) {
      const total = arrLocation.reduce((newData, currentData) => {
        return newData + Number(currentData.totalBid);
      }, 0);
      newDataChart = arrLocation.map((item) => ({
        type: item.location ?? "Other",
        sales: Number(((Number(item.totalBid) / total) * 100).toFixed(2)),
      }));
    } else {
      const total = arrLocation.reduce((newData, currentData) => {
        return newData + Number(currentData.totalLot);
      }, 0);
      newDataChart = arrLocation.map((item) => ({
        type: item.location ?? "Other",
        sales: Number(((Number(item.totalLot) / total) * 100).toFixed(2)),
      }));
    }
    setDataChart(newDataChart);
  }, [data, type]);

  const config = {
    height: 327,
    data: dataChart,
    xField: "sales",
    yField: "type",
    meta: {
      type: {
        alias: "类别",
      },
      sales: {
        alias: "Ratio",
      },
    },
    minBarWidth: 20,
    maxBarWidth: 20,
    color: "#4472c4",
    // padding: [10, 20, 80, 30],
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    xAxis: {
      label: {
        formatter: (val) => val + "%",
      },
    },
    tooltip: {
      formatter: (datum) => {
        return { name: datum.type, value: datum.sales + "%" };
      },
    },
  };

  return (
    <>
      <div>
        <Bar {...config} />
        {isShowButton && (
          <GroupButton
            isTop10={true}
            type={type}
            handleSetType={(v) => setType(v)}
            style={styleButton}
          />
        )}
      </div>
    </>
  );
};

export default BarPlotChart;
