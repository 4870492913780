/**
 * @author Peter
 * @email [hoangvanlam9988@gmail.com]
 * @create date 2023-06-13 10:03:17
 * @modify date 2023-06-13 10:13:18
 * @desc [description]
 */

import html2canvas from "html2canvas";

export function downloadImage(domIdName, fileName) {
  html2canvas(document.getElementById(domIdName)).then(function (canvas) {
    var link = document.createElement("a");
    document.body.appendChild(link);
    link.download = fileName ?? domIdName;
    link.href = canvas.toDataURL();
    link.target = "_blank";
    link.click();
  });
}
