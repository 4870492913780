import { useEffect, useState } from "react";
import * as S from "./Loading.styles";
import LoadingLottie from "./LoadingLottie";

var bodyDom = document.getElementsByTagName("body")?.[0];

function Loading({ isLoad, time, children, isNotFixed = false }) {
  const [isLoading, setIsLoading] = useState(isLoad ?? true);

  useEffect(() => {
    bodyDom.style.overflow = "hidden";
    if (time) {
      const timeOut = setTimeout(() => {
        setIsLoading(false);
        bodyDom.style.overflow = "auto";
        clearTimeout(timeOut);
      }, time);
    } else {
      setIsLoading(isLoad);
      bodyDom.style.overflow = "auto";
    }
  }, [isLoad]);

  return (
    <S.Container isLoading={isLoading}>
      {isLoading ? <LoadingLottie isNotFixed={isNotFixed} /> : <>{children}</>}
    </S.Container>
  );
}

export default Loading;
