import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { dataColors, dataLocationShort } from "../../common/constants/dataSelect";
import {
  AUC_COM,
  BID_FILTER,
  MAIN_COLOR,
  MATERIAL,
  NAME_FILTERS,
  NATIONAL,
} from "../../common/constants/filtersName";
import { compareParamsUrlValue, getParamUrlValue } from "../../utils/url";
import CheckboxRender from "./CheckboxRender";
import CheckboxRender2 from "./CheckboxRender2";
import CheckboxRender3 from "./CheckboxRender3";
import FieldsValueFilters from "./FieldsValueFilters";
import * as S from "./FilterForm.style";
import PopoverEl from "./PopoverEl";
import RangeDatePicker from "./RangeDatePicker";
import ButtonDownloadImg from "../ButtonDownloadImg";
import ButtonCus from "../Button";
import queryString from "query-string"

const getDefaultValue = (value) =>
  typeof value === "string" ? [value] : value;

const FilterForm3 = ({
  search = false,
  searchName = false,
  className,
  children,
  onValueChangeFilter = () => { },
  onValueChangeSearch = () => { }
}) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const history = useHistory();
  const [reloadFilter, setReloadFilter] = useState(false);

  const setFilter = (value) => {
    if (typeof value === "string") return [value];
    else return value;
  };

  const defaultValues = {
    transactDate:
      getDefaultValue(getParamUrlValue(location, "transactDate")) ??
      (searchName
        ? [
          moment(`01/01/${new Date().getFullYear()}`).format("YYYY-MM-DD"),
          moment(new Date()).format("YYYY-MM-DD"),
        ]
        : null),
    company: setFilter(getParamUrlValue(location, "company")),
    location: setFilter(getParamUrlValue(location, "location")),
    locationSearch: getParamUrlValue(location, "locationSearch"),
    materialKind: setFilter(getParamUrlValue(location, "materialKind")),
    artistBirth: setFilter(getParamUrlValue(location, "artistBirth")),
    nationality: setFilter(getParamUrlValue(location, "nationality")),
    nationalitySearch: getParamUrlValue(location, "nationalitySearch"),
    querySearch: getParamUrlValue(location, "querySearch"),
    // mainColor: getParamUrlValue(location, "mainColor"),
    // mainColorSearch: getParamUrlValue(location, "mainColorSearch")
  };
  const useFormMethods = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
  } = useFormMethods;

  /**
   *  event search of form search
   * @param {object} values value of form search
   * @returns {void}
   */
  const onSearch = (values) => {
    onValueChangeFilter(values);
  };

  const handleReset = () => {
    setReloadFilter(true);
    onSearch(watch());
  }

  useEffect(() => {
    if (!reloadFilter)
      onValueChangeSearch(watch().querySearch)
    setReloadFilter(false);
  }, [watch().querySearch])
  // call back the form's onSearch function

  return (
    <FormProvider {...useFormMethods}>
      <S.WrapFilter>
        <S.WrapForm onSubmit={handleSubmit(onSearch)}>
          <S.Container>
            <S.WrapInputSearch>
              {searchName && (
                <Controller
                  name="querySearch"
                  control={control}
                  render={({ field }) => (
                    <S.SearchStyle
                      {...field}
                      placeholder="검색어를 입력해주세요."
                      allowClear
                      enterButton={<Button htmlType="submit">{t("SEARCH")}</Button>}
                    />
                  )}
                />
              )}
            </S.WrapInputSearch>
            <S.WrapFilterItem>
              <S.ContainerFilters className={className}>
                <PopoverEl
                  name="transactDate"
                  text={t(BID_FILTER.transactDate)}
                  content={<RangeDatePicker name="transactDate" />}
                />
                <PopoverEl
                  name="company"
                  text={t(NAME_FILTERS.company)}
                  content={<CheckboxRender name="company" options={AUC_COM} />}
                />
                <PopoverEl
                  name={["location", "locationSearch"]}
                  text={t(BID_FILTER.area)}
                  content={
                    <CheckboxRender3
                      name="location"
                      options={dataLocationShort}
                    />
                  }
                />
                <PopoverEl
                  name={["materialKind", "materialSearch"]}
                  text={t(BID_FILTER.material)}
                  content={
                    <CheckboxRender
                      inputSearch
                      name="materialKind"
                      options={MATERIAL}
                    />
                  }
                />
                <PopoverEl
                  name="artistBirth"
                  text={t(BID_FILTER.birthAndDeath)}
                  content={<RangeDatePicker name="artistBirth" />}
                />
                <PopoverEl
                  name={["nationality", "nationalitySearch"]}
                  text={t(BID_FILTER.nationality)}
                  content={
                    <CheckboxRender2
                      inputSearch
                      name="nationality"
                      options={NATIONAL}
                    />
                  }
                />
                {/* <PopoverEl
                name={["mainColor", "mainColorSearch"]}
                text={t(BID_FILTER.mainColor)}
                content={
                  <CheckboxRender
                    nameSearch="mainColorSearch"
                    inputSearch
                    name="mainColor"
                    options={dataColors}
                  />
                }
              /> */}
              </S.ContainerFilters>
            </S.WrapFilterItem>
          </S.Container>
        </S.WrapForm>
      </S.WrapFilter>
      <S.WrapTags>
        <FieldsValueFilters handleDeleteFilter={handleReset}>
          {compareParamsUrlValue(location, watch()) === 0 ? <ButtonCus onClick={() => onSearch(watch())}>
            {t("FILTER")}
          </ButtonCus> : ""}
        </FieldsValueFilters>
      </S.WrapTags>
    </FormProvider>
  );
};

export default FilterForm3;
