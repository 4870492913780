import Lottie from "lottie-react";
import LoadingJson from "../../../../assets/lottie/loading-animation.json";
import * as S from "./LoadingLottie.styles";

function LoadingLottie({ isNotFixed = false }) {
  return (
    <S.BgFixed isNotFixed={isNotFixed}>
      <Lottie animationData={LoadingJson} style={{width: 70}}/>
    </S.BgFixed>
  );
}

export default LoadingLottie;
