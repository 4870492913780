import { TYPE_BTN } from "../../common/constants/typeButton";
import { formatTimeChart } from "../util";

export function formatDatasets(data = []) {
  return data.reduce((newData, currentData) => {
    const winbid = {
      time: currentData.date,
      value: currentData.winbid,
      category: "낙찰가",
    };
    const esMin = {
      time: currentData.date,
      value: currentData.esMin,
      category: "낮은추정가",
    };
    const esMax = {
      time: currentData.date,
      value: currentData.esMax,
      category: "높은추정가",
    };

    newData.push(winbid, esMin, esMax);

    return newData;
  }, []);
}

export function formatDatasetsLotbyPeriod(data = [], type, t) {
  return data.reduce(
    (newData, currentData) => {
      let time = currentData?.timeFilter;
      time = formatTimeChart(time, type);
      const totalBid = {
        time,
        value: currentData?.totalHammerPrice,
      };
      const sold = {
        time,
        count: currentData?.totalSold ? Number(currentData?.totalSold) : 0,
        name: t ? t("NUMBER_OF_WINNING_WORKS_ORTHER") : "낙찰작품수(sold)",
      };
      const totalOfWork = {
        time,
        count: currentData?.totalLot ? Number(currentData?.totalLot) : 0,
        name: t ? t("TOTAL_NUMBER_OF_WORKS_ON_DISPLAY") : "전체출품수",
      };
      newData.dateData.push(totalBid);
      newData.transformData.push(totalOfWork, sold);

      return newData;
    },
    {
      dateData: [],
      transformData: [],
    }
  );
}

export function formatDatasetsResultByPeriod(data = [], type) {
  return data.reduce((newData, currentData) => {
    const date = formatTimeChart(currentData.timeFilter, type);
    const total =
      currentData?.notSold +
      currentData?.below +
      currentData?.above +
      currentData?.within;
    const percentWinbid = (currentData?.within / total) * 100;
    const percentAbove = (currentData?.above / total) * 100;
    const percentBelow = (currentData?.below / total) * 100;
    const percentNotsoild = (currentData?.notSold / total) * 100;
    const winbid = {
      time: date,
      value: percentWinbid,
      category: "Within",
    };
    const above = {
      time: date,
      value: percentAbove,
      category: "Above",
    };
    const below = {
      time: date,
      value: percentBelow,
      category: "Below",
    };
    const notsold = {
      time: date,
      value: percentNotsoild,
      category: "Unsold",
    };

    newData.push(above, winbid, below, notsold);

    return newData;
  }, []);
}

export function formatDatasetsWinningBid(data = [], type, dataHammer = [], t) {
  const handleArrayDataLineChart = (arrayData, field, category) => {
    if (!arrayData && !data.length) return [];
    let nonZeroIndex = 0;
    return arrayData?.map((item, index) => {
      nonZeroIndex = item[field] && index ? index : nonZeroIndex;
      return {
        time: formatTimeChart(item.timeFilter, type),
        value: item[field] || (index > 0 ? arrayData[nonZeroIndex]?.[field] : 0),
        category: category,
      }
    })
  }

  const dataSetsHammer = handleArrayDataLineChart(dataHammer, "hammerPriceMedian", t("MEDIAN_VALUE_OF_WINNING_BID"));
  const dataSetsAvg = handleArrayDataLineChart(data, "avgHammerPrice", t("AVERAGE_VALUE_OF_WINNING_BID"));

  return [...dataSetsAvg,...dataSetsHammer];
}

export function formatDatasetsWinningBid2(data = [], type, dataHammer = [], t) {
  const handleArrayDataLineChart = (arrayData, field, category) => {
    if (!arrayData && !data.length) return [];
    let nonZeroIndex = 0;
    return arrayData?.map((item, index) => {
      nonZeroIndex = item[field] && index ? index : nonZeroIndex;
      return {
        time: formatTimeChart(item.timeFilter, type),
        value: item[field] || (index > 0 ? arrayData[nonZeroIndex]?.[field] : 0),
        category: category,
      }
    })
  }
  const dataSetsHammer = handleArrayDataLineChart(dataHammer, "hammerPricePerSizeTableMedian", t("MEDIAN_VALUE_OF_WINNING_BID_PER_HO"));
  const dataSetsAvg = handleArrayDataLineChart(data, "avgHammerPriceBySizeTable", t("AVERAGE_VALUE_OF_WINNING_BID_PER_HO"));
  return [...dataSetsHammer, ...dataSetsAvg];
}

export function formatDatasetsDistributionByPeriod(data = [], type) {
  return data.reduce((newData, currentData) => {
    // const date = formatTimeChart(currentData.date, type, "dot");
    const date = currentData.date.split("-");
    const dateFormat = new Date(`${date[1]}-${date[0]}-${date[2]}`).getTime();
    const value = Object.assign({}, currentData.bid);
    let maxLength = currentData.bid.length > newData.maxLength ? currentData.bid.length : newData.maxLength;
    newData.data.push({date: dateFormat, ...value});
    newData.maxLength = maxLength;

    return newData;
  }, {data: [], maxLength: 0});
}

// const continentsName = {
//   northAmerica: "북미",
//   southAmerica: "남미",
//   europe: "유럽",
//   africa: "아프리카",
//   northeastAsia: "동북아시아",
//   southeastAsia: "동남아시아",
//   centralWesternAsia: "중앙.서남아시아",
//   oceania: "오세아니아",
// };

const continentsName = {
  northAmerica: "NORTH_AMERICA",
  southAmerica: "SOUTH_AMERICA",
  europe: "EUROPE",
  africa: "AFRICA",
  northeastAsia: "NORTHEAST_ASIA",
  southeastAsia: "SOUTH_EAST_ASIA",
  centralWesternAsia: "CENTRAL_SOUTH_SOUTHWEST_ASIA_ORTHER",
  oceania: "OCEANIA",
};

export function formatDatasetsNationalityByContinents(data = {}, type, t) {
  const result = [];
  for (let key in data) {
    result.push({
      type: t(continentsName[key]),
      sales:
        type === TYPE_BTN.VOLUME
          ? data[key].totalLot
          : data[key].totalHammerPrice,
    });
  }
  const total = result.reduce((total, current) => total + current.sales, 0);
  const newResult = result.map((item) => ({
    ...item,
    sales: (item.sales / total) * 100,
  }));
  return newResult;
}

const countriesName = {
  american: "US",
  british: "UK",
  french: "France",
  german: "Germany",
  spanish: "Spain",
  italian: "Italy",
  chinese: "China",
  japanese: "Japan",
  korean: "Korea",
  other: "Other",
};

export function formatDatasetsNationalityByCountries(data = {}, type) {
  const result = [];
  for (let key in data) {
    result.push({
      type: countriesName[key],
      sales:
        type === TYPE_BTN.VOLUME
          ? data[key].totalLot
          : data[key].totalHammerPrice,
    });
  }
  const total = result.reduce((total, current) => total + current.sales, 0);
  const newResult = result.map((item) => ({
    ...item,
    sales: (item.sales / total) * 100,
  }));
  return newResult;
}
