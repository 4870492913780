import styled from "styled-components";
import { Button, Input, Row, Space } from "antd";
const { Search } = Input;

export const BtnFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 150px;
  height: 50px;
  padding: 8px 32px;
  border-radius: 15px;
  box-shadow: 0px 0px 7.5px rgba(0, 0, 0, 0.129412);
  background-color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1024px) {
    max-width: 120px;
    height: 40px;
  }
  @media screen and (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    padding: 0 8px;
  }

  :hover,
  :focus {
    span {
      opacity: 0.6;
    }
  }
  span {
    font-family: NotoSansKR-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
  }

  &.active-btn span {
    color: #a49039;
  }
`;
export const SearchStyle = styled(Search)`
  &.ant-input-search
    .ant-input-group
    .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 10px;
    height: 50px;
    padding-left: 20px;

    @media screen and (max-width: 1024px) {
      max-width: 140px;
      height: 40px;
    }
    @media screen and (max-width: 768px) {
      min-width: 120px;
      height: 40px;
    }
  }
  .ant-input:placeholder-shown {
    text-overflow: unset;
  }

  &.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    min-width: 60px;
    height: 50px;
    border: none;
    border-radius: 0 10px 10px 0;
    color: #272727;
    background-color: #f5f5f5;
    font-family: NotoSansKR-Medium;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;

    @media screen and (max-width: 1024px) {
      max-width: 50px;
      height: 40px;
    }
    @media screen and (max-width: 768px) {
      min-width: 50px;
      height: 40px;
    }
  }
  &.ant-input-group-wrapper {
    width: auto;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: solid 1px #e8e8e8;
  }
  :hover,
  :focus {
    border: none;
    opacity: 0.8;
  }

  .ant-input-group-addon {
    display: none;
  }
`;
export const ButtonStyle = styled(Button)`
  min-width: 80px;
  /* margin: 0 10px; */
  border: none;
  border-radius: 10px;
  background-color: #f5f5f5;

  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202020;

  :hover,
  :focus {
    color: #202020;
    background-color: #f5f5f5;
    border: none;
    opacity: 0.8;
  }

  @media screen and (max-width: 1024px) {
    min-width: 60px;
    height: 40px;
  }
  @media screen and (max-width: 768px) {
    min-width: 60px;
    height: 40px;
    margin: 0;
  }
`;
export const ContainerFilters = styled(Row)`
  width: 100%;
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 40px auto 0px;
  //height: 88px;
  background-color: #fff;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 14px;
  /* border: 1px solid #A49039; */
  border-radius: 22px;
  gap: 30px;
  /* padding: 0 25px; */

  /* &::before {
    content: "";
    border-width: 20px 20px;
    border-style: solid;
    border-color: transparent transparent #f5f5f5;
    position: absolute;
    left: 6%;
    top: calc(100% - 155%);
  } */

  @media (max-width: 768px) {
    gap: 15px 30px;
    margin-top: 20px !important;
    height: initial !important;
  }

  @media (max-width: 425px) {
    gap: 8px 30px;
    /* padding: 0 14px; */
  }
`;

export const SpaceStyle = styled(Space)`
  width: 100%;
  margin-top: 4px;
  justify-content: flex-start;

  .ant-checkbox-group-item {
    font-family: NotoSansKR-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #3f444d;
  }

  .ant-input {
    border: none;
    padding: 8px 16px 8px 0;
    border-bottom: solid 1px #e8e8e8;
    outline: none;
    font-family: NotoSansKR-Regular;
    font-size: 14px;

    &::placeholder {
      font-family: NotoSansKR-Regular;
      color: #949494;
    }

    &:focus {
      box-shadow: none;
    }
  }

  /* @media screen and (max-width: 768px) {
    padding: 0px 21px;
  }

  @media screen and (max-width: 425px) {
    padding: 0 14px;
  } */
`;

export const WrapFilter = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 18px auto 36px;
  display: flex;
  min-height: 102px;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #a49039;
  border-radius: 22px;
  padding: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px 20px;
    margin: 30px 0;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 25px 15px;
  }
`;

export const WrapTags = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 18px auto 0;
  display: flex;
  justify-content: space-between;
  border-radius: 45px 45px 0 0;
  background: #ffffff;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 7.5px;
  padding: 50px 50px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px 20px;
    margin-top: 30px;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 25px 15px;
  }
`;

export const LiStyle = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #3f444d;
  cursor: pointer;
  :hover,
  :focus {
    opacity: 0.8;
  }
  &.active-li {
    font-family: NotoSansKR-Bold;
    font-weight: 700;
    color: #a49039;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: #a49039;
  }

  &.popover-disabled {
    .filter-level-2 {
      color: #bbb;
    }
    cursor: not-allowed;
  }

`;

export const Summary = styled.div`
  margin-left: 20px;
  flex-grow: 1;
`;

export const ContainerInput = styled.div`
  position: relative;

  input[name="materialSearch"] {
    border-bottom: 1px solid rgb(232, 232, 232);
  }

  input[name="materialSearch"] + svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-input-wrapper {
    border-bottom: 1px solid rgb(232, 232, 232);
  }

  .ant-input {
    border-bottom: none;
  }

  .ant-btn {
    border: none;
  }

  .ant-input-group-addon {
    background-color: #fff;
  }

  .ant-select-auto-complete .ant-select-clear {
    right: 28px;
  }

  &.material-input {
    display: flex;
    border-bottom: 1px solid rgb(232, 232, 232);

    input[name="materialSearch"] {
      border: none;
      padding-bottom: 5px;
    }

    .ant-input-affix-wrapper {
      border: none;
    }

    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    svg {
      margin-top: 6px;
    }
  }
`;
