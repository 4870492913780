import React from "react";
import * as S from "./Title.style";
import IconImg from "../../assets/images/icon.png";

const Title = ({ children }) => {
  return (
    <S.Title>
      <img src={IconImg} alt="icon-title" />
      <span>{children}</span>
    </S.Title>
  );
};

export default Title;
