import { Column, DualAxes, G2, Line, Pie, Scatter } from "@ant-design/plots";
import { Image } from "antd";
import moment from "moment";
import React, { useEffect, useState, useLayoutEffect } from "react";
import GroupButton from "../../components/common/GroupButton";
import {
  formatDatasets,
  formatDatasetsDistributionByPeriod,
  formatDatasetsLotbyPeriod,
  formatDatasetsResultByPeriod,
  formatDatasetsWinningBid,
  formatDatasetsWinningBid2,
} from "../../utils/chart/formatDatasets";
import { formatThousandths } from "../../utils/format/formatSumary";
import { MoneyConvert, labelNumberFormatter } from "../../utils/util";
import DataTable from "../dataTable/DataTable";
import { TYPE_BTN } from "../../common/constants/typeButton";
import { formatTimeChart } from "../../utils/util";
import { useTranslation } from "react-i18next";
import NoImage from "../../assets/images/noimg.jpeg";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function ByMaterialChart({ data = [] }) {
  const { t } = useTranslation("common");
  const [type, setType] = useState(TYPE_BTN.VOLUME);

  const datasets =
    data &&
    data.map((item) => ({
      type: item.materialName,
      value: Number(type === TYPE_BTN.VOLUME ? item.volume : item.value),
    }));

  const config = {
    height: 327,
    // appendPadding: 10,
    data: datasets,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    label: {
      type: "spider",
      labelHeight: 28,
      content: (value) => {
        let percent = Number((value.percent * 100).toFixed(1));
        return `${value.type}\n${percent}%`;
      },
      style: {
        fontWeight: "500",
        fill: "#000",
      },
    },
    color: [
      "#b47b56",
      "#000",
      "#d2b09a",
      "#89888e",
      "#e1cabc",
      "#b2b2b2",
      "#f0e5dd",
    ],
    legend: {
      position: "bottom",
      offsetX: 0,
      flipPage: false,
    },
    meta: {
      value: {
        formatter: (value, index) => {
          return Number((value * 100).toFixed(1)) + "%";
        },
        sync: false,
      },
    },
    responsive: true,
  };
  return (
    <div>
      <div>
        <Pie {...config} />
      </div>
      <GroupButton handleSetType={setType} type={type} isTop10 />
    </div>
  );
}
function Top10AuctionWorks({ data }) {
  const { t } = useTranslation("common");
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      width: "5%",
      align: "center",
      render: (text, object, index) => index + 1,
    },
    {
      title: t("DAY_TRADING"),
      dataIndex: "transact_date",
      key: "transact_date",
      render: (value) => moment(value).format("YYYY-MM-DD"),
      width: "10%",
      align: "left",
    },
    {
      title: t("EXHIBITION_PLACE"),
      dataIndex: "company",
      key: "company",
      width: "10%",
      align: "center",
    },
    {
      title: t("IMAGE"),
      dataIndex: "img",
      key: "img",
      render: (value) => (
        <Image
          width={100}
          src={value}
          style={{
            maxWidth: "50px",
            maxHeight: "50px",
            objectFit: "contain",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = NoImage;
          }}
        />
      ),
      width: "15%",
      align: "center",
    },
    {
      title: t("NAME_OF_THE_WORK"),
      dataIndex: "title",
      key: "title",
      width: "20%",
      align: "center",
    },
    {
      title: `${t("PRODUCT_DIMENSIONS")}(cm)`,
      dataIndex: "width",
      key: "width",
      render: (value, record) =>
        MoneyConvert(record.width, false, 1) +
        "x" +
        MoneyConvert(record.height, false, 1),
      width: "15%",
      align: "center",
    },
    {
      title: t("HO_NUMBER"),
      dataIndex: "size_table",
      key: "size_table",
      align: "center"
    },
    {
      title: t("CRAFTING_YEAR_ORTHER"),
      dataIndex: "mfg_date",
      key: "mfg_date",
      width: 100,
      align: "center",
    },
    {
      title: t("WINNING_PRICE_ORTHER"),
      dataIndex: "hammer_price",
      key: "hammer_price",
      width: 150,
      render: (value) => `₩ ${value ? MoneyConvert(value) : 0}`,
      align: "right",
    },
  ];
  const dataSource = data?.map((item) => {
    return {
      key: item.id,
      ...item,
    };
  });
  return (
    <div style={{ borderRadius: 4 }}>
      <DataTable
        columns={columns}
        data={dataSource}
        pagination={false}
        scroll={{ y: 600, x: 600 }}
      />
    </div>
  );
}

const DualAxesChart = ({ data, styleButton, unit = 1, unitText = "m" }) => {
  const { t } = useTranslation("common");
  const [type, setType] = useState("yearly");
  const [datasets, setDatasets] = useState({
    dateData: [],
    transformData: [],
  });

  useEffect(() => {
    switch (type) {
      case "yearly":
        const dataYearly = formatDatasetsLotbyPeriod(
          data?.yearly,
          undefined,
          t
        );
        setDatasets(dataYearly);
        break;
      case "monthly":
        const dataMonthly = formatDatasetsLotbyPeriod(data?.monthly, type, t);
        setDatasets(dataMonthly);
        break;
      case "half":
        const dataHalf = formatDatasetsLotbyPeriod(data?.half, type, t);
        setDatasets(dataHalf);
        break;
      case "quarter":
        const dataQuarter = formatDatasetsLotbyPeriod(data?.quarter, type, t);
        setDatasets(dataQuarter);
        break;

      default:
        break;
    }
  }, [type, data, t]);

  const { registerTheme } = G2;
  registerTheme("custom-theme", {
    colors10: ["#bf2659", "#febe20", "#00a076"],
    colors20: ["#bf2659", "#febe20", "#00a076"],
  });

  const config = {
    height: 327,
    data: [datasets?.dateData, datasets?.transformData],
    xField: "time",
    yField: ["value", "count"],
    geometryOptions: [
      {
        geometry: "column",
        columnWidthRatio: 0.4,
      },
      {
        geometry: "line",
        seriesField: "name",
      },
    ],
    theme: "custom-theme",
    legend: false,
    meta: {
      value: {
        alias: t("TOTAL_WINNING_BID"),
        formatter: (value, index) => {
          if (value === 0) return value;
          value = formatThousandths(Number(value / unit));
          return value + unitText;
        },
        sync: false,
      },
      count: {
        min: 0
      }
    },
    tooltip: {
      textAlign: "left",
    },
    slider: { start: 0, end: 1 },
  };

  return (
    <>
      <div>
        <DualAxes {...config} />
      </div>
      <GroupButton handleSetType={setType} type={type} style={styleButton} />
    </>
  );
};

function ColumnChart({ data = {}, styleButton }) {
  const { t } = useTranslation("common");
  const [type, setType] = useState("yearly");
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    switch (type) {
      case "yearly":
        const dataYearly = formatDatasetsResultByPeriod(data?.yearly);
        setDatasets(dataYearly);
        break;
      case "monthly":
        const dataMonthly = formatDatasetsResultByPeriod(data?.monthly, type);
        setDatasets(dataMonthly);
        break;
      case "half":
        const dataHalf = formatDatasetsResultByPeriod(data?.half, type);
        setDatasets(dataHalf);
        break;
      case "quarter":
        const dataQuarter = formatDatasetsResultByPeriod(data?.quarter, type);
        setDatasets(dataQuarter);
        break;

      default:
        break;
    }
  }, [type, data, t]);

  const config = {
    height: 327,
    data: datasets,
    xField: "time",
    yField: "value",
    seriesField: "category",
    isPercent: true,
    isStack: true,
    meta: {
      value: {
        min: 0,
        max: 1,
      },
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${v * 100}%`,
      }
    },
    tooltip: {
      formatter: (datum) => {
        return { name: datum.category, value: MoneyConvert(datum.value * 100, false, 1) + "%" };
      },
    },
    slider: { start: 0, end: 1 },
    legend: {
      position: "bottom",
    },
    color: ["#c23162", "#70ad47", "#4472c4", "#ffc000"],
  };

  return (
    <>
      <div>
        <Column {...config} />
      </div>
      <GroupButton handleSetType={setType} type={type} style={styleButton} />
    </>
  );
}

function MedianByPeriodChart({
  data = {},
  styleButton,
  unit = 1,
  unitText = "m",
}) {
  const [type, setType] = useState("yearly");
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    switch (type) {
      case "yearly":
        const dataYearly = formatDatasets(data?.yearly);
        setDatasets(dataYearly);
        break;
      case "monthly":
        const dataMonthly = formatDatasets(data?.monthly);
        setDatasets(dataMonthly);
        break;
      case "half":
        const dataHalf = formatDatasets(data?.half);
        setDatasets(dataHalf);
        break;
      case "quarter":
        const dataQuarter = formatDatasets(data?.quarter);
        setDatasets(dataQuarter);
        break;

      default:
        break;
    }
  }, [type, data]);

  const config = {
    height: 327,
    data: datasets,
    xField: "time",
    yField: "value",
    seriesField: "category",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ["#1979C9", "#D62A0D", "#FAA219"],
    point: {
      size: 5,
      style: {
        lineWidth: 1,
        fillOpacity: 1,
      },
      shape: (item) => "circle",
    },
    meta: {
      value: {
        alias: "낙찰가",
        formatter: (value, index) => {
          if (value === 0) return value;
          value = formatThousandths(Number(value / unit));
          return value + unitText;
        },
        sync: false,
      },
    },
  };

  return (
    <>
      <div>
        <Line {...config} />
      </div>
      <GroupButton handleSetType={setType} type={type} style={styleButton} />
    </>
  );
}

function WinningBid({
  data = {},
  styleButton,
  unit = 1,
  unitText = "m",
  dataHammer = {},
}) {
  const { t } = useTranslation("common");
  const [type, setType] = useState("yearly");
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    switch (type) {
      case "yearly":
        const dataYearly = formatDatasetsWinningBid(
          data?.yearly,
          type,
          dataHammer?.hammerPriceMedian?.yearly,
          t
        );
        setDatasets(dataYearly);
        break;
      case "monthly":
        const dataMonthly = formatDatasetsWinningBid(
          data?.monthly,
          type,
          dataHammer?.hammerPriceMedian?.monthly,
          t
        );
        setDatasets(dataMonthly);
        break;
      case "half":
        const dataHalf = formatDatasetsWinningBid(
          data?.half,
          type,
          dataHammer?.hammerPriceMedian?.half,
          t
        );
        setDatasets(dataHalf);
        break;
      case "quarter":
        const dataQuarter = formatDatasetsWinningBid(
          data?.quarter,
          type,
          dataHammer?.hammerPriceMedian?.quarter,
          t
        );
        setDatasets(dataQuarter);
        break;
      default:
        break;
    }
  }, [type, data, t]);

  const config = {
    height: 327,
    data: datasets,
    xField: "time",
    yField: "value",
    seriesField: "category",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => labelNumberFormatter(v, unitText)
        // `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ["#D62A0D", "#1979C9", "#FAA219"],
    point: {
      size: 5,
      style: {
        lineWidth: 1,
        fillOpacity: 1,
      },
      shape: (item) => "circle",
    },
    meta: {
      value: {
        alias: "낙찰가",
        formatter: (value, index) => {
          // if (value === 0) return value;
          value = MoneyConvert(Number(value / unit), false, 1);
          return value + unitText;
        },
        sync: false,
      },
    },
    legend: {
      position: "bottom",
    },
  };

  return (
    <>
      <div>
        <Line {...config} />
      </div>
      <GroupButton handleSetType={setType} type={type} style={styleButton} />
    </>
  );
}

function WinningBid2({
  data = {},
  styleButton,
  unit = 1,
  unitText = "m",
  dataHammer = {},
}) {
  const { t } = useTranslation("common");
  const [type, setType] = useState("yearly");
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    switch (type) {
      case "yearly":
        const dataYearly = formatDatasetsWinningBid2(
          data?.yearly,
          type,
          dataHammer?.hammerPricePerSizeTableMedian?.yearly,
          t
        );
        setDatasets(dataYearly);
        break;
      case "monthly":
        const dataMonthly = formatDatasetsWinningBid2(
          data?.monthly,
          type,
          dataHammer?.hammerPricePerSizeTableMedian?.monthly,
          t
        );
        setDatasets(dataMonthly);
        break;
      case "half":
        const dataHalf = formatDatasetsWinningBid2(
          data?.half,
          type,
          dataHammer?.hammerPricePerSizeTableMedian?.half,
          t
        );
        setDatasets(dataHalf);
        break;
      case "quarter":
        const dataQuarter = formatDatasetsWinningBid2(
          data?.quarter,
          type,
          dataHammer?.hammerPricePerSizeTableMedian?.quarter,
          t
        );
        setDatasets(dataQuarter);
        break;
      default:
        break;
    }
  }, [type, data, t]);

  const config = {
    height: 327,
    data: datasets,
    xField: "time",
    yField: "value",
    seriesField: "category",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => labelNumberFormatter(v, unitText),
        // `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ["#1979C9", "#D62A0D", "#FAA219"],
    point: {
      size: 5,
      style: {
        lineWidth: 1,
        fillOpacity: 1,
      },
      shape: (item) => "circle",
    },
    meta: {
      value: {
        alias: "낙찰가",
        formatter: (value, index) => {
          // if (value === 0) return value;
          value = MoneyConvert(Number(value / unit), false, 1);
          return value + unitText;
        },
        sync: false,
      },
    },
    legend: {
      position: "bottom",
    },
  };

  return (
    <>
      <div>
        <Line {...config} />
      </div>
      <GroupButton handleSetType={setType} type={type} style={styleButton} />
    </>
  );
}

function BidMedianTrend({ data = {}, styleButton, unit = 1, unitText = "m" }) {
  const { t } = useTranslation("common");
  const [type, setType] = useState("yearly");
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    const arrEsimateMin = handleArrayDataLineChart(data?.estimateMinMedian, "estimateMinMedian", t("ESTIMATE_MIN"));
    const arrEsimateMax = handleArrayDataLineChart(data?.estimateMaxMedian, "estimateMaxMedian", t("ESTIMATE_MAX_OTHER"));
    const arrHammerPrice = handleArrayDataLineChart(data?.hammerPriceMedian, "hammerPriceMedian", t("MEDIAN_VALUE_OF_WINNING_BID"));
    setDatasets([...arrEsimateMax, ...arrHammerPrice, ...arrEsimateMin]);
  }, [type, data, t]);

  const handleArrayDataLineChart = (arrayData, field, category) => {
    if (!arrayData?.[type]) return [];
    let nonZeroIndex = 0;
    return arrayData?.[type].map((item, index) => {
      nonZeroIndex = item[field] && index ? index : nonZeroIndex;
      return {
        time: formatTimeChart(item.timeFilter, type),
        value: item[field] || (index > 0 ? arrayData?.[type][nonZeroIndex]?.[field] : 0),
        category: category,
      }
    })
  }

  const config = {
    height: 327,
    data: datasets,
    xField: "time",
    yField: "value",
    seriesField: "category",
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => labelNumberFormatter(v, unitText),
        // `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ["#1979C9", "#D62A0D", "#FAA219"],
    point: {
      size: 5,
      style: {
        lineWidth: 1,
        fillOpacity: 1,
      },
      shape: (item) => "circle",
    },
    meta: {
      value: {
        alias: "낙찰가",
        formatter: (value, index) => {
          // if (value === 0) return value;
          value = MoneyConvert(Number(value / unit), false, 1);
          return value + unitText;
        },
        sync: false,
      },
    },
    legend: {
      position: "bottom",
    },
  };

  return (
    <>
      <div>
        <Line {...config} />
      </div>
      <GroupButton handleSetType={setType} type={type} style={styleButton} />
    </>
  );
}

const TICK_STATE = [
  "DAY",            // < 3 months
  "WEEK",           // < 4 years
  "MONTH",          // < 8 years
  "YEAR"            // > 8 years
]

function ScatterChart({ data = {}, styleButton, unit = 1, unitText = "m" }) {
  const [type, setType] = useState("yearly");
  // const [datasets, setDatasets] = useState([]);
  // const [lastPoint, setLastPoint] = useState();
  // const [firstPoint, setFirstPoint] = useState();
  // const [sliderStart, setSliderStart] = useState();
  // const [sliderEnd, setSliderEnd] = useState();

  // const [tickState, setTickState] = useState(TICK_STATE[0]);
  // const [tickCount, setTickCount] = useState(90);

  const dataSet = formatDatasetsDistributionByPeriod(data?.monthly, type);
  const newData = dataSet.data.sort((a, b) => {
    if (a.date > b.date) return 1;
    return -1;
  })

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new("chartdiv");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX"]
    });

    root.numberFormatter.setAll({
      numberFormat: "#a",
      smallNumberThreshold: 0.1,
      bigNumberPrefixes: [
        { "number": unit, "suffix": unitText },
      ],
      smallNumberPrefixes: []
    });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        pinchZoomX: true
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 40
    });
    xRenderer.grid.template.set("location", 0.5);
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
      fontSize: 12,
      fill: "#999999",
      rotation: -35,
      centerY: am5.p50,
    });

    var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0,
      baseInterval: {
        timeUnit: "day",
        count: 1
      },
      gridIntervals: [
        { timeUnit: "day", count: 1 },
        { timeUnit: "day", count: 4 },
        { timeUnit: "week", count: 1 },
        { timeUnit: "week", count: 2 },
        { timeUnit: "month", count: 1 },
        { timeUnit: "month", count: 2 },
        { timeUnit: "year", count: 1 },
      ],
      renderer: xRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));
    xAxis.get("dateFormats")["day"] = "yyyy-MM-dd";
    xAxis.get("periodChangeDateFormats")["day"] = "yyyy-MM-dd";
    xAxis.get("dateFormats")["week"] = "yyyy-MM-dd";
    xAxis.get("periodChangeDateFormats")["week"] = "yyyy-MM-dd";
    xAxis.get("dateFormats")["month"] = "yyyy-MM";
    xAxis.get("periodChangeDateFormats")["month"] = "yyyy-MM";

    var yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: false
    })
    yRenderer.labels.template.setAll({
      fontSize: 12,
      fill: "#999999"
    });
    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#a",
        maxPrecision: 0,
        renderer: yRenderer
      })
    );


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    function createSeries(name, field) {
      var series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          valueXField: "date",
          fill: "#1890ff90",
          stroke: "#0000",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{valueX}: {valueY}"
          })
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 4,
            fill: series.get("fill"),
          })
        });
      });

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      series.set("setStateOnChildren", true);
      series.states.create("hover", {});

      series.mainContainer.set("setStateOnChildren", true);
      series.mainContainer.states.create("hover", {});

      series.strokes.template.states.create("hover", {
        strokeWidth: 4
      });

      series.data.setAll(newData);
      series.appear(1000);

      // let zoomEnd = new Date(newData[newData.length-1].date);
      // zoomEnd = moment(zoomEnd).add(3, "day");
      // const zoomStart = moment(zoomEnd).subtract(2, "month");
      // // Pre-zoom X axis
      // series.events.once("datavalidated", function(ev, target) {
      //   xAxis.zoomToDates(new Date(zoomStart), new Date(zoomEnd));
      // })
    }

    for (let i = 0; i < dataSet.maxLength; i++) {
      createSeries(`${i}`, `${i}`)
    }

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    let scrollbarX = am5.Scrollbar.new(root, {
      orientation: "horizontal",
    });
    chart.set("scrollbarX", scrollbarX);
    chart.bottomAxesContainer.children.push(scrollbarX);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [type, newData]);

  return (
    <div id="chartdiv" style={{ width: "100%", height: 350 }}></div>
  );
}

export {
  ByMaterialChart,
  ColumnChart,
  DualAxesChart,
  MedianByPeriodChart,
  ScatterChart,
  Top10AuctionWorks,
  WinningBid,
  WinningBid2,
  BidMedianTrend,
};
