import { createGlobalStyle } from "styled-components";
import {
  NotoSansKrBlack,
  NotoSansKrBold,
  NotoSansKrLight,
  NotoSansKrMedium,
  NotoSansKrRegular,
  NotoSansKrThin,
} from "../../assets/fonts/font";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'NotoSansKR-Thin';
    src: local('NotoSansKR-Thin'), url(${NotoSansKrThin}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKR-Light';
    src: local('NotoSansKR-Light'), url(${NotoSansKrLight}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKR-Regular';
    src: local('NotoSansKR-Regular'), url(${NotoSansKrRegular}) format('opentype');
  }

  @font-face {
      font-family: 'NotoSansKR-Medium';
      src: local('NotoSansKR-Medium'), url(${NotoSansKrMedium}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKR-Bold';
    src: local('NotoSansKR-Bold'), url(${NotoSansKrBold}) format('opentype');
  }

  @font-face {
    font-family: 'NotoSansKR-Black';
    src: local('NotoSansKR-Black'), url(${NotoSansKrBlack}) format('opentype');
  }

  * {
    margin: 0;
    font-family: 'NotoSansKR-Regular', sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: #202020;
      box-shadow: inset 0 0 20px 20px #ffffff;
  }
  input[type="search"] {
      -webkit-appearance: none !important;
    }
  input::-moz-selection { /* Code for Firefox */
    background: #dddddd;
  }
  input::selection {
    background: #dddddd;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    scroll-behavior: smooth;
  }

  html:focus-within {
    scroll-behavior: smooth;
  }

  /* #root{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  } */

  body {
    width: 100%;
    height: 100vh;
  }

  a, button {
    text-decoration: none;
    cursor: pointer;
    border: none;
    outline: none;
  }

  p {
    margin-bottom: 0;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    max-width: 230px;
    background-color: #a49039;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(180, 185, 171, 0.3);
    background-color: #f5f5f5;
  }

  .ant-popover-inner {
    border-radius: 10px;
    min-width: 250px;
  }

  .ant-popover-inner-content {
    padding: 24px;
  }

  .g2-tooltip-list-item {
    text-align: left;
  }

  .g2-tooltip-title {
    text-align: left;
  }

  .ant-btn {
    box-shadow: none;
  }

  .ant-popover-inner {
    min-width: auto;
    padding: 16px 0;
   
  }

 .ant-popover-inner-content {
    max-height: 500px;
    overflow-y: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export default GlobalStyles;
