import { getKey, removeKey } from "../../App";
import types from "../actions/types";

const defaultState = getKey("Authorization") ?? null;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case types.USER_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case types.REMOVE_USER_INFO: {
      removeKey("Authorization");
      state = null;
      return state;
    }
    default:
      return state;
  }
};
