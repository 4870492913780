import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  min-width: 130px;
  height: 50px;
  border-radius: 11px;
  margin-left: 14px;
  /* background-image: linear-gradient(to bottom, #b291ff, #A49039); */
  background-color: #a49039;

  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  :focus,
  :hover {
    opacity: 0.9;
    background-color: #a49039;
    border: none;
    outline: none;
    color: #fff;
  }
  /* @media screen and (max-width: 1024px) {
    width: 80px;
    height: 40px;
  }
  @media screen and (max-width: 768px) {
    min-width: 100px;
    height: 40px;
  } */

  &:disabled {
    background: #a8a69d !important;
    color: #6a6a6a !important;
    opacity: 1 !important;
    border-color: #a8a69d !important;
  }
`;
