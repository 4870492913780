import { combineReducers } from "redux";
import userReducer from "../reducers/userReducer";
import chartReducer from "./chartReducer";
import langReducer from "./langReducer";
import loadingReducer from "./loadingReducer";

export default combineReducers({
  user: userReducer,
  chart: chartReducer,
  lang: langReducer,
  isLoading: loadingReducer
});
