import styled, { css } from "styled-components";
import BgImage from "../../assets/images/footer-bg.png";
import BgCardImage from "../../assets/images/bg-card.png";
import BgLine from "../../assets/images/bg_line.png";
import BgMobile from "../../assets/images/bg_mobile.png";

export const Main = styled.div`
  width: 100%;
`;

export const MainHead = styled.div`
  padding: 194px 0 0;
  background-image: url(${BgImage});
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 768px) {
    padding: 162px 15px 0;
    background-position: left top -235px;
  }
`;

export const MainHeadInner = styled.div`
  display: flex;
  margin: 59px auto 0;
  max-width: 1398px;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: block;
  }

  @media screen and (max-width: 425px) {
    margin-top: 88px;
  }
`;

export const MainHeadLeft = styled.div`
  width: calc(50% - 15px);
  background-image: url(${BgCardImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 54px 33px 29px 31px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 32px;
    padding-top: 63px;
    padding: 63px 22px 32px 24px;
  }

  /* @media screen and (max-width: 425px) {
    padding-right: 10px;
  } */
`;

export const MainHeadRight = styled.div`
  width: calc(50% - 15px);
  background-image: url(${BgCardImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 54px 33px 0 27px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-top: 63px;
    padding: 63px 33px 35px 27px;
  }

  @media screen and (max-width: 425px) {
    padding-right: 16px;
    padding-left: 24px;
  }
`;

export const TitleHeadCard = styled.div`
  display: flex;
  align-items: center;

  img {
    max-width: 21px;
    max-height: 18px;
    margin-right: 10px;
  }
`;

export const MainTitle = styled.div`
  text-align: left;
  font-family: NotoSansKR-Medium;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  line-height: 29px;
  text-transform: uppercase;
  color: #a49039;
  margin-right: 3px;
  white-space: nowrap;
`;

export const SubTitle = styled.div`
  text-align: left;
  font-family: NotoSansKR-Regular;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  line-height: 22px;
  color: #a49039;
  white-space: nowrap;
`;

export const ContentYoyChange = styled.div`
  margin-top: 34px;

  .left,
  .right {
    width: 50%;
  }

  .topYoy {
    padding-bottom: 23px;
    border-bottom: 1px dashed #d5d5d5;
  }

  .bottomYoy {
    padding-top: 23px;
  }

  .itemYoy {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .subItemYoy {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;

    &.red {
      color: #cb141a;
    }

    &.blue {
      color: #0d59de;
    }
  }

  .labelYoy {
    font-family: NotoSansKR-Regular;
    font-size: 28px;
    font-weight: 400;
    font-style: normal;
    line-height: 35px;
    color: #a49039;
  }

  .ValueYoy {
    font-family: NotoSansKR-Bold;
    font-size: 50px;
    font-weight: 700;
    font-style: normal;
    line-height: 72px;
    color: #2e2e2e;

    span {
      font-weight: normal;
      font-size: 18px;
      font-family: NotoSansKR-Regular;
    }
  }

  .ratioYoy {
    font-family: NotoSansKR-Regular;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    line-height: 35px;
  }

  .subRatioYoy {
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 23px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 44px;

    .topYoy {
      padding-bottom: 27px;
    }

    .bottomYoy {
      padding-top: 32px;
    }

    .subItemYoy {
      gap: 9px;
    }

    .ValueYoy {
      font-size: 36px;
      line-height: 1.5;
      font-weight: bold;
    }

    .ratioYoy,
    .subRatioYoy {
      font-weight: normal;
      line-height: 1.5;
    }
  }

  @media screen and (max-width: 425px) {
    margin-top: 29px;

    .labelYoy {
      font-size: 18px;
      line-height: 1.5;
    }

    .itemYoy {
      display: block;
    }

    .ValueYoy {
      line-height: 1.46;
      font-size: 28px;
      margin-bottom: 6px;
    }

    .subItemYoy {
      gap: 8px;
      justify-content: flex-start;
    }

    .ratioYoy {
      font-size: 20px;
      line-height: 1.45;
    }

    .subRatioYoy {
      font-size: 14px;
      line-height: 1.43;
    }

    .bottomYoy {
      padding-top: 23px;
    }
  }
`;

export const ContentLastMonth = styled.div`
  margin-top: 12px;

  .wrap-chart {
    margin-top: 70px;
  }
`;

export const WrapChartCard = styled.div`
  margin: 0 auto;
  max-width: 1398px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1397px) {
    padding: 32px;
  }

  @media screen and (max-width: 540px) {
    gap: 32px;
  }
`;

export const WrapCardArtist = styled.div`
  margin: 116px auto 0;
  max-width: 1398px;

  @media screen and (max-width: 768px) {
    margin-top: 78px;
  }

  @media screen and (max-width: 425px) {
    margin-top: 73px;
  }
`;

export const RisingArtistMain = styled.div`
  flex-shrink: 0;
  padding-top: 31.6px;
  display: flex;
  justify-content: center;
`;

export const WrapTopCategory = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const ItemTopThisYear = styled.div`
  width: calc(25% - 24px);

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 327px;
    margin: 0 auto;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const WrapYearsChage = styled.div`
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    bottom: 234px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${BgLine});
    background-repeat: no-repeat;
    background-position: right bottom;
    z-index: -1;

    @media screen and (max-width: 768px) {
      background-image: url(${BgMobile});
      top: 0;
      background-position: left top 43px;
    }

    @media screen and (max-width: 425px) {
      background-position: left -63px top 19px;
    }
  }
`;

export const InnerYearsChage = styled.div`
  margin: 129px auto 0;
  max-width: 1398px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    padding: 77px 15px 0;
  }

  @media screen and (max-width: 425px) {
    padding-top: 60px;
  }
`;

export const BoxItem = styled.div`
  border: 1px solid #a49039;
  padding: 18px 20px 30px;
  border-radius: 20px;
  background-color: white;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 45px;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }

  canvas {
    object-fit: contain;
  }

  @media screen and (max-width: 768px) {
    padding: 14px 3px 27px;
    min-height: 500px;

    canvas {
      /* max-height: 280px; */
      object-fit: cover;
    }

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &.chart1 {
      canvas {
        height: 353px !important;
      }

      .content-chart1 {
        margin-top: 19px;
      }
    }

    &.chart2 {
      canvas {
        height: 290px !important;
      }

      .content-chart2 {
        margin-top: 25px;
      }
    }

    &.chart3 {
      canvas {
        height: 300px !important;
      }

      .content-chart3 {
        margin-top: 21px;
      }
    }

    &.chart4 {
      canvas {
        height: 320px !important;
      }

      .content-chart4 {
        margin-top: 6px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    min-height: 450px;
    padding: 3px 0 12px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &.chart1 {
      canvas {
        height: 353px !important;
      }

      .content-chart1 {
        margin-top: 14px;
      }
    }

    &.chart2 {
      .content-chart2 {
        margin-top: 14px;
      }
    }

    &.chart3 {
      .content-chart3 {
        margin-top: 14px;
      }
    }

    /* &.chart4 {
      canvas {
        height: 320px !important;
      }

      .content-chart4 {
        margin-top: 6px;
      }
    } */
  }
`;

export const InnerBoxItem = styled.div`
  padding: 20px;
`;

export const BoxInner = styled.div`
  background-color: white;
  /* height: 408px; */
  width: 100%;
  text-align: center;
  align-items: center;
  white-space: normal;

  ${({ doughnut }) =>
    doughnut
      ? css`
          canvas {
            height: 380px !important;
            object-fit: contain;
          }
        `
      : ""}

  ${({ customHeight }) =>
    customHeight
      ? css`
          & > div:first-child {
            height: unset !important;
          }
        `
      : ""}
`;

export const Title = styled.div`
  width: 100%;
  /* margin-top: 20px; */
  font-family: NotoSansKR-Bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: left;
  color: #a49039;
  word-break: keep-all;
  white-space: nowrap;

  @media screen and (max-width: 425px) {
    font-size: 18px;
    line-height: 1.5;
  }
`;

export const MainTitleYearChange = styled.div`
  text-align: center;
  font-family: NotoSansKR-Bold;
  font-size: 47px;
  font-weight: 700;
  font-style: normal;
  line-height: 68px;
  color: #a49039;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 1.5;
  }
`;

export const SubTitleYearChange = styled.div`
  text-align: center;
  font-family: NotoSansKR-Regular;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  line-height: 29px;
  color: #a49039;
  margin-bottom: 34px;

  @media screen and (max-width: 768px) {
    font-weight: normal;
    margin-bottom: 44px;
  }

  @media screen and (max-width: 425px) {
    margin-bottom: 24px;
  }
`;

export const MainTitlePage = styled.div`
  text-align: center;
  font-family: NotoSansKR-Medium;
  font-size: 85px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  color: #a49039;

  span {
    font-family: NotoSansKR-Regular;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    font-size: 56px;
  }

  @media screen and (max-width: 425px) {
    font-size: 38px;
  }
`;

export const SubTitlePage = styled.div`
  text-align: center;
  font-family: NotoSansKR-Medium;
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #a49039;

  @media screen and (max-width: 768px) {
    line-height: 1.48;
  }

  @media screen and (max-width: 425px) {
    font-size: 18px;
    line-height: 1.5;
  }
`;

export const RowHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 18px;

  @media screen and (max-width: 768px) {
    margin-bottom: 0 !important;
  }
`;
