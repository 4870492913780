export function formatDatasetsAuctionOccupy(datas = []) {
  return datas.reduce((newData, currentData) => {
    newData.push({
      type: currentData.auction,
      value: currentData.occupy
        ? currentData.occupy
        : 0,
    });
    return newData;
  }, []);
}

export function formatDatasetsTop10(datas = []) {
  return datas.reduce(
    (newData, currentData) => {
      if (currentData.hammer !== 0 || currentData.bid !== 0) {
        newData.arrArtist.push(currentData.artist);
        newData.arrHammer.push(currentData.hammer);
        newData.arrBid.push(currentData.bid);
      }
      return newData;
    },
    {
      arrArtist: [],
      arrHammer: [],
      arrBid: [],
    }
  );
}

export function formatDatasetsArtMarketShare(datas = []) {
  const total = datas.reduce(
    (total, currentData) => total + currentData.value,
    0
  );
  return datas.map((item) => ({
    ...item,
    value: Number(((item.value / total) * 100).toFixed(2)),
  }));
}
