import styled, { css } from "styled-components";

export const BgFixed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  animation: none;
  backdrop-filter: blur(0.5px);
  ${({ isNotFixed }) =>
    isNotFixed
      ? css`
          width: 100%;
          height: 100%;
          margin-top: 100px;
        `
      : css`
          width: 100%;
          height: 100vh;
          position: fixed;
          background-color: #ffffff99;
          top: 0;
          left: 0;
        `}
`;
