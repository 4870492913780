import styled from "styled-components";

export const WrapArtMarket = styled.div`
  position: relative;
  z-index: 1;

  .ant-container {
    min-height: 50px;
    height: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`;

export const Container = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  min-height: 942px;
  margin: 0 auto;
  padding: 50px 35px 71px 50px;
  border-radius: 0 0 45px 45px;
  box-shadow: 0px 5px 7.5px rgba(0, 0, 0, 0.129412);
  background-color: #fff;
  position: relative;

  @media screen and (max-width: 768px) {
    border-radius: 0 0 20px 20px;
    padding: 30px 20px 77px;
  }

  @media screen and (max-width: 425px) {
    padding: 30px 15px 60px;
  }
`;

export const Content = styled.div`
  background-color: white;
  border: solid 0.5px rgb(196, 196, 196);
  border-radius: 50px;
  padding: 50px;
  box-shadow: 0px 0px 7.5px 0px #00000021;
  height: 550px;
`;

export const FilterWrapper = styled.div`
  border: 1px solid #a49039;
  border-radius: 22px;
  display: flex;
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 18px auto 0;
  padding: 6px;
  display: flex;
  align-items: center;
  padding: 38px 25px;

  form {
    width: 100%;
  }

  .custom-arrow {
    margin-top: 0;
    height: auto;
  }

  /* .ant-btn-default {
    margin-left: 25px;
  } */

  @media screen and (max-width: 768px) {
    margin-top: 30px;
    padding: 25px 20px;

    .custom-arrow {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 425px) {
    padding: 18px 14px;

    .custom-arrow {
      gap: 4px 30px;
    }
  }
`;
