import { mainAxios } from "../libs/axios";

export const loginApi = (payload) => {
  return mainAxios.request({
    methodType: "POST",
    url: `/crawling/login`,
    payload: payload,
    config: {
      headers: {
        contentType: "application/json",
      },
    },
  });
};
export const mainApi = () => {
  return mainAxios.getRequest({
    url: `/crawling/main`,
    requiresToken: true,
  });
};
export const mainApi1 = () => {
  return mainAxios.getRequest({
    url: `/crawling/main1`,
    requiresToken: true,
  });
};
/**
 * used to get data total Bid
 * @param {object} payload
 * @returns {http-response}
 */
export const totalBidApi = (payload) => {
  // return mainAxios.getRequest({
  //   url: `/crawling/totalbid`,
  //   requiresToken: true,
  //   params: payload,
  // });
  return mainAxios.postRequest({
    url: `/crawling/totalbid`,
    requiresToken: true,
    payload: payload,
  });
};

export const exportExcelBidApi = (payload) => {
  return mainAxios.postRequest({
    url: `/crawling/totalbid-export`,
    requiresToken: true,
    payload: payload,
    config: {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob",
    },
  });
};

/**
 * used to get data search auction
 * @param {object} payload
 * @returns {http-response}
 */
export const searchApi = (payload) => {
  return mainAxios.postRequest({
    url: `/crawling/search`,
    requiresToken: true,
    payload: payload,
  });
};

export async function downloadExcelAuction(formData, typeA) {
  return await mainAxios.postRequest({
    url: "/crawling/export-excel-datasearch",
    payload: formData,
    requiresToken: true,
    config: {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: typeA,
    },
  });
}

export const artistApi = (params) => {
  return mainAxios.getRequest({
    url: `/crawling/artist?${params}`,
    requiresToken: true,
  });
};

export const wordcompletionApi = (word) => {
  return mainAxios.getRequest({
    url: `/crawling/wordcompletion?artist=${word}`,
    requiresToken: true,
  });
};

export const artistApiSearch = (payload) => {
  return mainAxios.postRequest({
    url: `/crawling/new-artist`,
    requiresToken: true,
    payload: payload,
  });
};

export const getTopTenWorks = (payload) => {
  return mainAxios.postRequest({
    url: `/crawling/winning-rate-and-bid`,
    requiresToken: true,
    payload: payload,
  });
};

export const getArtistName = (payload) => {
  return mainAxios.getRequest({
    url: `/crawling/auto-complete-artist`,
    requiresToken: true,
    params: payload,
  });
};

export const getSelectLevel2Data = (payload) => {
  return mainAxios.postRequest({
    url: '/api/v1/auction/data-after-filter',
    requiresToken: true,
    payload: payload,
  })
}

export const getSelectLevel2DataArtist = (payload) => {
  return mainAxios.postRequest({
    url: '/crawling/level2-filters',
    requiresToken: true,
    payload: payload,
  })
}