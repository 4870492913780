import debounce from "lodash.debounce";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { filterArtMarketApi } from "../../apis/artMarket";
import { HTTP_STATUS_CODE } from "../../common/constants/httpsConstant";
import ArtMarketChart from "../../components/ArtMarket/ArtMarketChart";
import FilterFormMarket from "../../components/filterForm/FilterFormMarket";
import Title from "../../containers/title/Title";
import { formatDataFilterArtMarket } from "../../utils/format/formatDataFilter";
import { addParamUrl } from "../../utils/url";
import * as S from "./ArtMarket.styles";
import types from "../../store/actions/types";
import { useDispatch } from "react-redux";

function ArtMarket() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(null);
  const [summaryFilter, setSummaryFilter] = useState(null);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [errFilter, setErrFilter] = useState("");
  const [filter, setFilter] = useState(() => {
    const getValuesUrl = queryString.parse(history.location.search);
    return {
      ...getValuesUrl,
    };
  });

  /**
   * used to get data art market from api
   * @param {object} valuesForm - value form
   * @return {void}
   */
  const getDataArtMarket = async (valuesForm = {}) => {
    try {
      dispatch({type: types.SET_LOADING, payload: {isLoading: true}});
      addParamUrl(history, valuesForm);
      const dataFilter = formatDataFilterArtMarket(valuesForm);

      setErrFilter("");
      setLoadingFilter(true);

      const filterDataResponse = await filterArtMarketApi(dataFilter);
      if (filterDataResponse.result) {
        setFilterData(filterDataResponse.resp);
        setSummaryFilter(filterDataResponse.summary);
        setLoadingFilter(false);
      }
      if (!filterDataResponse.result) {
        if (Object.values(HTTP_STATUS_CODE).includes(filterDataResponse.err)) {
          setFilterData(null);
          setSummaryFilter(null);
          setErrFilter(filterDataResponse.err_desc);
          setLoadingFilter(false);
          return;
        }
        window.location.replace("/login");
      }
    } catch (e) {
      setFilterData(null);
      setSummaryFilter(null);
      setErrFilter("");
      setLoadingFilter(false);
    } finally {
      dispatch({type: types.SET_LOADING, payload: {isLoading: false}});
    }
  };

  /**
   * event when submit form
   */
  const handleValueChangeFilter = async (values) => {
    // getDataArtMarket(values);
    setFilter({ ...filter, ...values });
  };

  useEffect(() => {
    getDataArtMarket(filter);
  }, [filter]);

  return (
    <S.WrapArtMarket>
      <Title>Market anlys</Title>
      <FilterFormMarket
        onValueChangeFilter={handleValueChangeFilter}
        className="custom-arrow align-items-right"
      />
      <S.Container>
        <ArtMarketChart
          summaryInfo={summaryFilter}
          dataArtMarket={filterData}
          loading={loadingFilter}
          err={errFilter}
        />
      </S.Container>
    </S.WrapArtMarket>
  );
}

export default ArtMarket;
