import styled from "styled-components";
import BgImage from "../../assets/images/footer-bg.png";
import BgMobileImage from "../../assets/images/login-bg.png";

export const Container = styled.div`
  .header-login {
    margin-bottom: 0;
  }
`;

export const Login = styled.div`
  background-image: url(${BgImage});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 140px;

  @media (max-width: 768px) {
    background-image: url(${BgMobileImage});
    background-size: 100%;
    padding-top: 180px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 425px) {
    padding-top: 110px;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 450px;

  @media (max-width: 768px) {
    margin: 0 45px;
  }
  @media (max-width: 425px) {
    margin: 0 15px;
  }
`;

export const SubTitle = styled.div`
  text-align: center;
  margin: 6px 0 21px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #a49039;
`;

export const FormLogin = styled.div`
  padding: 65px 50px;
  position: relative;

  .form-login {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.06;
    border-radius: 15px;
    background-color: #a49039;
  }

  button {
    width: 100%;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    margin-top: 15px;
    border-radius: 10px;
    /* background-image: linear-gradient(to bottom, #b291ff, #A49039); */
    background-color: #a49039;
    border: none;
    outline: none;
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;

    &:hover {
      background-color: initial;
      background-color: #a4903999;
      color: #fff;
    }

    &:focus {
      background-color: initial;
      background-color: #a4903999;
      color: #fff;
    }

    &:disabled {
      background-image: none;
      background-color: #afafaf;
      color: #6a6a6a;
      cursor: not-allowed;
    }
  }

  .ant-btn[disabled] {
    &:hover {
      background-image: none;
      background-color: #afafaf;
      color: #6a6a6a;
      cursor: not-allowed;
    }
  }

  .ant-form-item-explain {
    margin-bottom: 10px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const WrapInput = styled.div`
  margin-bottom: 10px;

  .ant-input {
    height: 50px;
    padding: 0 23.5px;
    border-radius: 10px;
    background-color: #fff;
    text-align: left;
    font-family: NotoSansKR-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #a19e9e;
    border: none;
    color: #202020;

    &::placeholder {
      font-family: NotoSansKR-Regular;
      font-size: 15px;
      color: #a19e9e;
    }
  }
`;

export const Message = styled.div`
  margin-top: 10px;
  text-align: center;
  color: red;
  font-family: NotoSansKR-Regular;
  font-size: 15px;
`;
