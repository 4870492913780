import { ArrowUpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import * as S from "./OnToTop.styles";

const OnToTop = () => {
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (event) => {
    const position = window.pageYOffset;
    const isShow = position > 300;
    setIsScroll(isShow);
    if (event.cancelable) event.preventDefault();
  };

  const handleOnClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <S.Container onClick={handleOnClick} className={isScroll ? "show" : ""}>
      <ArrowUpOutlined />
    </S.Container>
  );
};

export default OnToTop;
