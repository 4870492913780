import React, { useEffect, useState } from "react";
import { loginApi } from "../../apis/auth";
import { setKey } from "../../App";
import Title from "../../containers/title/Title";
import types from "../../store/actions/types";
import * as S from "./Login.styles";

import { Button, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { URL } from "../../common/constants/endpoints";

function Login() {
  const [mess, setMess] = useState("");
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const statebackUrl = history?.location?.state;
  
  /**
   * event when press button submit
   * @param {values} - value form
   * @returns {void}
   */
  const onFinish = async (values) => {
    try {
      const response = await loginApi(values);
      if (response.result) {
        setKey("Authorization", response.resp.token);
        dispatch({
          type: types.USER_INFO,
          payload: response.resp,
        });
        statebackUrl?.fromURL 
          ? history.push(statebackUrl?.fromURL) 
          : history.push(URL.MAIN);
      } else {
        setMess(response.err_desc);
      }
    } catch (error) {
      setMess(error?.err_desc);
    }
  };

  useEffect(() => {
    forceUpdate({});
  }, []);

  if (!navigator.cookieEnabled) {
    alert(
      "브라우저의 쿠키가 차단되어있습니다. 기능을 사용하시려면 쿠키를 허용해주세요."
    );
  }
  return (
    <S.Container>
      <S.Login>
        <S.Content>
          <Title>Login</Title>
          <S.SubTitle>
            Please, write your account ID and password to login
          </S.SubTitle>
          <S.FormLogin>
            <Form form={form} className="form-login" onFinish={onFinish}>
              <Form.Item
                name="id"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <S.WrapInput>
                  <Input placeholder="ID" />
                </S.WrapInput>
              </Form.Item>
              <Form.Item
                name="pw"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <S.WrapInput>
                  <Input
                    placeholder="Your password"
                    type="password"
                    autoComplete="password"
                  />
                </S.WrapInput>
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    htmlType="submit"
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Login
                  </Button>
                )}
              </Form.Item>
              {mess && <S.Message>{mess}</S.Message>}
            </Form>
          </S.FormLogin>
        </S.Content>
      </S.Login>
    </S.Container>
  );
}

export { Login };
