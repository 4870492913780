import { Space } from "antd";
import * as S from "./GroupButton.styles";
import { TYPE_BTN } from "../../../common/constants/typeButton";
import React from "react";
import { useTranslation } from "react-i18next";

function GroupButton({
  type,
  handleSetType,
  isTop10 = false,
  isLocation = false,
  style,
}) {
  const { t } = useTranslation("common");

  return (
    <S.Container style={style}>
      <Space align="center">
        {isTop10 ? (
          <>
            <S.ContainerInner>
              <S.ButtonFilter
                type="primary"
                size="small"
                onClick={() => handleSetType(TYPE_BTN.VOLUME)}
                className={type === TYPE_BTN.VOLUME ? "item-active" : ""}
              >
                {TYPE_BTN.VOLUME}
              </S.ButtonFilter>
            </S.ContainerInner>
            <S.ContainerInner>
              <S.ButtonFilter
                type="primary"
                size="small"
                onClick={() => handleSetType(TYPE_BTN.VALUE)}
                className={type === TYPE_BTN.VALUE ? "item-active" : ""}
              >
                {TYPE_BTN.VALUE}
              </S.ButtonFilter>
            </S.ContainerInner>
          </>
        ) : isLocation ? (
          <>
            <S.ContainerInner>
              <S.ButtonFilter
                type="primary"
                size="small"
                onClick={() => handleSetType(TYPE_BTN.VALUE)}
                className={type === TYPE_BTN.VALUE ? "item-active" : ""}
              >
                Bid
              </S.ButtonFilter>
            </S.ContainerInner>
            <S.ContainerInner>
              <S.ButtonFilter
                type="primary"
                size="small"
                onClick={() => handleSetType(TYPE_BTN.VOLUME)}
                className={type === TYPE_BTN.VOLUME ? "item-active" : ""}
              >
                Lot
              </S.ButtonFilter>
            </S.ContainerInner>
          </>
        ) : (
          <>
            <S.ContainerInner>
              <S.ButtonFilter
                type="primary"
                size="small"
                onClick={() => handleSetType(TYPE_BTN.YEARLY)}
                className={type === TYPE_BTN.YEARLY ? "item-active" : ""}
              >
                {t("YEAR")}
              </S.ButtonFilter>
            </S.ContainerInner>
            <S.ContainerInner>
              <S.ButtonFilter
                type="primary"
                size="small"
                onClick={() => handleSetType(TYPE_BTN.HALF)}
                className={type === TYPE_BTN.HALF ? "item-active" : ""}
              >
                {t("HALF_A_YEAR")}
              </S.ButtonFilter>
            </S.ContainerInner>
            <S.ContainerInner>
              <S.ButtonFilter
                type="primary"
                size="small"
                onClick={() => handleSetType(TYPE_BTN.QUARTER)}
                className={type === TYPE_BTN.QUARTER ? "item-active" : ""}
              >
                {t("PRECIOUS")}
              </S.ButtonFilter>
            </S.ContainerInner>
            <S.ContainerInner>
              <S.ButtonFilter
                type="primary"
                size="small"
                onClick={() => handleSetType(TYPE_BTN.MONTHLY)}
                className={type === TYPE_BTN.MONTHLY ? "item-active" : ""}
              >
                {t("MONTH")}
              </S.ButtonFilter>
            </S.ContainerInner>
          </>
        )}
      </Space>
    </S.Container>
  );
}

export default React.memo(GroupButton);
