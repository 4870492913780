export function isEmpty(arg) {
  return (
    arg == null ||
    arg.length === 0 ||
    (typeof arg === "object" && Object.keys(arg).length === 0)
  );
}

export function checkValidForm(valuesForm = {}) {
  return Object.keys(valuesForm).some((key) => {
    if (valuesForm[key]?.length > 0) return true;
    return false;
  });
}

export function checkValidImage(image) {
  let urlImage = "";
  if (JSON.stringify(image).includes("[")) {
    const arrImg = JSON.parse(image);
    if (!arrImg || arrImg.length <= 0) return "";
    urlImage = arrImg[0].image_url;
  } else {
    urlImage = image;
  }
  return urlImage;
}
