import { Layout } from "antd";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import LogoMain from "../../assets/images/logo.png";
import LogoTrans from "../../assets/images/logo_transparent.png";

export const Container = styled(Layout)`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 0 auto;
  background: none;
  @media screen and (max-width: 768px) {
    padding: 0 15px;

    .space {
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
`;
export const HeaderBg = styled.div`
  background-color: ${({ main }) => (main ? "inherit" : "#A49039")};
  margin: 0 0 79px;
  ${({ main }) =>
    main
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 99;
        `
      : ""}

  ${({ bgFixed, main }) =>
    bgFixed && main
      ? css`
          background-color: rgb(255 255 255 / 90%) !important;
          box-shadow: 0 3px 15px 0 rgb(0 0 0 / 16%);
        `
      : ""}

  &.header-menu {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`;
export const HeaderMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;

  @media screen and (max-width: 768px) {
    height: 90px;
  }
`;
export const Logo = styled.div`
  background-image: ${({ main }) =>
    main ? `url(${LogoMain})` : `url(${LogoTrans})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 158px;
  height: 62px;

  @media (max-width: 768px) {
    min-width: 120px;
    height: 52px;
    background-size: contain;
  }
`;
export const NavbarLink = styled(Link)`
  text-decoration: none;
`;
export const Button = styled.button`
  width: 120px;
  height: 40px;
  border-radius: 11px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ main }) => (main ? "#A49039" : "#fff")};
  color: ${({ main }) => (main ? "#fff" : "#A49039")};

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    width: 100px;
    height: 40px;
    margin-left: 24px;
    font-size: 15px;
  }

  @media screen and (max-width: 425px) {
    width: 90px;
    height: 35px;
    margin-left: 15px;
  }
`;
export const NavText = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 23px;
  letter-spacing: normal;
  text-align: left;

  color: ${({ main }) => (main ? "#262626" : "#EFE7C4")};
  &:hover,
  &:focus {
    opacity: 0.6;
    color: ${({ main }) => (main ? "#262626" : "#EFE7C4")};
  }
  &.active-link {
    color: #fff;
    font-weight: bold;
  }
  &.main {
    color: #a49039;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;
export const BtnText = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
`;

export const Sidebar = styled.div`
  display: flex;
  gap: 50px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SidebarMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    flex-grow: 1;
  }
`;

export const NavbarMobile = styled.div`
  margin-bottom: 12px;
`;

export const NavTextMobile = styled.div`
  color: #000;
  cursor: pointer;
  &.active-link {
    color: #a49039;
  }
  &.main {
    color: #a49039;
    font-weight: 700;
  }
`;

export const BarsIcon = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .line {
    width: 100%;
    height: 2px;

    background-color: ${({ main }) => (main ? "#A49039" : "#fff")};
  }
`;

export const Action = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: center;

  @media screen and (max-width: 768px) {
    gap: 20px;
    flex-direction: row-reverse;
  }
`;
