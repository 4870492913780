import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DataTable from "../../containers/dataTable/DataTable";
import { MoneyConvert, checkSort } from "../../utils/util";

const TotalBidDataTable = ({
  data,
  pageTable,
  onChangePageCurrent = () => {},
  load,
  onChangeSorter,
  sorterData,
}) => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (load) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [load]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 60,
      render: (text, record, index) => index + 1,
    },
    {
      title: t("THE_WRITER_S_NAME"),
      dataIndex: "artist",
      key: "artist",
      align: "center",
      width: 250,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "artist_eng"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("YEAR_OF_BIRTH"),
      dataIndex: "artistBirth",
      key: "artistBirth",
      align: "center",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "artist_birth"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("YEAR_OF_DEATH"),
      dataIndex: "artistDeath",
      key: "artistDeath",
      align: "center",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "artist_death"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("NATIONALITY"),
      dataIndex: "nationality1",
      key: "nationality1",
      align: "center",
      width: 100,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "nationality1"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("NUMBER_OF_WORKS_ON_DISPLAY"),
      dataIndex: "totalLot",
      key: "totalLot",
      align: "center",
      render: (value) => MoneyConvert(value),
      sorter: (a, b) => null,
      showSorterTooltip: false,
      width: 100,
      defaultSortOrder:
        sorterData?.field === "total_lot" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("NUMBER_OF_WINNING_WORKS"),
      dataIndex: "winLot",
      key: "winLot",
      align: "center",
      render: (value) => MoneyConvert(value),
      sorter: (a, b) => null,
      showSorterTooltip: false,
      width: 100,
      defaultSortOrder:
        sorterData?.field === "win_lot" ? checkSort(sorterData?.order) : null,
    },
    {
      title: "낙찰률",
      dataIndex: "bidRate",
      key: "bidRate",
      align: "center",
      render: (value) => MoneyConvert(value, false, 1) + "%",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "bid_rate" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("TOTAL_WINNING_BID"),
      dataIndex: "total_winbid",
      key: "total_winbid",
      align: "center",
      width: 250,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      render: (text, record, index) => (
        <div>
          <div> ₩ {MoneyConvert(record?.totalBid)}</div>
          <div>$ {MoneyConvert(record?.usd)}</div>
        </div>
      ),
      defaultSortOrder:
        sorterData?.field === "total_bid" ? checkSort(sorterData?.order) : null,
    },
    {
      title: `YOY (${t("TOTAL_WINNING_BID")})`,
      dataIndex: "yoy",
      key: "yoy",
      align: "center",
      sorter: (a, b) => null,
      render: (text) => `${MoneyConvert(text, false, 1)}%`,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "yoy" ? checkSort(sorterData?.order) : null,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      pagination={pageTable}
      onChangePageCurrent={onChangePageCurrent}
      onChange={onChangeSorter}
      isLoading={isLoading}
    />
  );
};

export default TotalBidDataTable;
