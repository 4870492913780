import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mainApi, mainApi1 } from "../../apis/auth";
import IconBread from "../../assets/images/icon.png";
import ButtonDownloadImg from "../../components/ButtonDownloadImg";
import HomeCard from "../../components/Main/HomeCard";
import StackedColumnChart from "../../components/StackedColumnChart";
import TableList from "../../components/TableList";
import TreeChart from "../../components/TreeChart";
import Loading from "../../components/common/Loading";
import { RatioEntriesAndBid } from "../../containers/Chart/ChartSecondary";
import { useConvertDataCard } from "../../utils/convertDataCard";
import { downloadImage } from "../../utils/downloadChartImg";
import { dateToYMD } from "../../utils/format/formatDate";
import { MoneyConvert } from "../../utils/util";
import * as S from "./Main.styles";

const date = new Date();

function Main() {
  const { t } = useTranslation("common");
  const [data, setData] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateChart, setDateChart] = useState({
    start: "",
    end: "",
  });
  const [lastMonth, setLastMonth] = useState({
    start: "",
    end: "",
  });

  const getAllData = () => {
    Promise.all([mainApi(), mainApi1()])
      .then((res) => {
        setData({ ...res[0].resp, ...res[1].resp });
        setStartDate(
          res[1].resp.firstDayOfYear.slice(2, 10).replaceAll("-", ".")
        );
        setEndDate(res[1].resp.lastDayOfYear.slice(2, 10).replaceAll("-", "."));
        setDateChart({
          start: res[0].resp?.firstDay.slice(2, 10).replaceAll("-", "."),
          end: res[0].resp?.lastDay.slice(2, 10).replaceAll("-", "."),
        });
        setLastMonth({
          start: res[1].resp.yoyLastMonth.preFirstDate
            ? res[1].resp.yoyLastMonth.preFirstDate
                .slice(2, 10)
                .replaceAll("-", ".")
            : "",
          end: res[1].resp.yoyLastMonth.preLastDate
            ? res[1].resp.yoyLastMonth.preLastDate
                .slice(2, 10)
                .replaceAll("-", ".")
            : "",
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <Loading isLoad={!data}>
        <S.Main>
          <S.MainHead>
            <S.MainTitlePage>Market Now</S.MainTitlePage>
            <S.SubTitlePage>최근 업데이트: {dateToYMD(date)}</S.SubTitlePage>
            <S.MainHeadInner>
              <S.MainHeadLeft>
                <S.TitleHeadCard>
                  <img src={IconBread} alt="icon.png" />
                  <S.MainTitle>YOY Change</S.MainTitle>
                  <S.SubTitle>
                    ({startDate} - {endDate})
                  </S.SubTitle>
                </S.TitleHeadCard>
                <S.ContentYoyChange>
                  <div className="topYoy">
                    <div className="itemYoy">
                      <div className="labelYoy">{t("TOTAL_WINNING_BID")}</div>
                      <div className="ValueYoy">
                        {MoneyConvert(data?.yoy?.total_winbid)}
                        <span>(원)</span>
                      </div>
                    </div>
                    <div
                      className={`subItemYoy ${
                        data?.yoy?.yoy > 0 ? "red" : "blue"
                      }`}
                    >
                      {data?.yoy?.yoy > 0 ? (
                        <ArrowUpOutlined style={{ fontSize: "20px" }} />
                      ) : (
                        <ArrowDownOutlined style={{ fontSize: "20px" }} />
                      )}

                      <div className="ratioYoy">
                        {data?.yoy?.yoy
                          ? MoneyConvert(data?.yoy?.yoy.toFixed(1), false, 1)
                          : 0}
                        %
                      </div>
                      <div className="subRatioYoy">
                        (
                        {data?.yoy?.yoysum
                          ? MoneyConvert(data?.yoy?.yoysum)
                          : 0}
                        )
                      </div>
                    </div>
                  </div>
                  <div className="bottomYoy">
                    <div className="itemYoy">
                      <div className="labelYoy">{t("BID_WINNING_RATE")}</div>
                      <div className="ValueYoy">
                        {data?.biddroping?.winbid_rate
                          ? MoneyConvert(
                              data?.biddroping?.winbid_rate,
                              false,
                              1
                            ) + "%"
                          : 0}
                      </div>
                    </div>
                    <div
                      className={`subItemYoy ${
                        data?.biddroping?.bid_rate_change > 0 ? "red" : "blue"
                      }`}
                    >
                      <div className="ratioYoy">
                        {data?.biddroping?.bid_rate_change > 0 ? (
                          <ArrowUpOutlined style={{ fontSize: "20px" }} />
                        ) : (
                          <ArrowDownOutlined style={{ fontSize: "20px" }} />
                        )}
                        <span style={{ marginLeft: "6px" }}>
                          {data?.biddroping?.bid_rate_change
                            ? MoneyConvert(
                                data?.biddroping?.bid_rate_change,
                                false,
                                1
                              )
                            : 0}
                          %p
                        </span>
                      </div>
                    </div>
                  </div>
                </S.ContentYoyChange>
              </S.MainHeadLeft>
              <S.MainHeadRight>
                <S.TitleHeadCard>
                  <img src={IconBread} alt="icon.png" />
                  <S.MainTitle>Last Month</S.MainTitle>
                  <S.SubTitle>
                    ({lastMonth.start} - {lastMonth.end})
                  </S.SubTitle>
                </S.TitleHeadCard>
                <S.ContentLastMonth>
                  <TableList
                    data={{
                      numberWorksOutStock: data?.yoyLastMonth?.worksOnDisplay
                        ? MoneyConvert(data?.yoyLastMonth?.worksOnDisplay)
                        : 0,
                      numberWorksAuction: data?.yoyLastMonth?.tenderWorks
                        ? MoneyConvert(data?.yoyLastMonth?.tenderWorks)
                        : 0,
                      totalBidAmount: data?.yoyLastMonth?.tenderMoney
                        ? MoneyConvert(data?.yoyLastMonth?.tenderMoney)
                        : 0,
                      biddingRate: data?.yoyLastMonth?.biddingRate
                        ? MoneyConvert(
                            data?.yoyLastMonth?.biddingRate,
                            false,
                            1
                          )
                        : 0,
                    }}
                  />
                  <div className="wrap-chart">
                    <TreeChart
                      data={[
                        {
                          ratio: data?.yoyLastMonth?.above
                            ? Number(data?.yoyLastMonth?.above).toFixed(1)
                            : 0,
                          label: "Above",
                        },
                        {
                          ratio: data?.yoyLastMonth?.within
                            ? Number(data?.yoyLastMonth?.within).toFixed(1)
                            : 0,
                          label: "Within",
                        },
                        {
                          ratio: data?.yoyLastMonth?.below
                            ? Number(data?.yoyLastMonth?.below).toFixed(1)
                            : 0,
                          label: "Below",
                        },
                        {
                          ratio: data?.yoyLastMonth?.notsold
                            ? Number(data?.yoyLastMonth?.notsold).toFixed(1)
                            : 0,
                          label: "Unsold",
                        },
                      ]}
                    />
                  </div>
                </S.ContentLastMonth>
              </S.MainHeadRight>
            </S.MainHeadInner>
          </S.MainHead>
          <S.WrapYearsChage>
            <S.InnerYearsChage>
              <S.MainTitleYearChange>5 YEARS Change</S.MainTitleYearChange>
              <S.SubTitleYearChange>
                ({dateChart.start} - {dateChart.end})
              </S.SubTitleYearChange>
              <S.BoxItem className="chart1">
                <S.InnerBoxItem id="fiveYearsChange">
                  <S.RowHead>
                    <S.Title>{t("TREND_OF_TOTAL_WINNINGS_NUMBER_OF_WORKS_ON_DISPLAY")}</S.Title>
                    <ButtonDownloadImg
                      handleOnClick={() =>
                        downloadImage("fiveYearsChange", "fiveYearsChange")
                      }
                    />
                  </S.RowHead>
                  <S.BoxInner customHeight className="content-chart1">
                    <RatioEntriesAndBid
                      datas={data?.lotByPeriod ?? []}
                      unitText="억"
                      unit={100000000}
                    />
                  </S.BoxInner>
                </S.InnerBoxItem>
              </S.BoxItem>
              <S.BoxItem className="chart2">
                <S.InnerBoxItem id="successfulBidRateTrend">
                  <S.RowHead>
                    <S.Title>{t("TENDER_WINNING_RATE_TREND")}</S.Title>
                    <ButtonDownloadImg
                      handleOnClick={() =>
                        downloadImage(
                          "successfulBidRateTrend",
                          "successfulBidRateTrend"
                        )
                      }
                    />
                  </S.RowHead>
                  <S.BoxInner className="content-chart2">
                    <StackedColumnChart
                      colors={["#c23162", "#70ad47", "#4472c4", "#ffc000"]}
                      data={data?.resultByPeriod ?? []}
                      typeChart="RESULT"
                    />
                  </S.BoxInner>
                </S.InnerBoxItem>
              </S.BoxItem>
              <S.BoxItem className="chart3">
                <S.InnerBoxItem id="marketShareTrend">
                  <S.RowHead>
                    <S.Title>{t("TREND_OF_MARKET_SHARE_RATIO")}</S.Title>
                    <ButtonDownloadImg
                      handleOnClick={() =>
                        downloadImage("marketShareTrend", "marketShareTrend")
                      }
                    />
                  </S.RowHead>
                  <S.BoxInner className="content-chart3">
                    <StackedColumnChart
                      colors={["#b1174d", "#ef7a91", "#d8d8d8"]}
                      data={data?.occupyByPeriod ?? []}
                      ishowLabel={true}
                      typeChart="OCCUPY"
                      height={400}
                      mt={16}
                    />
                  </S.BoxInner>
                </S.InnerBoxItem>
              </S.BoxItem>
              <S.BoxItem className="chart4">
                <S.InnerBoxItem id="onlineOrOfflineSpecificGravity">
                  <S.RowHead>
                    <S.Title>{t("TREND_OF_ON_OFF_RATIO")}</S.Title>
                    <ButtonDownloadImg
                      handleOnClick={() =>
                        downloadImage(
                          "onlineOrOfflineSpecificGravity",
                          "onlineOrOfflineSpecificGravity"
                        )
                      }
                    />
                  </S.RowHead>
                  <S.BoxInner className="content-chart4">
                    <StackedColumnChart
                      colors={["#4472c4", "#ed7d31"]}
                      data={data?.onoffByPeriod ?? []}
                      typeChart="ONOFF"
                      height={416}
                      mt={11}
                    />
                  </S.BoxInner>
                </S.InnerBoxItem>
              </S.BoxItem>
            </S.InnerYearsChage>
          </S.WrapYearsChage>
          <S.WrapCardArtist>
            <S.MainTitleYearChange>TOP THIS YEAR</S.MainTitleYearChange>
            <S.SubTitleYearChange style={{ marginBottom: "44px" }}>
              ({startDate} - {endDate})
            </S.SubTitleYearChange>
            <S.WrapTopCategory>
              {useConvertDataCard(data).map((val, index) => (
                <S.ItemTopThisYear key={index}>
                  <HomeCard data={val} />
                </S.ItemTopThisYear>
              ))}
            </S.WrapTopCategory>
          </S.WrapCardArtist>
        </S.Main>
      </Loading>
    </>
  );
}

export default Main;
