import styled, { css } from "styled-components";

export const WrapToal = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #989898;

  span {
    margin-right: 5px;
  }
`;

export const ResultContent = styled.div`
  @media screen and (max-width: 425px) {
    .ant-col {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

export const WrapChart = styled.div``;

export const BoxItem = styled.div`
  border: 1px solid #a49039;
  border-radius: 45px;
  min-height: 500px;

  h2 {
    font-size: 16px;
    font-weight: bold;
  }

  @media screen and (max-width: 768px) {
    &.chart-cover {
      canvas {
        max-height: 320px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    border-radius: 20px;
  }
`;

export const BoxInner = styled.div`
  background-color: white;
  border-radius: 45px;
  /* min-height: 400px; */
  width: 100%;
  text-align: center;
  align-items: center;
  white-space: normal;

  ${({ customHeight }) =>
    customHeight
      ? css`
          & > div:first-child {
            height: unset !important;
          }
        `
      : ""}
`;

export const Title = styled.div`
  width: 100%;
  /* margin-top: 20px; */
  font-family: NotoSansKR-Bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 35px;
  letter-spacing: normal;
  text-align: left;
  color: #a49039;
  word-break: keep-all;

  @media screen and (max-width: 425px) {
    font-size: 18px;
    line-height: 27px;
  }
`;

export const Group = styled.div`
  padding: 45px 0 0;
`;

export const BoxGray = styled.div`
  border-radius: 45px;
  background-color: #f5f5f5;
`;

export const TitleMainGroup = styled.div`
  font-family: NotoSansKR-Bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #202020;
  margin-bottom: 14px;
`;

export const TextGroup = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6e6e6e;

  &.ml-60 {
    margin-left: 60px;
  }
`;

export const Summary = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    gap: 10px 20px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 425px) {
    gap: 8px;
  }
`;

export const ItemSummary = styled.div`
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f9f5e3;
  border-radius: 24px;
  padding: 0 17px;
  width: calc(25% - 15px);

  @media screen and (max-width: 768px) {
    width: calc(50% - 10px);
  }

  @media screen and (max-width: 425px) {
    padding: 0;
    width: calc(50% - 4px);
  }
`;

export const Lable = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 23px;
  letter-spacing: normal;
  color: #2e2e2e;
  text-align: center;
`;

export const Value = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 52px;
  letter-spacing: normal;
  color: #000;
  text-align: center;

  span {
    font-size: 16px;
    font-family: NotoSansKR-Regular;
    font-weight: normal;
    line-height: 23px;
  }

  .icon-down {
    color: #2549fb;
    font-size: 28px;
    margin-right: 5px;
  }

  .icon-up {
    color: #ff0000;
    font-size: 28px;
    margin-right: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 45px;
  }

  @media screen and (max-width: 425px) {
    font-size: 22px;
    line-height: 33px;
  }
`;

export const BoxItemHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
  padding-top: 22px;
`;

export const RowHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 18px;
`;

export const InnerBoxItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;

  .ant-btn-icon-only.ant-btn-sm {
    width: 40px;
    height: 35px;
    border-radius: 8px;
    border: 1px solid #e6e6e6;
  }

  &.custom-label {
    padding-left: 35px;
    padding-right: 35px;

    @media screen and (max-width: 768px) {
      padding-right: 32px;
      padding-left: 36px;
    }
  }
`;

export const BoxInnerWrap = styled.div`
  padding: 35px 35px 40px;

  @media screen and (max-width: 768px) {
    padding: 34px 32px 15px 36px;
  }

  @media screen and (max-width: 425px) {
    padding: 39px 15px 15;
  }
`;
