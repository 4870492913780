import * as S from "./TreeChart.styles";

const backgroundColor = ["#c23162", "#70ad47", "#4472c4", "#ffc000"];

const TreeChart = ({ data = [] }) => {
  return (
    <S.Container>
      {data.map((item, index) => (
        <S.Item key={index} ratio={item?.ratio ?? 0}>
          <S.LabelRatio>
            <S.Label style={{ color: backgroundColor[index] }}>
              {item?.label}
            </S.Label>
            <S.Ratio ratio={item?.ratio ?? 0}>
              {item?.ratio}
              <span>%</span>
            </S.Ratio>
          </S.LabelRatio>
          <S.Line style={{ backgroundColor: backgroundColor[index] }}></S.Line>
        </S.Item>
      ))}
    </S.Container>
  );
};

export default TreeChart;
