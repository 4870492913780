import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  CERTIFICATION_MAP,
  NAME_FILTERS,
  ON_OFF_MAP,
  PRICE_MAP,
} from "../../../common/constants/filtersName";
import "./style.css";
import { TfiClose } from "react-icons/tfi";
import { MoneyConvert } from "../../../utils/util";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { compareParamsUrlValue } from "../../../utils/url";

const Chip = ({ label, content, handleDeleteFilter }) => {
  const { reset, watch } = useFormContext();
  const [fieldValue, setFieldValue] = useState(null);
  const { t } = useTranslation("common");
  const location = useLocation();
  //used to format data for Chip component
  useEffect(() => {
    if (content) {
      switch (label) {
        case "pricetp": {
          const value = PRICE_MAP[content];
          return setFieldValue(value);
        }
        case "onOff":
        case "certification": {
          const value =
            label === "onOff"
              ? ON_OFF_MAP[content]
              : CERTIFICATION_MAP[content];

          return setFieldValue(value);
        }
        case "transactDate":
        case "mfgDate": {
          const min = content[0] ? content[0] : "";
          const max = content[1] ? content[1] : "";
          const value = `${min} ~ ${max}`;
          return setFieldValue(value);
        }
        case "height":
        case "width":
        case "sizeTable": 
        case "price": {
          const min = content[0] ? MoneyConvert(content[0], false, 1) : "";
          const max = content[1] ? MoneyConvert(content[1], false, 1) : "";
          const value = `${min} ~ ${max}`;
          return setFieldValue(value);
        }
        default: {
          if (Array.isArray(content)) {
            const value = content.join(", ");
            return setFieldValue(value);
          }
          return setFieldValue(content);
        }
      }
    }
  }, [label, JSON.stringify(content)]);

  // reset when close all chip
  const handleResetFilterEmpty = () => {
    const tagsElement = document.getElementsByClassName("chip");
    if (tagsElement.length === 1 && compareParamsUrlValue(location, watch()) === 0) {
      handleDeleteFilter?.(true);
    }
  }

  return (
    <>
      {content?.length > 0 && (
        <div className="chip">
          <div className="chip-head">{t(NAME_FILTERS[label])}:</div>
          <div className="chip-content">{fieldValue}</div>
          <div
            className="chip-close"
            onClick={() => {
              reset((formValues) => ({
                ...formValues,
                [label]: undefined,
              }));
              handleResetFilterEmpty();
            }}
          >
            <TfiClose />
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(Chip);
