import { Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { dataColors, dataLocationShort, dataMethods, dataPreferences } from "../../common/constants/dataSelect";
import {
  AUC_COM,
  BID_CLASS,
  BID_FILTER,
  MATERIAL,
  NAME_FILTERS,
  ON_OFF_LIST,
} from "../../common/constants/filtersName";
import { getParamUrlValue, compareParamsUrlValue } from "../../utils/url";
import CheckboxActive from "./CheckboxActive";
import CheckboxRender from "./CheckboxRender";
import CheckboxRender2 from "./CheckboxRender2";
import FieldsValueFilters from "./FieldsValueFilters";
import * as S from "./FilterForm.style";
import PopoverEl from "./PopoverEl";
import RangeDatePicker from "./RangeDatePicker";
import SelectMinMax from "./SelectMinMax";
import { useTranslation } from "react-i18next";
import { getSelectLevel2Data } from "../../apis/auth";
import ButtonCus from "../Button";

const getDefaultValue = (value) =>
  typeof value === "string" ? [value] : value;

const FilterForm = ({
  filterPage,
  search = false,
  searchName = false,
  className,
  children,
  onValueChangeFilter = () => { },
}) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  // const [openFilter, setOpenFilter] = useState(false);
  //initial value form
  const defaultValues = {
    // querySearch: getParamUrlValue(location, "querySearch"),
    bidClass: getDefaultValue(getParamUrlValue(location, "bidClass")),
    pricetp: getParamUrlValue(location, "pricetp"),
    price: getParamUrlValue(location, "price"),
    material:
      getDefaultValue(getParamUrlValue(location, "material")) ??
      (searchName ? ["Painting"] : undefined),
    materialSearch: getParamUrlValue(location, "materialSearch"),
    transactDate:
      getDefaultValue(getParamUrlValue(location, "transactDate")) ??
      (searchName
        ? [
          moment([new Date().getFullYear() - 3, new Date().getMonth() - 1]).endOf('month').format("YYYY-MM-DD"),
          moment([new Date().getFullYear(), new Date().getMonth() - 1]).endOf('month').format("YYYY-MM-DD"),
        ]
        : undefined),
    mfgDate: getDefaultValue(getParamUrlValue(location, "mfgDate")),
    height: getDefaultValue(getParamUrlValue(location, "height")),
    width: getDefaultValue(getParamUrlValue(location, "width")),
    title: getParamUrlValue(location, "title"),
    company: getDefaultValue(getParamUrlValue(location, "company")),
    location: getDefaultValue(getParamUrlValue(location, "location")),
    locationSearch: getParamUrlValue(location, "locationSearch"),
    onOff: getParamUrlValue(location, "onOff"),
    certification: getParamUrlValue(location, "certification"),
    auctionName: getParamUrlValue(location, "auctionName"),
    sizeTable: getDefaultValue(getParamUrlValue(location, "sizeTable")),
    mainColor: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "mainColor")) : undefined,
    method: getDefaultValue(getParamUrlValue(location, "method")),
    series: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "series")) : undefined,
    preference: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "preference")) : undefined
  };
  const [reloadFilter, setReloadFilter] = useState(false);
  const [dataSelectLevel2, setDataSelectLevel2] = useState(null);

  const useFormMethods = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
    setValue
  } = useFormMethods;

  /**
   *  event search of form search
   * @param {object} values value of form search
   * @returns {void}
   */
  const onSearch = (values) => {
    onValueChangeFilter(values);
  };

  const clearLevel2Filter = () => {
    setValue("mainColor", undefined);
    setValue("series", undefined);
    setValue("preference", undefined);
  }

  const getSelectLevel2 = async () => {
    const artistName = getParamUrlValue(location, "querySearch");
    if (artistName) {
      const resp = await getSelectLevel2Data({ artistName });
      if (resp) {
        setDataSelectLevel2(resp?.resp)
      }
    } else {
      setDataSelectLevel2(null);
      clearLevel2Filter();
    }
  }

  const handleReset = () => {
    onSearch(watch());
  }

  useEffect(() => {
    getSelectLevel2();
  }, [getParamUrlValue(location, "querySearch")])

  // call back the form's onSearch function
  // useEffect(() => {
  //   if (reloadFilter) {
  //     onSearch(watch());
  //   }
  //   if (!isDirty) {
  //     return;
  //   }
  //   onSearch(watch());
  // }, [JSON.stringify(watch())]);

  const dataLocationUsed = dataLocationShort.map((x) => {
    return x.label;
  });

  return (
    <FormProvider {...useFormMethods}>
      <S.WrapFilter style={{justifyContent: "flex-end"}}>
        <form onSubmit={handleSubmit(onSearch)}>
          <>
            <S.ContainerFilters className={className}>
              <PopoverEl
                name="company"
                text={t(NAME_FILTERS.company)}
                content={<CheckboxRender name="company" options={AUC_COM} />}
              />
              <PopoverEl
                name="auctionName"
                text={t(NAME_FILTERS.auctionName)}
                content={
                  <Controller
                    name="auctionName"
                    control={control}
                    render={({ field }) => (
                      <Input {...field} size="small" placeholder="경매명" />
                    )}
                  />
                }
              />
              <PopoverEl
                name="transactDate"
                text={t(NAME_FILTERS.transactDate)}
                content={<RangeDatePicker name="transactDate" />}
              />
              <PopoverEl
                name={["location", "locationSearch"]}
                text={t(NAME_FILTERS.location)}
                content={
                  <CheckboxRender2 name="location" options={dataLocationUsed} />
                }
              />
              <PopoverEl
                name="onOff"
                text={t(NAME_FILTERS.onOff)}
                content={<CheckboxActive name="onOff" options={ON_OFF_LIST} />}
              />
              <PopoverEl
                name="title"
                text={t(NAME_FILTERS.title)}
                content={
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <Input {...field} size="small" placeholder="작품명 입력" />
                    )}
                  />
                }
              />
              <PopoverEl
                name="mfgDate"
                text={t(NAME_FILTERS.mfgDate)}
                content={<RangeDatePicker name="mfgDate" />}
              />
              <PopoverEl
                name={["material", "materialSearch"]}
                text={t(NAME_FILTERS.material)}
                content={
                  <CheckboxRender
                    inputSearch
                    name="material"
                    options={MATERIAL}
                  />
                }
              />
              <PopoverEl
                name="height"
                text={t(NAME_FILTERS.height)}
                content={<SelectMinMax name="height" />}
              />
              <PopoverEl
                name="width"
                text={t(NAME_FILTERS.width)}
                content={<SelectMinMax name="width" />}
              />
              <PopoverEl
                name="sizeTable"
                text={t(NAME_FILTERS.sizeTable)}
                content={<SelectMinMax name="sizeTable" />}
              />
              <PopoverEl
                name={["pricetp", "price"]}
                text={t(NAME_FILTERS.price)}
                content={<SelectMinMax pricept name="price" />}
              />
              {search && (
                <>
                  <PopoverEl
                    name="bidClass"
                    text={t(NAME_FILTERS.bidClass)}
                    content={
                      <CheckboxRender name="bidClass" options={BID_CLASS} />
                    }
                  />
                </>
              )}
              {filterPage === "artist" && dataSelectLevel2 &&
                <PopoverEl
                  name={"mainColor"}
                  text={t(BID_FILTER.mainColor)}
                  content={
                    <CheckboxRender
                      name="mainColor"
                      options={dataSelectLevel2?.mainColorList}
                    />
                  }
                />
              }
              {filterPage === "artist" && dataSelectLevel2 && <PopoverEl
                name="method"
                text={t(NAME_FILTERS.method)}
                content={
                  <CheckboxRender name="method" options={dataMethods} />
                }
              />}
              {filterPage === "artist" && dataSelectLevel2 && <PopoverEl
                name="series"
                text={t(NAME_FILTERS.series)}
                content={
                  <CheckboxRender name="series" options={dataSelectLevel2?.seriesList} />
                }
              />}
              {filterPage === "artist" && dataSelectLevel2 && <PopoverEl
                name="preference"
                text={t(NAME_FILTERS.preference)}
                content={
                  <CheckboxRender name="preference" options={dataSelectLevel2?.preferenceList} />
                }
              />}
            </S.ContainerFilters>
          </>
        </form>
      </S.WrapFilter>
      <S.WrapTags>
        <FieldsValueFilters handleDeleteFilter={handleReset}>
          {compareParamsUrlValue(location, watch()) === 0 ? <ButtonCus onClick={() => onSearch(watch())}>
            {t("FILTER")}
          </ButtonCus> : ""}
        </FieldsValueFilters>
      </S.WrapTags>
    </FormProvider>
  );
};

export default FilterForm;
