export const HTTP_STATUS_CODE = {
  DB_ERROR: 10000,
  INTERNAL_SERVER_ERROR: 500,
  TOKEN_EXPIRE: 401,
  NOT_FOUND: 404,
  INVALID_TOKEN: 419,
  PARAM_REQUIRED: 49999,
  PASSWORD_ERROR: 10010,
  ID_DOES_NOT_EXIST: 10011,
  INCORRECT_PERIOD_OF_TIME: 10050,
  PLEASE_ENTER_THE_SEARCH_CONTENTS: 10051,
  NO_RESULTS: 10053,
};
