import { isEmpty } from "../checkValue";

export const formatArrayToString = (array = [], data = []) => {
  return array.map((val) => (data.includes(val) ? 1 : 0)).join("");
};

export const formatStringToNumber = (array = [], data = []) => {
  return array.map((val, index) => index === 1 ? Number(val) || 9999999999 : Number(val));
};

const checkNational = (arr) => {
  if (arr.length > 0) {
    return arr;
  } else {
    return undefined;
  }
};

const formatArray = (data) => {
  if (typeof(data) === "string") return [data];
  return data
}

export const formatDataFilter = (values = {}) => {
  const requestParams = {
    ...values,
    artistBirth: !isEmpty(values?.artistBirth)
      ? [Number(values?.artistBirth[0]) || "", Number(values?.artistBirth[1]) || ""]
      : undefined,
    querySearch: values?.querySearch ? values?.querySearch : undefined,
    pricetp: values?.pricetp ? values?.pricetp : undefined,
    materialSearch: values?.materialSearch ? values?.materialSearch : undefined,
    nationality: values?.nationality
      ? checkNational(values?.nationality)
      : undefined,
    nationalitySearch: values?.nationalitySearch
      ? values?.nationalitySearch
      : undefined,
    title: values?.title ? values?.title : undefined,
    auctionName: values?.auctionName ? values?.auctionName : undefined,
    bidClass: values?.bidClass
      ? values?.bidClass.map((item) =>
          item === "W/D" ? item.toLowerCase() : item
        )
      : undefined,
    price: !isEmpty(values?.price)
      ? formatStringToNumber(values?.price)
      : undefined,
    material: values?.material?.length > 0 ? values?.material : undefined,
    transactDate: !isEmpty(values?.transactDate)
      ? values?.transactDate
      : undefined,
    mfgDate: !isEmpty(values?.mfgDate) ? values?.mfgDate : undefined,
    height: !isEmpty(values?.height)
      ? formatStringToNumber(values?.height)
      : undefined,
    width: !isEmpty(values?.width)
      ? formatStringToNumber(values?.width)
      : undefined,
    sizeTable: !isEmpty(values?.sizeTable)
      ? formatStringToNumber(values?.sizeTable)
      : undefined,
    company: values?.company?.length > 0 ? values?.company : undefined,
    location: values?.location?.length > 0 ? values?.location : undefined,
    materialKind: values?.materialKind ? values?.materialKind : undefined,
    onOff: values?.onOff ? values.onOff : undefined,
    certification: values?.certification
      ? Number(values.certification)
      : undefined,
    order: values?.order ?? undefined,
    field: values?.field ?? undefined,
    mainColor: values?.mainColor?.length > 0 ? formatArray(values?.mainColor) : undefined,
    method: values?.method?.length > 0 ? formatArray(values?.method) : undefined,
    series: values?.series?.length > 0 ? formatArray(values?.series) : undefined,
    preference: values?.preference?.length > 0 ? formatArray(values?.preference) : undefined,
  };

  return requestParams;
};

export const formatDataFilterArtMarket = (values = {}) => {
  const requestParams = {
    ...values,
    querySearch: values?.querySearch ? values?.querySearch : undefined,
    pricetp: values?.pricetp ? values?.pricetp : undefined,
    materialSearch: values?.materialSearch ? values?.materialSearch : undefined,
    nationality: values?.nationality
      ? checkNational(values?.nationality)
      : undefined,
    nationalitySearch: values?.nationalitySearch
      ? values?.nationalitySearch
      : undefined,
    title: values?.title ? values?.title : undefined,
    auctionName: values?.auctionName ? values?.auctionName : undefined,
    bidClass: values?.bidClass
      ? values?.bidClass.map((item) =>
          item === "W/D" ? item.toLowerCase() : item
        )
      : undefined,
    price: !isEmpty(values?.price) 
      ? formatStringToNumber(values?.price)
      : undefined,
    material:
      values?.material?.length > 0 ? formatArray(values?.material) : undefined,
    transactDate: !isEmpty(values?.transactDate)
      ? values?.transactDate
      : undefined,
    mfgDate: !isEmpty(values?.mfgDate) 
      ? values?.mfgDate
      : undefined,
    height: !isEmpty(values?.height) 
      ? formatStringToNumber(values.height)
      : undefined,
    width: !isEmpty(values?.width)
      ? formatStringToNumber(values?.width)
      : undefined,
    sizeTable: !isEmpty(values?.sizeTable)
      ? formatStringToNumber(values.sizeTable)
      : undefined,
    company:
      values?.company?.length > 0 ? formatArray(values?.company) : undefined,
    location:
      values?.location?.length > 0 ? formatArray(values?.location) : undefined,
    materialKind: values?.materialKind ? values?.materialKind : undefined,
    onOff: values?.onOff ? values.onOff : undefined,
    certification: values?.certification
      ? Number(values.certification)
      : undefined,
    order: values?.order ?? undefined,
    field: values?.field ?? undefined,
    mainColor: values?.mainColor?.length > 0 ? formatArray(values?.mainColor) : undefined,
    mainColorSearch: values?.mainColorSearch ? values?.mainColorSearch : undefined,
  };

  return requestParams;
};
