import { useEffect, useState } from "react";
import { mainApi } from "../apis/auth";
import Decline from "../assets/images/arrow-up.png";
import Increase from "../assets/images/arrow-down.png";
import ChartCard from "../components/Main/ChartCard";
import HomeCard from "../components/Main/HomeCard";
import RisingArtistCard from "../components/Main/RisingArtistCard";
import {
  BidRateDoughnutgraph,
  StackbargraphPriceSecondary,
} from "../containers/Chart/ChartSecondary";
import {
  useConvertDataCard,
  convertDataRisingArtist,
} from "../utils/convertDataCard";
import ColumnLineChart from "../components/ColumnLineChart";
import StackedColumnChart from "../components/StackedColumnChart";
import BarPlotChart from "../components/BarPlotChart";
import TableList from "../components/TableList";
import TreeChart from "../components/TreeChart";
import YoyChange from "../components/YoyChange";

const listTab = ["전체 데이터", "필터링 데이터"];

const dataDemo = [
  {
    year: "2013",
    type: "Above",
    value: 92.1,
  },
  {
    year: "2013",
    type: "Within",
    value: 145.1,
  },
  {
    year: "2013",
    type: "Below",
    value: 110.6,
  },
  {
    year: "2013",
    type: "Unsold",
    value: 39.4,
  },
  {
    year: "2014",
    type: "Above",
    value: 91.8,
  },
  {
    year: "2014",
    type: "Within",
    value: 140.9,
  },
  {
    year: "2014",
    type: "Below",
    value: 99,
  },
  {
    year: "2014",
    type: "Unsold",
    value: 33.2,
  },
  {
    year: "2015",
    type: "Above",
    value: 87.1,
  },
  {
    year: "2015",
    type: "Within",
    value: 139.4,
  },
  {
    year: "2015",
    type: "Below",
    value: 103.9,
  },
  {
    year: "2015",
    type: "Unsold",
    value: 30,
  },
  {
    year: "2016",
    type: "Above",
    value: 80,
  },
  {
    year: "2016",
    type: "Within",
    value: 134.8,
  },
  {
    year: "2016",
    type: "Below",
    value: 100,
  },
  {
    year: "2016",
    type: "Unsold",
    value: 45.2,
  },
];

const dataOnOff = [
  {
    year: "2013",
    type: "On",
    value: 92.1,
  },
  {
    year: "2013",
    type: "Off",
    value: 145.1,
  },
  {
    year: "2014",
    type: "On",
    value: 91.8,
  },
  {
    year: "2014",
    type: "Off",
    value: 140.9,
  },
  {
    year: "2015",
    type: "On",
    value: 87.1,
  },
  {
    year: "2015",
    type: "Off",
    value: 139.4,
  },
  {
    year: "2016",
    type: "On",
    value: 80,
  },
  {
    year: "2016",
    type: "Off",
    value: 134.8,
  },
];

const dataRatioCompany = [
  {
    year: "2013",
    type: "Seoul Auction",
    value: 92.1,
  },
  {
    year: "2013",
    type: "K-Auction",
    value: 145.1,
  },
  {
    year: "2013",
    type: "Other",
    value: 110.6,
  },
  {
    year: "2014",
    type: "Seoul Auction",
    value: 91.8,
  },
  {
    year: "2014",
    type: "K-Auction",
    value: 140.9,
  },
  {
    year: "2014",
    type: "Other",
    value: 99,
  },
  {
    year: "2015",
    type: "Seoul Auction",
    value: 87.1,
  },
  {
    year: "2015",
    type: "K-Auction",
    value: 139.4,
  },
  {
    year: "2015",
    type: "Other",
    value: 103.9,
  },
  {
    year: "2016",
    type: "Seoul Auction",
    value: 80,
  },
  {
    year: "2016",
    type: "K-Auction",
    value: 134.8,
  },
  {
    year: "2016",
    type: "Other",
    value: 100,
  },
];

const dataBArChart = [
  {
    type: "家具家电",
    sales: 38,
  },
  {
    type: "粮油副食",
    sales: 52,
  },
  {
    type: "生鲜水果",
    sales: 61,
  },
  {
    type: "美容洗护",
    sales: 145,
  },
  {
    type: "母婴用品",
    sales: 48,
  },
  {
    type: "进口食品",
    sales: 38,
  },
  {
    type: "食品饮料",
    sales: 38,
  },
  {
    type: "家庭清洁",
    sales: 38,
  },
];

function Test() {
  return (
    <>
      <div style={{ margin: "0 auto", maxWidth: "760px" }}>
        <div
          style={{
            marginBottom: "50px",
            border: "solid 1px #e5e8e7",
            borderRadius: "45px",
            padding: "20px",
            boxShadow: `0 0 15px 0 rgba(0,0,0,0.16)`,
          }}
        >
          <TableList
            data={{
              numberWorksOutStock: 5799,
              numberWorksAuction: 5799,
              totalBidAmount: "6,799",
              biddingRate: "65.11",
            }}
          />
          <TreeChart
            data={[
              {
                ratio: 7,
                label: "ABOVE",
              },
              {
                ratio: 20,
                label: "WITHIN",
              },
              {
                ratio: 28,
                label: "BELOW",
              },
              {
                ratio: 45,
                label: "UNSOLD",
              },
            ]}
          />
          <YoyChange
            data={{
              title: "낙찰총액",
              value: "2,543",
              ratio: -41,
            }}
          />
          <YoyChange
            data={{
              title: "낙찰률",
              value: "65.7%",
              ratio: 8.9,
            }}
          />
        </div>
        <div
          style={{
            marginBottom: "50px",
            border: "solid 1px #e5e8e7",
            borderRadius: "45px",
            padding: "20px",
            boxShadow: `0 0 15px 0 rgba(0,0,0,0.16)`,
          }}
        >
          <ColumnLineChart />
        </div>
        <div
          style={{
            marginBottom: "50px",
            border: "solid 1px #e5e8e7",
            borderRadius: "45px",
            padding: "20px",
            boxShadow: `0 0 15px 0 rgba(0,0,0,0.16)`,
          }}
        >
          <StackedColumnChart
            colors={["#8c929b", "#dea454", "#85d6df", "#b0ba69"]}
            data={dataDemo}
          />
        </div>
        <div
          style={{
            marginBottom: "50px",
            border: "solid 1px #e5e8e7",
            borderRadius: "45px",
            padding: "20px",
            boxShadow: `0 0 15px 0 rgba(0,0,0,0.16)`,
          }}
        >
          <StackedColumnChart
            colors={["#4472c4", "#ed7d31"]}
            data={dataOnOff}
          />
        </div>
        <div
          style={{
            marginBottom: "50px",
            border: "solid 1px #e5e8e7",
            borderRadius: "45px",
            padding: "20px",
            boxShadow: `0 0 15px 0 rgba(0,0,0,0.16)`,
          }}
        >
          <StackedColumnChart
            colors={["#b1174d", "#ef7a91", "#7dbab9", "#e2e2e2"]}
            data={dataRatioCompany}
            ishowLabel={true}
          />
        </div>
        <div
          style={{
            marginBottom: "50px",
            border: "solid 1px #e5e8e7",
            borderRadius: "45px",
            padding: "20px",
            boxShadow: `0 0 15px 0 rgba(0,0,0,0.16)`,
          }}
        >
          <BarPlotChart data={dataBArChart} />
        </div>
      </div>
    </>
  );
}

export default Test;
