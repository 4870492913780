import axios from "axios";
import { removeKey, getKey } from "../App";
const { REACT_APP_API_SERVER } = process.env;

// eslint-disable-next-line no-underscore-dangle
const _axios = axios.create({
  baseURL: REACT_APP_API_SERVER,
});

const mainAxios = {
  request: async (parameter) => {
    const { methodType, url, payload, requiresToken, config, params } =
      parameter;

    return new Promise((resolve, reject) => {
      // axios request default options
      const headers = config && config.headers ? config.headers : {};

      if (headers.contentType) {
        headers["Content-Type"] = headers.contentType;
        delete headers.contentType;
      } else {
        headers["Content-Type"] = "application/json";
      }

      // if API endpoint requires a token
      if (requiresToken) {
        const acToken = getKey("Authorization");
        if (acToken) {
          headers["Authorization"] = `Bearer ${acToken}`;
        } else {
          alert("로그인 페이지로 이동합니다");
          window.location.replace("/login");
        }
      }

      _axios
        .request({
          url,
          method: methodType,
          data: payload,
          params,
          ...config,
          headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status === 401 ||
              err.response.status === 419 ||
              err.response.status === 403
            ) {
              console.log(err.response.data);
              const acToken = getKey("Authorization");
              if (acToken) {
                removeKey("Authorization");
                console.log("유효하지 않은 토큰 삭제");
                window.location.replace("/login");
              }
            }
            if (err.response.status >= 400 && err.response.status < 500) {
              const errData = err.response.data;
              console.log("::: Error Code :", errData.err);
              console.log("::: Error Message :", errData.err_desc);

              // alert(errData.err_desc);
              reject(errData);
            } else {
              console.log(err);
              const errData = err.response.data;
              console.log("::: Error Code2 :", errData);
              // return errData
              reject(errData);

              // alert('서버에러:' + errData.message)
            }
          }
          reject(err);
        });
    });
  },

  getRequest: async function (parameter) {
    parameter.methodType = "GET";
    return this.request(parameter);
  },

  postRequest: async function (parameter) {
    parameter.methodType = "POST";
    return this.request(parameter);
  },

  putRequest: async function (parameter) {
    parameter.methodType = "PUT";
    return this.request(parameter);
  },

  patchRequest: async function (parameter) {
    parameter.methodType = "PATCH";
    return this.request(parameter);
  },

  deleteRequest: async function (parameter) {
    parameter.methodType = "DELETE";
    return this.request(parameter);
  },
};

export { mainAxios, _axios };
