import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import React from "react";
import * as S from "./DataTable.style";

/**
 *  custom pagination table
 * @param {any} current
 * @param {string} type
 * @param {any} originalElement
 * @returns new UI pagination
 */
const itemRender = (current, type, originalElement) => {
  if (type === "prev") {
    return <ArrowLeftOutlined className="icon-arrow" />;
  }
  if (type === "next") {
    return <ArrowRightOutlined className="icon-arrow" />;
  }
  return originalElement;
};
const DataTable = ({
  columns = [],
  data = [],
  pagination = {},
  onChangePageCurrent,
  rowSelection,
  isLoading = false,
  onChange,
}) => {
  const paginationTable = pagination
    ? {
        total: pagination?.totalElement,
        current: Number(pagination?.page),
        pageSize: Number(pagination?.size),
      }
    : { position: ["none"] };
  /**
   * event when change page current
   * @param {number} page
   * @param {number} pageSize
   * @returns {void}
   */
  const handleChangePage = (page, pageSize) => {
    onChangePageCurrent({ page, pageSize });
  };

  return (
    <S.DataTable
      columns={columns}
      dataSource={data}
      pagination={{
        ...paginationTable,
        position: ["bottomCenter"],
        size: "small",
        defaultPageSize: 20,
        showSizeChanger: false,
        hideOnSinglePage: true,
        itemRender: itemRender,
        onChange: handleChangePage,
      }}
      rowSelection={rowSelection ?? false}
      rowKey={(value, index) => value?.id || index}
      scroll={{ y: 990 }}
      loading={isLoading}
      onChange={onChange}
    />
  );
};

export default DataTable;
