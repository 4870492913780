import React from "react";
import { InputNumber, Space, Select } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { PRICE_MAP } from "../../common/constants/filtersName";
import { useFormContext, useWatch } from "react-hook-form";
import { NumericFormat } from 'react-number-format';
import { useTranslation } from "react-i18next";

const { Option } = Select;

const SelectMinMax = ({ pricept = false, name }) => {
  const { t } = useTranslation("common");
  const { control, setValue, getValues } = useFormContext();
  const fieldValue = getValues(name) || [];
  const pricetpField = useWatch({
    control,
    name: "pricetp",
  });

  const [valuePricetp, setValuePricetp] = useState(pricetpField);

  const [min, setMin] = useState(fieldValue[0] ?? "");
  const [max, setMax] = useState(fieldValue[1] ?? "");
  // const [isError, setIsError] = useState(false);
  //used reset value for field ${name} (name used common)
  useEffect(() => {
    if (min === "" || max === "") {
      setValue(`${name}`, undefined);
    }
    if (!min && !max) {
      return setValue(`${name}`, undefined);
    }
    if (max.length > 0 && min.length > 0 && Number(max) < Number(min)) {
      // setIsError(true);
      return;
    }

    setValue(`${name}`, [min ?? "", Number(max) >= Number(min) ? max : ""], {
      shouldDirty: true,
    });
    // setIsError(false);
  }, [min, max]);

  useEffect(() => {
    if (!getValues(name)) {
      setMin("");
      setMax("");
    }
  }, [JSON.stringify(getValues(name))]);
  //used reset value for field pricetp
  useEffect(() => {
    if (!pricetpField) {
      setValuePricetp(undefined);
    }
  }, [JSON.stringify(pricetpField)]);

  useEffect(() => {
    if (!valuePricetp) {
      return setValue("pricetp", undefined);
    }
    setValue("pricetp", valuePricetp);
  }, [valuePricetp]);

  return (
    <>
      <Space>
        {pricept && (
          <Select
            value={valuePricetp}
            onChange={(value) => setValuePricetp(value)}
            size="small"
            allowClear
            placeholder="가격 종류"
            style={{
              minWidth: 150,
            }}
          >
            {Object.keys(PRICE_MAP).map((price, index) => (
              <Option key={index} value={price}>
                {PRICE_MAP[price]}
              </Option>
            ))}
          </Select>
        )}
        <NumericFormat 
          thousandSeparator=","
          decimalSeparator="." 
          decimalScale={1}
          onValueChange={(values) => setMin(values.value)} 
          placeholder="MIN" 
          defaultValue={min}
          value={min} 
          allowNegative={false}
          className="ant-input ant-input-sm"
        />
        <span>~</span>
        <NumericFormat  
          thousandSeparator=","
          decimalSeparator="." 
          decimalScale={1}
          onValueChange={(values) => setMax(values.value)} 
          placeholder="MAX" 
          value={max} 
          defaultValue={max}
          allowNegative={false}
          className="ant-input ant-input-sm"
        />
      </Space>
      {/* {isError && <p style={{color: "#ff6363", marginTop: 8}}><i>* {t("ERROR_TEXT_MINMAX")}</i></p>} */}
    </>
  );
};

export default SelectMinMax;
