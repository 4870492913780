import * as S from "./ChartCard.styles";

function ChartCard({ title, chart, contentText, ratio }) {
  return (
    <S.ChartCard>
      <S.Chart>{chart}</S.Chart>
      {ratio && (
        <S.Ratio>{ratio}%</S.Ratio>
      )}
      <S.Title>{title}</S.Title>
      {contentText && contentText?.map((item, index) => (
        <S.ContentText key={index}>
          <span>{item.label}:</span>
          <span>{item.context}</span>
        </S.ContentText>
      ))}
    </S.ChartCard>
  );
}

export default ChartCard;
