import { Pie } from "@ant-design/plots";
import React from "react";
import GroupButton from "../common/GroupButton";
import { useState } from "react";
import { TYPE_BTN } from "../../common/constants/typeButton";
import { useEffect } from "react";
import { formatDatasetsArtMarketShare } from "../../utils/chart/formatDatasetsArtMarket";

const PiePlotChart = ({ data, styleButton }) => {
  const [type, setType] = useState(TYPE_BTN.VOLUME);
  const [datasets, setDatasets] = useState([]);

  useEffect(() => {
    let newDatasets = [];
    if (type === TYPE_BTN.VOLUME) {
      newDatasets = data?.top5Lot.map((item) => ({
        type: item.location ?? "No-name",
        value: Number(item.totalLot),
      }));
    } else {
      newDatasets = data?.top5HammerPrice.map((item) => ({
        type: item.location ?? "No-name",
        value: Number(item.totalHammerPrice),
      }));
    }
    newDatasets = formatDatasetsArtMarketShare(newDatasets);
    setDatasets(newDatasets);
  }, [type, data]);

  const config = {
    height: 327,
    appendPadding: 10,
    data: datasets,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.6,
    label: {
      type: "outer",
      offset: "-50%",
      content: "{percentage}",
      autoRotate: true,
      style: {
        textAlign: "center",
        fontSize: 11,
        color: "#000",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        content: "",
      },
    },
    legend: {
      position: "left",
    },
    tooltip: {
      formatter: (datum) => {
        return { name: datum.type, value: datum.value + "%" };
      },
    },
  };
  // const config = {
  //   height: 327,
  //   appendPadding: 10,
  //   data: datasets,
  //   angleField: "value",
  //   colorField: "type",
  //   radius: 0.75,
  //   legend: { position: "left" },
  //   label: {
  //     type: "spider",
  //     labelHeight: 28,
  //     content: "{name}\n{percentage}",
  //   },
  //   interactions: [
  //     {
  //       type: "element-selected",
  //     },
  //     {
  //       type: "element-active",
  //     },
  //   ],
  // };

  return (
    <>
      <Pie {...config} />
      <GroupButton
        isTop10={true}
        type={type}
        handleSetType={(v) => setType(v)}
        style={styleButton}
      />
    </>
  );
};

export default PiePlotChart;
