import "antd/dist/antd.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import kr from "./common/lang/kr.json"
import vi from "./common/lang/vi.json"


import App from "./App";

import { StoreProvider, store } from "./store";

const lang = store.getState().lang;

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: lang?.lang || "kr", // language to use
  resources: {
    kr: {
      common: kr,
    },
    vi: {
      common: vi, // 'common' is our custom namespace
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StoreProvider>
    <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
  </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
