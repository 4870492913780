import styled from "styled-components";

export const Container = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 50px;
  right: 25px;
  z-index: 999;
  border-radius: 50%;
  background-color: #a49039;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 20px;
  display: none;
  cursor: pointer;

  &.show {
    display: flex;
  }

  /* @media screen and (max-width: 768px) {
    display: flex;
  } */
`;
