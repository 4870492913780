import { DualAxes, G2 } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { TYPE_BTN } from "../../common/constants/typeButton";
import { MoneyConvert, formatTimeChart } from "../../utils/util";
import GroupButton from "../common/GroupButton";
import { useTranslation } from "react-i18next";

const ColumnLineChart = ({
  datas = {},
  isPadding = false,
  height = 327,
  isShowLegend = false,
  isShowSlider = true,
  isShowAllYAxis = true,
}) => {
  const { t } = useTranslation("common");
  const [type, setType] = useState(TYPE_BTN.YEARLY);
  const [dataRes, setDataRes] = useState([[], []]);

  useEffect(() => {
    const arrEsimateMin = datas?.estimateMinMedian?.[type]
      ? datas?.estimateMinMedian?.[type].map((item) => ({
          time: formatTimeChart(item.timeFilter, type),
          count: item.estimateMinMedian ?? 0,
          name: t("ESTIMATE_MIN"),
        }))
      : [];
    const arrEsimateMax = datas?.estimateMaxMedian?.[type]
      ? datas?.estimateMaxMedian?.[type].map((item) => ({
          time: formatTimeChart(item.timeFilter, type),
          count: item.estimateMaxMedian ?? 0,
          name: t("ESTIMATE_MAX_OTHER"),
        }))
      : [];
    const arrHammerPrice = datas?.hammerPriceMedian?.[type]
      ? datas?.hammerPriceMedian?.[type].map((item) => ({
          time: formatTimeChart(item.timeFilter, type),
          value: item.hammerPriceMedian ?? 0,
          name: t("WINNING_PRICE"),
        }))
      : [];
    const newData = [arrHammerPrice, [...arrEsimateMin, ...arrEsimateMax]];
    setDataRes(newData);
  }, [type, JSON.stringify(datas)]);

  const handleSetType = (values) => {
    setType(values);
  };

  const { registerTheme } = G2;
  registerTheme("custom-theme", {
    colors10: ["#bf2659", "#1979C9", "#febe20"],
    colors20: ["#bf2659", "#1979C9", "#febe20"],
  });

  const config = {
    height: 327,
    data: dataRes,
    xField: "time",
    yField: ["value", "count"],
    geometryOptions: [
      {
        geometry: "column",
        columnWidthRatio: 0.2,
      },
      {
        geometry: "line",
        seriesField: "name",
      },
    ],
    theme: "custom-theme",
    legend: isShowLegend ? true : false,
    tooltip: {
      style: {
        textAlign: "left",
      },
      title: (v) => {
        if (type === "monthly") {
          if (v.includes(".")) {
            return v.split(".").reverse().join(".");
          } else if (v.includes("-")) {
            return v.split("-").reverse().join(".");
          }
        }
        return v;
      },
    },
    slider: isShowSlider ? { start: 0, end: 1 } : false,
    limitInPlot: false,
    padding: isPadding ? [10, 30, 60, 30] : false,
    meta: {
      value: {
        alias: "낙찰가",
        formatter: (value, index) => {
          if (value === 0) return value;
          let newValue = value / 1000000;
          if (newValue >= 1) {
            newValue = MoneyConvert(newValue);
          }
          return newValue + t("UNIT_MILLION_WON");
        },
        sync: false,
      },
      time: {
        sync: false, // 开启之后 slider 无法重绘
      },
      count: {
        formatter: (value, index) => {
          if (value === 0) return value;
          let newValue = value / 1000000;
          if (newValue >= 1) {
            newValue = MoneyConvert(newValue);
          }
          return newValue + t("UNIT_MILLION_WON");
        },
        sync: false,
      },
    },
    xAxis: {
      label: {
        formatter: (value) => {
          return value;
        },
      },
    },
    yAxis: {
      count: isShowAllYAxis ? true : false,
    },
  };

  return (
    <>
      <DualAxes {...config} />
      <GroupButton handleSetType={handleSetType} type={type} />
    </>
  );
};

export default ColumnLineChart;
