import { AutoComplete, Checkbox, Input } from "antd";
import React, { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { dataLocation } from "../../common/constants/dataSelect";
import { ContainerInput, SpaceStyle } from "../filterForm/FilterForm.style";

const styleLayout = {
  minWidth: 150,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 8,
};

const CheckboxRender3 = ({ options = [], inputSearch = false, name }) => {
  const watchFields = useWatch();
  const { setValue, getValues, control } = useFormContext();

  useEffect(() => {
    if (!watchFields[name] && watchFields[name]?.length === 0) {
      setValue(name, undefined);
    }
  }, [JSON.stringify(watchFields)]);

  const onSelect = (data) => {
    setValue("locationSearch", data, {
      shouldDirty: true,
    });
  };

  const onChange = (value) => {
    if (!value || value === "") {
      setValue("locationSearch", undefined, {
        shouldDirty: true,
      });
    }
  };

  const onClear = () => {
    setValue("locationSearch", undefined, {
      shouldDirty: true,
    });
  };

  return (
    <SpaceStyle direction="vertical">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox.Group {...field} options={options} style={styleLayout} />
        )}
      />

      <ContainerInput>
        <AutoComplete
          popupClassName="certain-category-search-dropdown"
          options={dataLocation}
          defaultValue={getValues("locationSearch")}
          value={getValues("locationSearch")}
          onSelect={onSelect}
          onClear={onClear}
          onChange={onChange}
          allowClear
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        >
          <Input.Search size="small" placeholder="입력" />
        </AutoComplete>
      </ContainerInput>
    </SpaceStyle>
  );
};

export default CheckboxRender3;
