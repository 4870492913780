import { Radio, Space } from "antd";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const CheckboxActive = ({ name, options = [] }) => {
  const { control } = useFormContext();
  return (
    <Space direction="vertical">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Radio.Group {...field}>
            <Space direction="vertical">
              {options.map((item) => (
                <Radio value={item.value} key={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        )}
      />
    </Space>
  );
};

export default CheckboxActive;
