import { mainAxios } from "../libs/axios";

export const getArtMarketApi = () => {
  return mainAxios.getRequest({
    url: `/crawling/artmarket`,
    requiresToken: true
  });
}

// export const filterArtMarketApi = (payload) => {
//   return mainAxios.getRequest({
//     url: `/crawling/new-art-market`,
//     requiresToken: true,
//     params: payload
//   });
// };

export const filterArtMarketApi = (payload) => {
  return mainAxios.postRequest({
    url: `/crawling/artmarket`,
    requiresToken: true,
    payload: payload
  });
};