import { Checkbox, Input } from "antd";
import React, { useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { TfiSearch } from "react-icons/tfi";
import { ContainerInput, SpaceStyle } from "../filterForm/FilterForm.style";

const styleLayout = {
  minWidth: 150,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 8,
};

const CheckboxRender = ({
  options = [],
  inputSearch = false,
  name,
  nameSearch = "materialSearch",
}) => {
  const watchFields = useWatch();
  const { setValue, getValues, control } = useFormContext();

  useEffect(() => {
    if (!watchFields[name] && watchFields[name]?.length === 0) {
      setValue(name, undefined);
    }
    if (watchFields[nameSearch] === "") {
      setValue(nameSearch, undefined);
    } else if (watchFields["nationalitySearch"] === "") {
      setValue("nationalitySearch", undefined);
    }
  }, [JSON.stringify(watchFields)]);

  const handleKeyDown = (e) => {
    if (e.key.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|.<>\?~\d]/g) && nameSearch === "mainColorSearch") {
      e.preventDefault();
    }
  };

  return (
    <SpaceStyle direction="vertical">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox.Group {...field} options={options} style={styleLayout} />
        )}
      />

      {inputSearch && (
        <Controller
          name={
            nameSearch === "nationalitySearch"
              ? "nationalitySearch"
              : nameSearch
          }
          control={control}
          render={({ field }) => (
            <ContainerInput className="material-input">
              <Input
                {...field}
                size="small"
                placeholder={nameSearch === "mainColorSearch" ? "주조색 찾기" : "재료명 입력"}
                id="material_search"
                allowClear
                name={nameSearch}
                onKeyPress={handleKeyDown}
              />
              <TfiSearch className="icon-search" />
            </ContainerInput>
          )}
        />
      )}
    </SpaceStyle>
  );
};

export default CheckboxRender;
