import { Provider } from "react-redux";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import reducers from "./reducers/index";

export const store = createStore(reducers, applyMiddleware(thunk));

export const StoreProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);
