import React from "react";
import * as S from "./Button.style";

const Button = ({ children, disabled, ...props }) => {
  return (
    <S.StyledButton {...props} disabled={disabled}>
      {children}
    </S.StyledButton>
  );
};

export default Button;
