import { Table } from "antd";
import styled from "styled-components";

export const DataTable = styled(Table)`
  .ant-table table {
    width: auto;
    min-width: 100%;

    @media screen and (max-width: 768px) {
      min-width: 1000px;
    }
  }

  .ant-table-thead > tr > th {
    background-color: #fff;
    border: none;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8e;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ant-table-thead > tr > th.ant-table-cell:before {
    content: none !important;
  }
  .ant-table-tbody {
    background-color: #f5f5f5;
  }
  .ant-table-tbody > tr {
    min-height: 60px;
  }
  .ant-table-tbody > tr > td {
    border: 1.5px solid #fff;
    font-family: NotoSansKR-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #202020;
  }

  .ant-table-body {
    padding-bottom: 10px;
  }

  .ant-table-pagination {
    align-items: center;
  }

  .ant-table-pagination.ant-pagination {
    margin: 47px 0 0;
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-item {
    min-width: 24px;
    height: auto;
    margin: 0;
    line-height: 22px;
  }
  .ant-pagination.ant-pagination-mini .ant-pagination-next,
  .ant-pagination.ant-pagination-mini .ant-pagination-prev {
    min-width: 16px;
    height: auto;
    margin: 0;
    line-height: 16px;
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-prev {
    margin-right: 30px;
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-next {
    margin-left: 30px;
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-jump-next,
  .ant-pagination.ant-pagination-mini .ant-pagination-jump-prev {
    line-height: 32px;
  }

  .ant-table-cell-scrollbar:not([rowspan]) {
    display: none;
  }
  .ant-pagination-item a {
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #9b9b9b;
  }
  .ant-pagination-item-active {
    border: none;
  }
  .ant-pagination-item-active a {
    font-size: 22px;
    font-weight: bold;
    color: #a49039;
    line-height: 33px;
    transform: translateY(-2px);
    font-family: NotoSansKR-Bold;
  }

  .ant-pagination-item a:hover,
  .icon-arrow:hover {
    font-weight: bold;
    color: #a49039;
  }
  .icon-arrow {
    font-family: themify;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #a49039;
  }

  .ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin-left: 30px;

    .ant-select-selector {
      border-radius: 6px;
      height: 32px;

      .ant-select-selection-item {
        line-height: 30px;
      }
    }

    .ant-select-arrow {
      margin-top: -5px;
    }
  }

  @media (max-width: 425px) {
    .ant-table-pagination.ant-pagination {
      margin-top: 20px;
    }
  }
`;
