import styled from "styled-components";

export const RisingArtistCard = styled.div`
  width: 565px;
  height: 760px;
  padding: 35px 50px 78px 43px;
  border-radius: 20px;
  box-shadow: 0px 0px 7.5px rgba(0, 0, 0, 0.129412);
  background-color: #fff;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: solid 1px #dbcdfb;
    z-index: -1;
    transform: rotate(-7deg);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 540px) {
    width: 90%;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 34px;

  img {
    width: 38px;
    height: 48px;
  }

  span {
    font-family: NotoSansKR-Bold;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #202020;
  }
`;

export const Image = styled.div`
  height: 350px;
  padding: 25px;
  border-radius: 26px;
  border: solid 1px #ebebeb;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 38px;

  @media screen and (max-width: 768px) {
    height: 220px;
  }

  img {
    width: 100%;
    max-height: 100%;
    min-height: 50%;
    object-fit: cover;
  }
`;

export const TitleContent = styled.div`
  font-family: NotoSansKR-Bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202020;
  margin-bottom: 18px;
`;

export const TextContent = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #6e6e6e;

  span {
    &:first-child {
      margin-right: 5px;
    }
  }
`;