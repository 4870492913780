import { AutoComplete, Button, Input } from "antd";
import debounce from "lodash.debounce";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  artistApiSearch,
  getArtistName,
  getTopTenWorks
} from "../../apis/auth";
import AuthorCharts from "../../components/author/AuthorCharts";
import FilterForm from "../../components/filterForm/FilterForm";
import Title from "../../containers/title/Title";
import { formatDataFilter } from "../../utils/format/formatDataFilter";
import { addParamUrl } from "../../utils/url";
import { getParamUrlValue } from "../../utils/util";
import types from "../../store/actions/types";
import * as S from "./Author.style";

const getDefaultValue = (value) =>
  typeof value === "string" ? [value] : value;

const Author = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [summaryFilter, setSummaryFilter] = useState(null);
  const [filter, setFilter] = useState(() => {
    const getValuesUrl = queryString.parse(history.location.search);
    return {
      querySearch: getParamUrlValue(location, "querySearch"),
      bidClass: getDefaultValue(getParamUrlValue(location, "bidClass")),
      pricetp: getParamUrlValue(location, "pricetp"),
      price: getParamUrlValue(location, "price"),
      materialSearch: getParamUrlValue(location, "materialSearch"),
      mfgDate: getDefaultValue(getParamUrlValue(location, "mfgDate")),
      height: getDefaultValue(getParamUrlValue(location, "height")),
      width: getDefaultValue(getParamUrlValue(location, "width")),
      title: getParamUrlValue(location, "title"),
      company: getDefaultValue(getParamUrlValue(location, "company")),
      location: getDefaultValue(getParamUrlValue(location, "location")),
      locationSearch: getParamUrlValue(location, "locationSearch"),
      onOff: getParamUrlValue(location, "onOff"),
      auctionName: getParamUrlValue(location, "auctionName"),
      sizeTable: getParamUrlValue(location, "sizeTable"),
      material: getValuesUrl?.material
        ? getDefaultValue(getValuesUrl?.material)
        : ["Painting"],
      transactDate: getValuesUrl?.transactDate
        ? getDefaultValue(getValuesUrl?.transactDate)
        : [
          moment([
            new Date().getFullYear() - (new Date().getMonth() === 0 ? 4 : 3), 
            new Date().getMonth() === 0 
              ? 11 
              : new Date().getMonth() - 1]
            )
          .endOf('month').format("YYYY-MM-DD"),
          moment([
            new Date().getFullYear() - (new Date().getMonth() === 0 ? 1 : 0), 
            new Date().getMonth() === 0 
              ? 11 
              : new Date().getMonth() - 1]
            )
          .endOf('month').format("YYYY-MM-DD"),
        ],
      mainColor: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "mainColor")) : undefined,
      method: getDefaultValue(getParamUrlValue(location, "method")),
      series: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "series")) : undefined,
      preference: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "preference")) : undefined
    };
  });
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [errFilter, setErrFilter] = useState("");
  const [dataWinningRate, setDataWinningRate] = useState(null);
  const { typeTime } = useSelector((state) => state.chart);
  const [options, setOptions] = useState([]);
  const [notFoundArtist, setNotFoundArtist] = useState(false);

  const formRef = useRef(null);

  /**
   * used to get data artist from api
   * @param {object} values - value form
   * @returns {void}
   */
  const getDataArtistFilter = async (valuesForm = {}) => {
    dispatch({type: types.SET_LOADING, payload: {isLoading: true}});
    addParamUrl(history, valuesForm);
    const dataFilter = formatDataFilter(valuesForm);
    setLoadingFilter(true);

    await Promise.all([
      artistApiSearch(dataFilter),
      getTopTenWorks({ ...dataFilter, timeFilter: typeTime }),
    ])
      .then((res) => {
        setData(res[0].resp);
        setDataWinningRate(res[1].winningRateAndWinningBid);
        setSummaryFilter(res[0].summary);
        setLoadingFilter(false);
        setErrFilter(null);
      })
      .catch((err) => {
        setData(null);
        setDataWinningRate(null);
        setSummaryFilter(null);
        setErrFilter("Error System");
        setLoadingFilter(false);
      });
    dispatch({type: types.SET_LOADING, payload: {isLoading: false}});
  };

  const getDataWinningFetch = async (payload) => {
    const resp = await getTopTenWorks(payload);
    if (resp?.result) {
      setDataWinningRate(resp?.winningRateAndWinningBid);
    } else {
      setDataWinningRate(null);
    }
  };

  /**
   * event when submit form
   */
  const handleValueChangeFilter = (values) => {
    // const { artist_search, ...args } = values;
    setFilter({ ...filter, ...values });
    getDataArtistFilter({...filter, ...values})
  }

  useEffect(() => {
    if (dataWinningRate === null) return;
    const payload = {
      ...filter,
      timeFilter: typeTime,
    };
    getDataWinningFetch(payload);
  }, [typeTime]);

  // useEffect(() => {
  //   if (!filter?.querySearch || filter?.querySearch === "") {
  //     return;
  //   }
  //   getDataArtistFilter(filter);
  // }, [filter]);

  const handleSearch = debounce(async (value) => {
    if (value === "") {
      setFilter({ ...filter, querySearch: "" });
      formRef.current.handleLevel2({ ...filter, querySearch: "" })
      addParamUrl(history, filter, true);
      setData(null);
      setSummaryFilter(null);
      setOptions([]);
      setNotFoundArtist(false);
      return;
    }
    const resp = await getArtistName({ artistName: value });
    if (resp.message === "successfully!") {
      const results = resp.result.map((item) => ({
        label: item.artistName,
        value: item.artistName,
      }));

      if (resp.result.length <= 0) {
        setData(null);
        setSummaryFilter(null);
        setOptions([]);
        setNotFoundArtist(true);
      } else {
        setOptions(results);
        setNotFoundArtist(false);
      }
    } else {
      setOptions([]);
      // setMessage("Not found Artist!");
    }
  }, 600);

  const onSelect = (value) => {
    if (!value || value === "") return;
    formRef.current.handleLevel2({ ...filter, querySearch: value });
  };

  const renderArtistName = () => {
    if (!data) {
      let message = "";
      if (notFoundArtist) {
        message = "Not Found Artist";
      } else {
        message = "Please enter name artist!";
      }
      return (
        <div style={{ textAlign: "center", marginTop: "26px" }}>{message}</div>
      );
    }
    return (
      <>
        {filter?.querySearch && filter?.querySearch !== "" && (
          <S.ResultAuthor>
            <div className="author-name">
              {filter?.querySearch?.split("-")[0] ?? ""}
            </div>
            <div className="author-info">
              {filter?.querySearch?.split("-")[1] ?? ""} (
              {summaryFilter?.nationality1
                ? summaryFilter?.nationality1 + ", "
                : ""}
              {summaryFilter?.artist_birth && summaryFilter?.artist_death 
                ? `${summaryFilter?.artist_birth}-${summaryFilter?.artist_death}`
                : `${summaryFilter?.artist_birth ? `b.${summaryFilter?.artist_birth}` : ""}
                   ${summaryFilter?.artist_death ? `d.${summaryFilter?.artist_death}` : ""}`})
            </div>
          </S.ResultAuthor>
        )}
      </>
    );
  };

  return (
    <S.ContainerAll>
      <Title>Artist anlys</Title>
      <FilterForm
        ref={formRef}
        filter={formatDataFilter(filter)}
        children={
          <S.WrapInput>
            <AutoComplete
              dropdownMatchSelectWidth={252}
              style={{ width: 260 }}
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
              defaultValue={filter?.querySearch}
              allowClear
            >
              <Input placeholder="작가 검색" />
            </AutoComplete>
            <Button onClick={() => { }}>{t("SEARCH")}</Button>
          </S.WrapInput>
        }
        filterPage={"artist"}
        onValueChangeFilter={handleValueChangeFilter}
        searchName
      />
      <S.Container>
        <>{renderArtistName()}</>
        <AuthorCharts
          summaryInfo={summaryFilter}
          dataArtist={data}
          loading={loadingFilter}
          err={errFilter}
          dataWinning={dataWinningRate}
        />
      </S.Container>
    </S.ContainerAll>
  );
};

export default Author;
