/* eslint-disable jsx-a11y/img-redundant-alt */
import * as S from "./HomeCard.styles";
import NoImage from "../../../assets/images/noimg.jpeg";
import { checkValidImage } from "../../../utils/checkValue";

function HomeCard({ data }) {
  const renderImage = () => {
    if (!data?.image || data?.image === "" || data?.image === "[]")
      return NoImage;
    else {
      return checkValidImage(data?.image);
    }
  };

  return (
    <S.HomeCard>
      <S.Header>{data?.titleHead}</S.Header>

      <S.Image>
        <img
          src={renderImage()}
          onError={(event) => {
            event.target.src = NoImage;
            event.onerror = null;
          }}
          alt="image"
        />
      </S.Image>
      <S.ContentTitle>
        <div>{data?.titleContent}</div>
        {data?.artist && <div>{data?.artist}</div>}
        {!!data?.moreInfor && <S.MoreInfor>{data?.moreInfor}</S.MoreInfor>}
      </S.ContentTitle>

      {data?.contentText &&
        data?.contentText.map((value, index) => (
          <S.ContentText key={index}>
            <S.Label>{value.label}:</S.Label>
            <S.Value style={{ color: value?.color ?? "#2E2E2E" }}>
              {value.context}
              <S.Unit>{value.unit}</S.Unit>
            </S.Value>
          </S.ContentText>
        ))}
    </S.HomeCard>
  );
}

export default HomeCard;
