import moment from "moment";
import { MoneyConvert, checkNumberYoy } from "./util";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

/**
 * Function concat title and date with character ()
 * @param {string} title - data title get from api
 * @param {string} date - data date get from api
 * @returns {string} return string after concat title and date
 */
const convertTitleContent = (title, date) => {
  const newDate = !date ? "" : " (" + date + ")";
  return title.replace("-", " - ") + " " + newDate;
};

/**
 * Function concat title and date with character |
 * @param {string} title - data title get from api
 * @param {string} date - data date get from api
 * @returns {string} return string after concat title and date
 */
export const convertTitleArtist = (title, date) => {
  const newDate = !date ? "" : `(${date})`;
  return title + " " + newDate;
};

/**
 * Function concat price with "원"
 * @param {string} price - data price get from api
 * @returns {string} return string after concat price with "원"
 */
const concatPriceText = (price) => {
  return price + "원";
};

/**
 * Function concat percent with "%";
 * @param {string} price - data percent get from api
 * @param {string} ratioRaise - format add string "+"
 * @returns {string} return string after concat percent with "%";
 */
const concatPercentText = (percent, ratioRaise) => {
  // if (ratioRaise && ratioRaise === "raise") percent = "+" + percent;
  return percent + "%";
};

/**
 * Function convert data get from api to array card
 * @param {*} data - data get from api
 * @returns arrResult array after convert data
 */
export const useConvertDataCard = (data)=> {
  const { t } = useTranslation("common");
  let arrResult = [];
  if (data?.top_lot) {
    arrResult.push({
      titleHead: "Top lot",
      image: data?.top_lot.img_url,
      titleContent: convertTitleContent(data?.top_lot.title),
      moreInfor: (
        <>
          <p>
            {data?.top_lot?.material_eng ?? ""}, {data?.top_lot?.mfg_date ?? ""}
          </p>
          <p>
            {data?.top_lot?.size
              ? data?.top_lot?.size.replaceAll("-", " x ")
              : ""}{" "}
            cm
          </p>
          <p>
            by{" "}
            <span style={{ fontFamily: "NotoSansKR-Medium" }}>
              {data?.top_lot?.artist ?? ""}
            </span>
          </p>
          <p style={{ marginTop: "5px" }}>
            {data?.top_lot.transact_date
              ? moment(data?.top_lot.transact_date).format("YYYY-MM-DD")
              : ""}
          </p>
          <p style={{ marginBottom: "25px" }}>{data?.top_lot.company ?? ""}</p>
        </>
      ),
      contentText: [
        {
          label: <span className="priceBid">{t("WINNING_PRICE")}</span>,
          context: data?.top_lot.winning_bid
            ? MoneyConvert(data?.top_lot.winning_bid)
            : 0,
          color: "#A49039",
          unit: "원",
        },
      ],
    });
  }
  if (data?.top_artist) {
    arrResult.push({
      titleHead: "Top Artist",
      image: data?.top_artist.img_url,
      titleContent: convertTitleArtist(
        data?.top_artist.artist,
        data?.top_artist.artist_date
      ),
      contentText: [
        {
          label: t("TOTAL_WINNING_BID"),
          context: data?.top_artist.total_price
            ? MoneyConvert(data?.top_artist.total_price)
            : 0,
          unit: "원",
          color: "#A49039",
        },
        {
          label: t("NUMBER_OF_WORKS_ON_DISPLAY"),
          context: data?.top_artist.all_lot ?? 0,
          unit: "Lot",
        },
        {
          label: t("BID_WINNING_RATE"),
          context: concatPercentText(data?.top_artist.succes_rate.toFixed(1)),
        },
        {
          label: `YOY (${t("TOTAL_WINNING_BID")})`,
          context: data?.top_artist.artist_yoy
            ? checkNumberYoy(Number(data?.top_artist.artist_yoy).toFixed(1)) +
              "%"
            : "",
          color: data?.top_artist.artist_yoy > 0 ? "#ff0000" : "#2549fb",
        },
      ],
    });
  }
  if (data?.top_outperfomer) {
    arrResult.push({
      titleHead: "Top Outperfomer",
      image: data?.top_outperfomer.img_url,
      titleContent: convertTitleContent(
        data?.top_outperfomer.artist,
        data?.top_outperfomer.artist_date
          ? data?.top_outperfomer.artist_date.replaceAll("null", "")
          : ""
      ),
      contentText: [
        {
          label: t("TOTAL_WINNING_BID"),
          context: data?.top_outperfomer?.total_winbid_cur
            ? MoneyConvert(data?.top_outperfomer?.total_winbid_cur)
            : 0,
          unit: "원",
          color: "#A49039",
        },
        {
          label: t("NUMBER_OF_WORKS_ON_DISPLAY"),
          context: data?.top_outperfomer?.total_lot ?? 0,
          unit: "Lot",
        },
        {
          label: t("BID_WINNING_RATE"),
          context: `${
            data?.top_outperfomer?.bidRate
              ? Number(data?.top_outperfomer?.bidRate).toFixed(1)
              : 0.0
          }%`,
        },
        {
          label: "추정가상회율",
          context: `${
            data?.top_outperfomer?.above_rate
              ? Number(data?.top_outperfomer?.above_rate).toFixed(1)
              : 0.0
          }%`,
        },
        {
          label: `YOY (${t("AVERAGE_WINNING_PRICE")})`,
          context: data?.top_outperfomer.winbid_change
            ? (data?.top_outperfomer.winbid_change > 0 ? "+" : "") +
              MoneyConvert(data?.top_outperfomer.winbid_change, false, 1) + "%"
            : "",
          color:
            data?.top_outperfomer.winbid_change > 0 ? "#ff0000" : "#2549fb",
        },
      ],
    });
  }
  if (data?.rising_artist) {
    arrResult.push({
      titleHead: "Top EMERGING",
      image: data?.rising_artist.img_url,
      titleContent: convertTitleContent(
        data?.rising_artist.artist,
        data?.rising_artist.artist_date
      ),
      contentText: [
        {
          label: t("BID_WINNING_RATE"),
          context: MoneyConvert(data?.rising_artist.bid_rate),
          unit: "%",
          color: "#2e2e2e",
        },
        {
          label: t("TOTAL_WINNING_BID_THIS_YEAR"),
          context: MoneyConvert(data?.rising_artist.total_winbid_cur),
          unit: "원",
          color: "#A49039",
        },
        {
          label: t("TOTAL_WINNINGS_LAST_YEAR"),
          context: data?.rising_artist?.total_winbid_pre
            ? MoneyConvert(data?.rising_artist?.total_winbid_pre)
            : 0,
          unit: "원",
          color: "#A49039",
        },
        {
          label: `YOY (${t("TOTAL_WINNING_BID")})`,
          context: data?.rising_artist?.estimate_change
            ? checkNumberYoy(
                MoneyConvert(data?.rising_artist?.estimate_change, false, 1)
              ) + "%"
            : 0,
          color:
            data?.rising_artist?.estimate_change > 0 ? "#ff0000" : "#2549fb",
        },
      ],
    });
  }
  return arrResult;
}

/**
 * Function convert data get from api of rising artist
 * @param {*} data - data get from api
 * @returns dataRisingArtist object after convert data rising artist
 */
export function convertDataRisingArtist(data) {
  let dataRisingArtist = {};
  if (data?.rising_artist) {
    dataRisingArtist = {
      titleHead: "기대주",
      image: data?.rising_artist.img_url,
      titleContent: convertTitleArtist(
        data?.rising_artist.artist,
        data?.rising_artist.artist_date
          ? data?.rising_artist.artist_date.replaceAll("null", "")
          : ""
      ),
      contentText: [
        {
          label: "올해 낙찰총액",
          context: concatPriceText(
            data?.rising_artist.total_winbid_cur.toLocaleString()
          ),
        },
        {
          label: "작년 낙찰총액",
          context: concatPriceText(
            data?.rising_artist.total_winbid_pre.toLocaleString()
          ),
        },
        {
          label: "전년대비 평균 추정가 변화율",
          context: concatPercentText(
            data?.rising_artist.estimate_change
              ? data?.rising_artist.estimate_change.toFixed(2)
              : "0",
            "raise"
          ),
        },
      ],
    };
  }
  return dataRisingArtist;
}
