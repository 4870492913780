import { useHistory } from "react-router-dom";

function JointPurchaseMarket() {

  const history = useHistory();

  const renderAlert = () => {
    alert('준비 중인 페이지입니다');
    history.goBack();
  }

  return (
    <>
      {renderAlert()}
    </>
  );
}

export default JointPurchaseMarket;