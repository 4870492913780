import { DatePicker, Select } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  min-height: 942px;
  margin: 0 auto;
  background-color: #fff;
  padding: 35px 50px 72px;
  box-shadow: 0px 5px 7.5px rgba(0, 0, 0, 0.129412);
  border-radius: 0 0 45px 45px;
  position: relative;
  z-index: 1;

  .btn-submit {
    margin-left: 30px;
  }

  .paging {
    margin-bottom: 18px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  @media (max-width: 768px) {
    border-radius: 0 0 20px 20px;
    padding: 24px 20px 70px;

    .ant-space {
      gap: 8px 16px !important;
    }

    form {
      margin-bottom: 16px;
    }

    .ant-space-item {
      width: calc(50% - 8px);
    }

    .btn-submit {
      margin-left: 0;
    }
  }

  @media (max-width: 425px) {
    padding: 24px 15px 62px;
    box-shadow: 0px 7.5px 7.5px rgba(0, 0, 0, 0.129412);

    .ant-space {
      gap: 8px 10px !important;
    }

    .ant-space-item {
      width: calc(50% - 5px);
    }

    form {
      margin-bottom: 13px;
    }
  }
`;
export const DateTime = styled(DatePicker)`
  &.ant-picker {
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    width: 200px;
    height: 50px;
    padding-left: 18px;

    .ant-picker-input > input {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  &.ant-picker input::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
  }
`;
export const SelectOptions = styled(Select)`
  height: 50px;
  width: 200px;
  border-radius: 10px;
  border: solid 1px #e6e6e6;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 18px;
    font-family: NotoSansKR-Regular;
    border: none;
    border-radius: 10px;
  }
  .ant-select-selection-item {
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #202020;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const ContainerAll = styled.div`
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const Inner = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 18px auto 36px;

  display: flex;
  justify-content: space-between;

  background: #ffffff;
  border: 1px solid #a49039;
  border-radius: 22px;
  padding: 25px;

  .ant-row {
    margin-top: 0;
    min-height: 50px;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px 20px;
    margin: 30px 0;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 25px 15px;
  }
`;

export const WrapInput = styled.div`
  display: flex;
  justify-content: center;

  .ant-input {
    border-radius: 10px;
    height: 50px;
    padding-left: 20px;
    font-family: NotoSansKR-Medium;
    font-size: 15px;
    width: 260px;
    border-radius: 10px 0 0 10px !important;
    border: 1px solid #d6d0b3;
  }
  .ant-input::placeholder {
    text-overflow: unset;
  }
  .ant-btn {
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    /* background-image: linear-gradient(to bottom, #b291ff, #A49039); */
    background-color: #a49039;
    border: none;
    outline: none;
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
    border-radius: 0 10px 10px 0;

    &:hover {
      background-color: initial;
      background-image: linear-gradient(to bottom, #f7e387, #a49039);
      color: #fff;
    }

    &:focus {
      background-color: initial;
      background-image: linear-gradient(to bottom, #f7e387, #a49039);
      color: #fff;
    }

    &:disabled {
      background-image: none;
      background-color: #afafaf;
      color: #6a6a6a;
      cursor: not-allowed;
    }
  }

  @media (max-width: 768px) {
    .ant-select {
      flex: 1;
    }
  }
`;
