import { AutoComplete, Checkbox, Input } from "antd";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { dataNational } from "../../common/constants/dataSelect";
import { ContainerInput, SpaceStyle } from "../filterForm/FilterForm.style";

const styleLayout = {
  minWidth: 150,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 8,
};

const CheckboxRender2 = ({ options = [], inputSearch = false, name }) => {
  const watchFields = useWatch();
  const { setValue, getValues, control } = useFormContext();
  const [nationalSearch, setNationalSearch] = useState("");

  useEffect(() => {
    if (!watchFields[name] && watchFields[name]?.length === 0) {
      setValue(name, undefined);
    }
  }, [JSON.stringify(watchFields), nationalSearch]);

  const onSelect = (data) => {
    setValue("nationalitySearch", data, {
      shouldDirty: true,
    });
  };

  const onChange = (value) => {
    if (!value || value === "") {
      setValue("nationalitySearch", undefined, {
        shouldDirty: true,
      });
    }
  };

  const onClear = () => {
    setValue("nationalitySearch", undefined, {
      shouldDirty: true,
    });
  };

  return (
    <SpaceStyle direction="vertical">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox.Group {...field} options={options} style={styleLayout} />
        )}
      />

      {inputSearch && (
        <ContainerInput>
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            options={dataNational}
            onSelect={onSelect}
            onClear={onClear}
            onChange={onChange}
            allowClear
            defaultValue={getValues("nationalitySearch")}
            value={getValues("nationalitySearch")}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          >
            <Input.Search size="small" placeholder="입력" />
          </AutoComplete>
        </ContainerInput>
      )}
    </SpaceStyle>
  );
};

export default CheckboxRender2;
