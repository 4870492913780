import styled from "styled-components";

export const Container = styled.div`
  font-family: NotoSansKR-Medium;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  border-bottom: 1px dashed #d5d5d5;
`;
export const Label = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 17px;
  font-weight: 400px;
  line-height: 25px;
  color: #6e6e6e;
`;
export const Value = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 22px;
  font-weight: 700;
  overflow-wrap: break-word;
  line-height: 32px;
  color: #2e2e2e;

  span {
    font-weight: normal;
    font-size: 18px;
    font-family: NotoSansKR-Regular;
  }
`;
