import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { DMYFormat, YearFormat } from "../../utils/format/formatDate";
import moment from "moment";

const RangeDatePicker = ({ name }) => {
  const { setValue, getValues } = useFormContext();
  const fieldValue = getValues(name) || [];
  const [minDate, setMinDate] = useState(() =>
    fieldValue[0]?.length > 0 ? moment(fieldValue[0]) : null
  );
  const [maxDate, setMaxDate] = useState(() =>
    fieldValue[1]?.length > 0 ? moment(fieldValue[1]) : null
  );

  const disabledDate = (currentDate) => {
    return currentDate && currentDate.valueOf() < Date.parse(minDate);
  };

  useEffect(() => {
    if (!minDate && !maxDate) {
      return setValue(`${name}`, undefined);
    }
    if (name === "transactDate") {
      const minDatePicker = minDate ? moment(minDate).format("YYYY-MM-DD") : "";
      const maxDatePicker = maxDate ? moment(maxDate).format("YYYY-MM-DD") : "";
      setValue(`${name}`, [minDatePicker ?? "", maxDatePicker ?? ""], {
        shouldDirty: true,
      });
    }
    if (name === "artistBirth") {
      const minDatePicker = minDate?.format("YYYY");
      const maxDatePicker = maxDate?.format("YYYY");
      setValue(`${name}`, [minDatePicker ?? "", maxDatePicker ?? ""], {
        shouldDirty: true,
      });
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    if (!getValues(name)) {
      setMinDate(null);
      setMaxDate(null);
    }
  }, [JSON.stringify(getValues(name))]);

  return (
    <>
      {name === "transactDate" ? (
        <>
          <DatePicker
            value={minDate}
            format={DMYFormat}
            size="small"
            placeholder="Start date"
            onChange={(date, dateString) => setMinDate(date)}
          />
          <span>~</span>
          <DatePicker
            value={maxDate}
            format={DMYFormat}
            size="small"
            placeholder="End date"
            disabledDate={disabledDate}
            onChange={(date, dateString) => setMaxDate(date)}
          />
        </>
      ) : (
        <>
          <DatePicker
            value={minDate}
            format={YearFormat}
            name="minYear"
            picker="year"
            size="small"
            placeholder="Start year"
            onChange={(date, dateString) => setMinDate(date)}
          />
          <span>~</span>
          <DatePicker
            value={maxDate}
            format={YearFormat}
            picker="year"
            placeholder="End year"
            size="small"
            disabledDate={disabledDate}
            onChange={(date, dateString) => setMaxDate(date)}
          />
        </>
      )}
    </>
  );
};

export default React.memo(RangeDatePicker);
