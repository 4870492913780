import { DualAxes, G2, Pie } from "@ant-design/plots";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import GroupButton from "../../components/common/GroupButton";
import {
  formatDatasetsAuctionOccupy,
  formatDatasetsTop10,
} from "../../utils/chart/formatDatasetsArtMarket";
import {
  ConvertToDecimal,
  MoneyConvert,
  formatDataEstimateMin,
  formatTimeChart2,
} from "../../utils/util";
import { TYPE_BTN } from "../../common/constants/typeButton";
import { useTranslation } from "react-i18next";
ChartJS.register(...registerables);

function BidRateDoughnutgraph({ labels, reqdata, centerText }) {
  const data = {
    labels: labels,
    datasets: [
      {
        data: reqdata,
        backgroundColor: ["#fff", "#b291ff"],
        borderColor: ["#b291ff"],
        borderWidth: 1,
      },
    ],
  };

  const plugins = [
    {
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        ctx.font = 32 + "px NotoSansKR-Medium";
        ctx.textBaseline = "top";
        ctx.fillStyle = "#000";
        var text = centerText,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2 - 11;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const options = {
    cutout: 60,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return <Doughnut data={data} plugins={plugins} options={options} />;
}

function BidRateSecondary({ below, within, above, not_sold, winRate, sold }) {
  below = ConvertToDecimal(below ?? 0);
  within = ConvertToDecimal(within ?? 0);
  above = ConvertToDecimal(above ?? 0);
  not_sold = ConvertToDecimal(not_sold ?? 0);
  winRate = sold ? ConvertToDecimal(sold ?? 0) : ConvertToDecimal(winRate ?? 0);
  const data = [
    {
      type: "Above",
      value: above,
    },
    {
      type: "Within",
      value: within < 0 ? 0 : within,
    },
    {
      type: "Below",
      value: below,
    },
    {
      type: "Unsold",
      value: not_sold,
    },
  ];

  const config = {
    height: 350,
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    color: ["#c23162", "#70ad47", "#4472c4", "#ffc000"],
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
        fontSize: 12,
      },
      autoRotate: false,
      content: (item) => {
        // if (percentage.value <= 0) {
        //   return "";
        // }
        // return percentage.value + "%";

        if (item.value <= 0) return "";
        return Number(item.value).toFixed(1) + "%";
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#8d1d8b",
          fontSize: 32,
        },
        content: winRate.toFixed(1) + "%",
      },
    },
    legend: {
      position: "bottom",
    },
    tooltip: {
      // formatter: (item) => {
      //   return `${item.type}: ${Number(item.value).toFixed(1)}%`;
      // },
      formatter: (datum) => {
        return {
          name: datum.type,
          value: Number(datum.value).toFixed(1) + "%",
        };
      },
    },
  };

  return <Pie {...config} height={350} />;
}

function DistributionByWinbid({ lot = {}, price = {} }) {
  const label = [
    "100만원 미만",
    "100만원 - 1000만원 미만",
    "1000만원 - 5000만원 미만",
    "5000만원 - 1억원 미만",
    "1억원 - 3억원 미만",
    "3억원 - 5억원 미만",
    "5억원 이상",
  ];
  const backgroundColor = [
    "#ae4baf",
    "#70ad47",
    "#4472c4",
    "#ffc000",
    "#a5a5a5",
    "#ed7d31",
    "#584577",
  ];
  const borderColor = ["#fff"];
  let dataLot = [
    Number(lot?.from0),
    Number(lot?.from100),
    Number(lot?.from1000),
    Number(lot?.from5000),
    Number(lot?.from10000),
    Number(lot?.from30000),
    Number(lot?.from50000),
  ];
  const totalLot = dataLot.reduce((a, b) => a + b);
  let datasetsLot = [];
  let dataPrice = [
    price?.from0,
    price?.from100,
    price?.from1000,
    price?.from5000,
    price?.from10000,
    price?.from30000,
    price?.from50000,
  ];
  const totalPrice = dataPrice.reduce((a, b) => a + b);
  let datasetsPrice = [];
  for (var i = 0; i < 7; i++) {
    datasetsLot.push({
      label: label[i],
      data: [
        MoneyConvert((dataLot[i] / totalLot) * 100, false, 1),
        MoneyConvert((dataPrice[i] / totalPrice) * 100, false, 1),
      ],
      backgroundColor: backgroundColor[i],
      borderColor: borderColor[i],
      borderWidth: 1,
      barThickness: 100,
    });
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          textStrokeWidth: 100,
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          min: 0,
          max: 100,
          stepSize: 5,
          callback: function (value, index, ticks) {
            if (value % 25 === 0) {
              return value + "%";
            }
            return;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        bodyAlign: "center",
        titleAlign: "center",
        footerAlign: "center",
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.label === TYPE_BTN.VOLUME) {
              return `${tooltipItem.dataset.label} : ${dataLot[
                tooltipItem.datasetIndex
              ].toLocaleString("ko-KR")} 건`;
            } else {
              return `${tooltipItem.dataset.label} : ${dataPrice[
                tooltipItem.datasetIndex
              ].toLocaleString("ko-KR")} ₩`;
            }
          },
          footer: function (tooltipItem) {
            return `${tooltipItem[0].formattedValue}%`;
          },
        },
      },
    },
  };

  return (
    <div style={{ display: "flex" }}>
      <Bar
        type="bar"
        height={350}
        data={{
          labels: [TYPE_BTN.VOLUME, TYPE_BTN.VALUE],
          datasets: datasetsLot.concat(datasetsPrice),
        }}
        options={options}
      />
    </div>
  );
}

function StackbargraphPriceSecondary({ datas = {} }) {
  const label = Object.keys(datas);
  const backgroundColor = [
    "#8d6def",
    "#b291ff",
    "#5934cb",
    "#3811af",
    "#5b9bd5",
  ];
  const datasets = [];
  const dataRes = Object.values(datas);

  for (var i = 0; i < label.length; i++) {
    datasets.push({
      label: label[i],
      data: [dataRes[i]],
      backgroundColor: backgroundColor[i],
      borderColor: "#fff",
      borderWidth: 2,
    });
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        stacked: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
        },
        position: "bottom",
      },
    },
  };

  return (
    <Bar
      height={250}
      type="bar"
      data={{
        labels: ["낙찰총액"],
        datasets: datasets,
      }}
      options={options}
    />
  );
}

function MaterialRate({ datas = {} }) {
  let datasets = formatDatasetsAuctionOccupy(datas);
  const config = {
    height: 320,
    // appendPadding: 5,
    data: datasets,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    label: {
      type: "spider",
      labelHeight: 28,
      content: (v) => {return `${v.type}\n${MoneyConvert(v.value, false ,1)}%`},
      style: {
        fontWeight: "500",
        fill: "#000",
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    legend: {
      position: "bottom",
      offsetX: 0,
      flipPage: false,
    },
    tooltip: {
      formatter: (datum) => {
        return { name: datum.type, value: MoneyConvert(datum.value, false, 1) + '%' };
      },
    },
    color: [
      "#b47b56",
      "#000",
      "#d2b09a",
      "#89888e",
      "#e1cabc",
      "#b2b2b2",
      "#f0e5dd",
    ],
    responsive: true,
  };

  return <Pie {...config} />;
}

function Top10Chart({ datas = {} }) {
  const [type, setType] = useState(TYPE_BTN.VOLUME);
  const [dataRes, setDataRes] = useState([]);

  useEffect(() => {
    let res = [];
    switch (type) {
      case TYPE_BTN.VOLUME: {
        res = datas.hammer;
        break;
      }
      case TYPE_BTN.VALUE: {
        res = datas.bid;
        break;
      }
      default:
        break;
    }
    res = formatDatasetsTop10(res);
    setDataRes(res);
  }, [type, datas]);

  const handleSetType = (value) => {
    setType(value);
  };

  const data = {
    labels: dataRes?.arrArtist,
    datasets: [
      {
        type: "bar",
        label: TYPE_BTN.VOLUME,
        yAxisID: "lot",
        data: dataRes?.arrHammer,
        backgroundColor: ["#c2c2c2"],
        borderColor: ["#c2c2c2"],
        borderWidth: 1,
      },
      {
        type: "bar",
        label: TYPE_BTN.VALUE,
        yAxisID: "price",
        data: dataRes?.arrBid,

        backgroundColor: ["#000"],
        borderColor: ["#000"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div>
        <Bar
          height={350}
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              price: {
                type: "linear",
                position: "left",
                title: {
                  display: false,
                  text: "price",
                },
                ticks: {
                  callback: (value, index, ticks) => {
                    return MoneyConvert(Math.round(value / 100000000)) + "억원";
                  },
                  crossAlign: "far",
                },
              },
              lot: {
                type: "linear",
                position: "right",
                title: {
                  display: false,
                  text: "lot",
                },
                ticks: {
                  color: "#c2c2c2",
                  crossAlign: "far",
                },
                grid: {
                  drawOnChartArea: false,
                },
              },
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  callback: (label, index, labels) => {
                    return [
                      dataRes?.arrArtist[index].slice(0, 9),
                      dataRes?.arrArtist[index].slice(9),
                    ];
                  },
                  autoSkip: false,
                },
              },
            },
            plugins: {
              legend: {
                labels: {
                  usePointStyle: true,
                },
              },
              tooltip: {
                bodyAlign: "center",
                titleAlign: "center",
                footerAlign: "center",
                callbacks: {
                  label: function (tooltipItem, data) {
                    if (tooltipItem.dataset.yAxisID === "price") {
                      return `${tooltipItem.dataset.label} : ${MoneyConvert(
                        Number(tooltipItem.formattedValue.replaceAll(",", "")) /
                          100000000,
                        false,
                        1
                      )} 억원`;
                    } else {
                      return `${tooltipItem.dataset.label} : ${tooltipItem.formattedValue} 건`;
                    }
                  },
                },
              },
            },
          }}
        />
      </div>
      <GroupButton handleSetType={handleSetType} isTop10 type={type} />
    </>
  );
}

function OnOffBar({ datas }) {
  let dataBid = [];
  let dataHammer = [];
  let totalBid;
  let totalHammer;
  let datasets = [];
  const backgroundColor = ["#4472c4", "#ed7d31"];
  const labels = ["온라인", "오프라인"];
  if (datas) {
    dataBid = [datas.online.bid ?? 0, datas.offline.bid ?? 0];
    totalBid = dataBid.reduce((a, b) => a + b);
    dataHammer = [datas?.online.hammer ?? 0, datas?.offline.hammer ?? 0];
    totalHammer = dataHammer.reduce((a, b) => a + b);
  }
  for (let i = 0; i < 2; i++) {
    datasets.push({
      label: labels[i],
      data: [
        (dataBid[i] / totalBid) * 100,
        (dataHammer[i] / totalHammer) * 100,
      ],
      backgroundColor: backgroundColor[i],
      barThickness: 100,
    });
  }

  return (
    <Bar
      height={350}
      data={{
        labels: ["낙찰총액", "낙출작품수"],
        datasets: datasets,
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
            text: "Chart.js Bar Chart - Stacked",
          },
          datalabels: {
            color: "#000",
            formatter: (value, ctx) => {
              if (value === 0) return "";
              return Math.round(value) + "%";
            },
          },
          legend: {
            position: "bottom",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return (
                  context.chart.data.datasets[context.datasetIndex].label +
                  ": " +
                  Math.round(context.formattedValue) +
                  "%"
                );
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              beginAtZero: true,
            },
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            min: 0,
            max: 100,
            ticks: {
              display: false,
              stepSize: 20,
              callback: (value, index, tsx) => {
                if (value === 0) return value;
                return value + "%";
              },
            },
            grid: {
              drawBorder: false,
              drawTicks: false,
            },
          },
        },
      }}
      plugins={[ChartDataLabels]}
    />
  );
}

function RatioEntriesAndBid({
  datas = {},
  isShowBtn = true,
  typeData = "yearly",
  height = 400,
  isPadding = true,
  unit = 1,
  unitText = "m",
}) {
  const { t } = useTranslation("common");
  const [type, setType] = useState(typeData);
  const [dataRes, setDataRes] = useState([]);
  let labels = [];
  let arrTotalBid = [];
  let arrSold = [];
  let arrTotalOfWork = [];

  useEffect(() => {
    switch (type) {
      case "yearly": {
        setDataRes(datas?.yearly ?? []);
        break;
      }
      case "half": {
        setDataRes(datas?.half ?? []);
        break;
      }
      case "quarter": {
        setDataRes(datas?.quarter ?? []);
        break;
      }
      case "monthly": {
        setDataRes(datas?.monthly ?? []);
        break;
      }
      default:
        break;
    }
  }, [type, JSON.stringify(datas)]);

  const handleSetType = (values) => {
    setType(values);
  };

  labels = dataRes.map((value) => {
    if (type === "half" || type === "quarter") {
      return formatTimeChart2(value.date, type);
    }
    return value.date;
  });
  arrTotalBid = dataRes.map((value) => value.total_bid);
  arrSold = dataRes.map((value) => value.sold);
  arrTotalOfWork = dataRes.map((value) => value.notsold + value.sold);

  let dateData = [];
  let soldData = [];
  let totalWorkData = [];

  for (let i = 0; i < labels.length; i++) {
    dateData.push({
      time: labels[i],
      value: arrTotalBid[i],
    });
    soldData.push({
      time: labels[i],
      count: arrSold[i],
      name: t("NUMBER_OF_WINNING_WORKS_ORTHER"),
    });
    totalWorkData.push({
      time: labels[i],
      count: arrTotalOfWork[i],
      name: t("TOTAL_NUMBER_OF_WORKS_ON_DISPLAY"),
    });
  }

  const { registerTheme } = G2;
  registerTheme("custom-theme", {
    colors10: ["#bf2659", "#ffc000", "#70ad47"],
    colors20: ["#bf2659", "#ffc000", "#70ad47"],
  });

  const config = {
    height: height,
    data: [dateData, [...totalWorkData, ...soldData]],
    xField: "time",
    yField: ["value", "count"],
    geometryOptions: [
      {
        geometry: "column",
        columnWidthRatio: 0.4,
      },
      {
        geometry: "line",
        seriesField: "name",
      },
    ],
    theme: "custom-theme",
    legend: false,

    tooltip: {
      style: {
        textAlign: "left",
      },
      // formatter: ()
    },
    slider: { start: 0, end: 1 },
    limitInPlot: false,
    padding: isPadding ? [10, 30, 55, 40] : false,
    meta: {
      value: {
        alias: t("TOTAL_WINNING_BID"),
        formatter: (value, index) => {
          if (value === 0) return value;
          let newValue = value / unit;
          if (newValue >= 1) {
            newValue = MoneyConvert(newValue, false, 1);
          }
          return newValue + unitText;
        },
        sync: false,
      },
      time: {
        sync: false, // 开启之后 slider 无法重绘
      },
      count: {
        formatter: (value, index) => MoneyConvert(value),
        min: 0
      },
    },
  };

  return (
    <>
      <DualAxes {...config} />
      {isShowBtn && (
        <GroupButton
          style={{ marginTop: "-35px" }}
          handleSetType={handleSetType}
          type={type}
        />
      )}
    </>
  );
}

function RateByCategory({ datas = {} }) {
  const [type, setType] = useState("yearly");
  const [dataRes, setDataRes] = useState([]);
  const [labels, setLabels] = useState([]);

  let dataSet = [];
  let arrAbove = [];
  let arrWithin = [];
  let arrBelow = [];
  let arrNotSold = [];
  let arrTotalItem = [];
  let backgroundColor = ["#8c929b", "#dea454", "#85d6df", "#b0ba69"];
  let dataLabel = ["UNSOLD", "BELOW", "WITHIN", "ABOVE"];
  let dataChart = [];

  useEffect(() => {
    switch (type) {
      case "yearly": {
        setDataRes(
          datas?.yearly ? datas?.yearly.map(({ date, ...rest }) => rest) : []
        );
        setLabels(
          datas?.yearly ? datas?.yearly.map((values) => values.date) : []
        );
        break;
      }
      case "half": {
        setDataRes(
          datas?.half ? datas?.half.map(({ date, ...rest }) => rest) : []
        );
        setLabels(datas?.half ? datas?.half.map((values) => values.date) : []);
        break;
      }
      case "quarter": {
        setDataRes(
          datas?.quarter ? datas?.quarter.map(({ date, ...rest }) => rest) : []
        );
        setLabels(
          datas?.quarter ? datas?.quarter.map((values) => values.date) : []
        );
        break;
      }
      case "monthly": {
        setDataRes(
          datas?.monthly ? datas?.monthly.map(({ date, ...rest }) => rest) : []
        );
        setLabels(
          datas?.monthly ? datas?.monthly.map((values) => values.date) : []
        );
        break;
      }
      default:
        break;
    }
  }, [type, datas]);

  const handleSetType = (values) => {
    setType(values);
  };

  for (let i = 0; i < dataRes.length; i++) {
    arrTotalItem.push(Object.values(dataRes[i]).reduce((a, b) => a + b));
  }
  arrAbove = dataRes.map(
    (value, index) => (Number(value.above) / arrTotalItem[index]) * 100
  );
  arrBelow = dataRes.map(
    (value, index) => (Number(value.below) / arrTotalItem[index]) * 100
  );
  arrWithin = dataRes.map(
    (value, index) => (Number(value.within) / arrTotalItem[index]) * 100
  );
  arrNotSold = dataRes.map(
    (value, index) => (Number(value.notsold) / arrTotalItem[index]) * 100
  );
  dataChart = [arrNotSold, arrBelow, arrWithin, arrAbove];

  for (let i = 0; i < 4; i++) {
    dataSet.push({
      label: dataLabel[i],
      data: dataChart[i],
      backgroundColor: backgroundColor[i],
    });
  }

  return (
    <>
      <div>
        <Bar
          height={350}
          data={{
            labels: labels,
            datasets: dataSet,
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return (
                      context.chart.data.datasets[context.datasetIndex].label +
                      ": " +
                      context.formattedValue +
                      "%"
                    );
                  },
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                stepSize: 5,
                grid: {
                  display: false,
                },
                ticks: {
                  autoSkip: false,
                },
              },
              y: {
                stacked: true,
                beginAtZero: true,
                // min: 0,
                // max: 100,
                ticks: {
                  // stepSize: 25,
                  callback: function (value, index, ticks) {
                    // if (value % 25 === 0) {
                    //   return value + "%";
                    // }
                    return value + "%";
                  },
                },
                grid: {
                  borderDash: [2, 3],
                },
              },
            },
          }}
        />
      </div>
      <GroupButton handleSetType={handleSetType} type={type} />
    </>
  );
}

function RatioTotalBid({ datas = {} }) {
  const [type, setType] = useState("yearly");
  const [dataRes, setDataRes] = useState([]);
  const [labels, setLabels] = useState([]);
  // let labels = [];
  let dataSet = [];
  let dataLabel = [
    "100만원 미만",
    "100만원 – 1000만원 미만",
    "1000만원 – 5000만원 미만",
    "5000만원 – 1억원 미만",
    "1억원 - 3억원 미만",
    "3억원 - 5억원 미만",
    "5억원 이상",
  ];
  let backgroundColor = [
    "#b47b56",
    "#000",
    "#e1cabc",
    "#89888e",
    "#d2b09a",
    "#b2b2b2",
    "#f0e5dd",
  ];
  let fillColor = ["#fff", "#fff", "#000", "#000", "#000", "#000"];
  let totalItem = [];
  let arrFrom0 = [];
  let arrFrom100 = [];
  let arrFrom1000 = [];
  let arrFrom5000 = [];
  let arrFrom10000 = [];
  let arrFrom30000 = [];
  let arrFrom50000 = [];
  let allDataFrom = [];

  useEffect(() => {
    switch (type) {
      case "yearly": {
        setDataRes(datas?.yearly.map(({ date, ...rest }) => rest));
        setLabels(datas?.yearly.map((values) => values.date));
        break;
      }
      case "half": {
        setDataRes(datas?.half.map(({ date, ...rest }) => rest));
        setLabels(datas?.half.map((values) => values.date));
        break;
      }
      case "quarter": {
        setDataRes(datas?.quarter.map(({ date, ...rest }) => rest));
        setLabels(datas?.quarter.map((values) => values.date));
        break;
      }
      case "monthly": {
        setDataRes(datas?.monthly.map(({ date, ...rest }) => rest));
        setLabels(datas?.monthly.map((values) => values.date));
        break;
      }
      default:
        break;
    }
  }, [type, datas]);

  const handleSetType = (values) => {
    setType(values);
  };

  for (let i = 0; i < dataRes.length; i++) {
    totalItem.push(
      Object.values(dataRes[i]).reduce((a, b) => Number(a) + Number(b))
    );
  }
  arrFrom0 = dataRes.map(
    (value, index) => (Number(value.from0) / totalItem[index]) * 100
  );
  arrFrom100 = dataRes.map(
    (value, index) => (Number(value.from100) / totalItem[index]) * 100
  );
  arrFrom1000 = dataRes.map(
    (value, index) => (Number(value.from1000) / totalItem[index]) * 100
  );
  arrFrom5000 = dataRes.map(
    (value, index) => (Number(value.from5000) / totalItem[index]) * 100
  );
  arrFrom10000 = dataRes.map(
    (value, index) => (Number(value.from10000) / totalItem[index]) * 100
  );
  arrFrom30000 = dataRes.map(
    (value, index) => (Number(value.from30000) / totalItem[index]) * 100
  );
  arrFrom50000 = dataRes.map(
    (value, index) => (Number(value.from50000) / totalItem[index]) * 100
  );
  allDataFrom = [
    arrFrom0,
    arrFrom100,
    arrFrom1000,
    arrFrom5000,
    arrFrom10000,
    arrFrom30000,
    arrFrom50000,
  ];
  for (let i = 0; i < 7; i++) {
    dataSet.push({
      label: dataLabel[i],
      data: allDataFrom[i],
      backgroundColor: backgroundColor[i],
      fillColor: fillColor[i],
    });
  }

  return (
    <>
      <div>
        <Bar
          height={350}
          data={{
            labels: labels,
            datasets: dataSet,
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
              },
              legend: {
                display: false,
              },
              datalabels: {
                color: (chart) => {
                  return chart.dataset.fillColor;
                },
                formatter: (value, ctx) => {
                  if (value === 0) return "";
                  return value.toFixed(1) + "%";
                },
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return (
                      context.chart.data.datasets[context.datasetIndex].label +
                      ": " +
                      Number(context.formattedValue).toFixed(1) +
                      "%"
                    );
                  },
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  autoSkip: false,
                },
              },
              y: {
                stacked: true,
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 20,
                  callback: (value, index, ticks) => {
                    return value + "%";
                  },
                },
                grid: {
                  borderDash: [2, 3],
                },
              },
            },
          }}
          plugins={[ChartDataLabels]}
        />
      </div>
      <GroupButton handleSetType={handleSetType} type={type} />
    </>
  );
}

function BidWining({ datas = {} }) {
  const [type, setType] = useState("yearly");
  const [dataRes, setDataRes] = useState([]);
  let labels = [
    "1 백만원 미만",
    "1-10 백만원",
    "10-50 백만원",
    "50-100 백만원",
    "100-300 백만원",
    "300-500 백만원",
    "500 백만원 이상",
  ];
  let backgroundColor = ["#b47b56", "#000", "#e1cabc", "#b2b2b2"];
  let dataSet = [];
  let dataLabel = new Array(4);
  let arrDataCal = [];

  useEffect(() => {
    switch (type) {
      case "yearly": {
        let newData = formatDataEstimateMin(datas.yearly.slice(-4), 1, "yearly");
        setDataRes(newData);
        break;
      }
      case "half": {
        let newData = formatDataEstimateMin(datas.half.slice(-4), 6, "month");
        setDataRes(newData);
        break;
      }
      case "quarter": {
        let newData = formatDataEstimateMin(datas.quarter.slice(-4), 3, "month");
        setDataRes(newData);
        break;
      }
      case "monthly": {
        let newData = formatDataEstimateMin(datas.monthly.slice(-4), 1, "month");
        setDataRes(newData);
        break;
      }
      default:
        break;
    }
  }, [type, datas]);

  const handleSetType = (value) => {
    setType(value);
  };

  dataLabel = dataRes.map((value) => value.date);
  arrDataCal = dataRes.map(({ date, ...rest }) => Object.values(rest));
  arrDataCal = arrDataCal.map((value) =>
    value.map((val) => (val ? val * 100 : 0))
  );
  for (let i = 0; i < dataLabel.length; i++) {
    dataSet.push({
      label: formatTimeChart2(dataLabel[i], type),
      data: arrDataCal[i],
      backgroundColor: backgroundColor[i],
    });
  }

  return (
    <>
      <div>
        <Bar
          height={320}
          data={{
            labels: labels,
            datasets: dataSet,
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
                align: "end",
                lables: {
                  filter: function (item, chart) {
                    if (item.text === 0) {
                      return false;
                    } else {
                      return item;
                    }
                  },
                },
              },
              title: {
                display: false,
                text: "Chart.js Bar Chart",
              },
              datalabels: {
                align: "top",
                anchor: "end",
                formatter: Math.round,
                font: {
                  size: "10",
                },
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    return (
                      context.chart.data.datasets[context.datasetIndex].label +
                      ": " +
                      Math.round(context.formattedValue) +
                      "%"
                    );
                  },
                },
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
                ticks: {
                  display: true,
                },
              },
              y: {
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 25,
                  callback: (value, index, tsx) => {
                    if (value === 0) return value;
                    return value + "%";
                  },
                },
                grid: {
                  borderDash: [2, 3],
                  drawTicks: true,
                },
              },
            },
            // layout: {
            //   padding: {
            //     top: 50,
            //   },
            // },
          }}
          plugins={[ChartDataLabels]}
        />
      </div>
      <GroupButton handleSetType={handleSetType} type={type} />
    </>
  );
}

function RatioCompany({ datas = {} }) {
  const [type, setType] = useState("yearly");
  const [arrOccupy, setArrOccupy] = useState([]);
  const [labels, setLabels] = useState([]);
  let dataLabel = ["서울옥션", "케이옥션", "그 외"];
  const backgroundColor = ["#b1174d", "#ef7a91", "#e2e2e2"];
  const colors = ["#fff", "#000", "#000", "#000"];
  let dataSet = [];
  let totalItem = [];
  let arrSeoulAuction = [];
  let arrKOction = [];
  // let arrArtDayAuction = [];
  let arrOther = [];

  useEffect(() => {
    switch (type) {
      case "yearly": {
        setArrOccupy(
          datas?.yearly ? datas?.yearly.map((value) => value.occupy) : []
        );
        setLabels(
          datas?.yearly ? datas?.yearly.map((values) => values.date) : []
        );
        break;
      }
      case "half": {
        setArrOccupy(
          datas?.half ? datas?.half.map((value) => value.occupy) : []
        );
        setLabels(datas?.half ? datas?.half.map((values) => values.date) : []);
        break;
      }
      case "quarter": {
        setArrOccupy(
          datas?.quarter ? datas?.quarter.map((value) => value.occupy) : []
        );
        setLabels(
          datas?.quarter ? datas?.quarter.map((values) => values.date) : []
        );
        break;
      }
      case "monthly": {
        setArrOccupy(
          datas?.monthly ? datas?.monthly.map((value) => value.occupy) : []
        );
        setLabels(
          datas?.monthly ? datas?.monthly.map((values) => values.date) : []
        );
        break;
      }
      default:
        break;
    }
  }, [type, datas]);

  const handleSetType = (values) => {
    setType(values);
  };

  for (let i = 0; i < arrOccupy.length; i++) {
    let arrBidItem = arrOccupy[i].map((val) => val.bid);
    totalItem.push(arrBidItem.reduce((a, b) => a + b));
  }
  arrSeoulAuction = arrOccupy.map((value, index) => {
    let item = value.find((val) => val.auction === "서울옥션");
    if (!item || item === null) return 0;
    return Math.round((item.bid / totalItem[index]) * 100);
  });
  arrKOction = arrOccupy.map((value, index) => {
    let item = value.find((val) => val.auction === "케이옥션");
    if (!item || item === null) return 0;
    return Math.round((item.bid / totalItem[index]) * 100);
  });
  // arrArtDayAuction = arrOccupy.map((value, index) => {
  //   let item = value.find((val) => val.auction === "아트데이옥션");
  //   if (!item || item === null) return 0;
  //   return Math.round((item.bid / totalItem[index]) * 100);
  // });
  arrOther = arrOccupy.map((value, index) => {
    let other = value.filter(
      (val) => val.auction !== "서울옥션" && val.auction !== "케이옥션"
      // val.auction !== "아트데이옥션"
    );
    if (other.length === 0) return 0;
    return Math.round(
      (other.reduce((a, b) => a + b.bid, 0) / totalItem[index]) * 100
    );
  });
  let AllN = [arrSeoulAuction, arrKOction, arrOther];
  for (let i = 0; i < 3; i++) {
    dataSet.push({
      label: dataLabel[i],
      data: AllN[i],
      backgroundColor: backgroundColor[i],
      fillColor: colors[i],
    });
  }

  return (
    <>
      <div>
        <Bar
          height={350}
          data={{
            labels: labels,
            datasets: dataSet,
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
              },
              legend: {
                position: "top",
                align: "end",
              },
              datalabels: {
                color: (chart) => {
                  return chart.dataset.fillColor;
                },
                formatter: (value, ctx) => {
                  if (value === 0) return "";
                  return value + "%";
                },
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return (
                      context.chart.data.datasets[context.datasetIndex].label +
                      ": " +
                      Math.round(context.formattedValue) +
                      "%"
                    );
                  },
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  autoSkip: false,
                },
              },
              y: {
                stacked: true,
                grid: {
                  borderDash: [2, 3],
                },
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 20,
                  callback: (value, index, ticks) => {
                    return value + "%";
                  },
                },
              },
            },
          }}
          plugins={[ChartDataLabels]}
        />
      </div>
      <GroupButton handleSetType={handleSetType} type={type} />
    </>
  );
}

function TrendsDomesticAuction({ datas = {} }) {
  const [type, setType] = useState("yearly");
  const [arrOnline, setArrOnline] = useState([]);
  const [arrOffline, setArrOffline] = useState([]);
  const [labels, setLabels] = useState([]);
  let dataLabel = ["온라인", "오프라인"];
  const backgroundColor = ["#4472c4", "#ed7d31"];
  let dataSet = [];

  useEffect(() => {
    switch (type) {
      case "yearly": {
        setLabels(
          datas?.yearly ? datas?.yearly.map((value) => value.date) : []
        );
        setArrOnline(
          datas?.yearly
            ? datas?.yearly.map(
                (value) =>
                  (Math.round(value.online) /
                    (Math.round(value.offline) + Math.round(value.online))) *
                  100
              )
            : []
        );
        setArrOffline(
          datas?.yearly
            ? datas?.yearly.map(
                (value) =>
                  (Math.round(value.offline) /
                    (Math.round(value.offline) + Math.round(value.online))) *
                  100
              )
            : []
        );
        break;
      }
      case "half": {
        setLabels(datas?.half ? datas?.half.map((value) => value.date) : []);
        setArrOnline(
          datas?.half
            ? datas?.half.map(
                (value) =>
                  (Math.round(value.online) /
                    (Math.round(value.offline) + Math.round(value.online))) *
                  100
              )
            : []
        );
        setArrOffline(
          datas?.half
            ? datas?.half.map(
                (value) =>
                  (Math.round(value.offline) /
                    (Math.round(value.offline) + Math.round(value.online))) *
                  100
              )
            : []
        );
        break;
      }
      case "quarter": {
        setLabels(
          datas?.quarter ? datas?.quarter.map((value) => value.date) : []
        );
        setArrOnline(
          datas?.quarter
            ? datas?.quarter.map(
                (value) =>
                  (Math.round(value.online) /
                    (Math.round(value.offline) + Math.round(value.online))) *
                  100
              )
            : []
        );
        setArrOffline(
          datas?.quarter
            ? datas?.quarter.map(
                (value) =>
                  (Math.round(value.offline) /
                    (Math.round(value.offline) + Math.round(value.online))) *
                  100
              )
            : []
        );
        break;
      }
      case "monthly": {
        setLabels(
          datas?.monthly ? datas?.monthly.map((value) => value.date) : []
        );
        setArrOnline(
          datas?.monthly
            ? datas?.monthly.map(
                (value) =>
                  (Math.round(value.online) /
                    (Math.round(value.offline) + Math.round(value.online))) *
                  100
              )
            : []
        );
        setArrOffline(
          datas?.monthly
            ? datas?.monthly.map(
                (value) =>
                  (Math.round(value.offline) /
                    (Math.round(value.offline) + Math.round(value.online))) *
                  100
              )
            : []
        );
        break;
      }
      default:
        break;
    }
  }, [type, datas]);

  const handleSetType = (values) => {
    setType(values);
  };

  let AllN = [arrOnline, arrOffline];
  for (let i = 0; i < 2; i++) {
    dataSet.push({
      label: dataLabel[i],
      data: AllN[i],
      backgroundColor: backgroundColor[i],
    });
  }

  return (
    <>
      <div>
        <Bar
          height={350}
          data={{
            labels: labels,
            datasets: dataSet,
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
              },
              legend: { position: "bottom" },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return (
                      context.chart.data.datasets[context.datasetIndex].label +
                      ": " +
                      Math.round(context.formattedValue) +
                      "%"
                    );
                  },
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  autoSkip: false,
                  fontSize: 10,
                },
              },
              y: {
                stacked: true,
                // min: 0,
                // max: 100,
                ticks: {
                  // stepSize: 25,
                  callback: (value, index, tsx) => {
                    if (value === 0) return value;
                    return value + "%";
                  },
                },
              },
            },
          }}
        />
      </div>
      <GroupButton handleSetType={handleSetType} type={type} />
    </>
  );
}

function RatioByGroupOfWork({ datas = {} }) {
  const [type, setType] = useState("yearly");
  const [dataRes, setDataRes] = useState([]);
  const [labels, setLabels] = useState([]);
  let dataSet = [];
  let dataLabel = ["그룹 A", "그룹 B", "그룹 C", "그룹 D", "OTHERS"];
  let backgroundColor = ["#b47b56", "#000", "#e1cabc", "#89888e", "#d2b09a"];
  let fillColor = ["#fff", "#fff", "#000", "#000", "#000"];
  let arrGroupA = [];
  let arrGroupB = [];
  let arrGroupC = [];
  let arrGroupD = [];
  let arrGroupOther = [];
  let allDataGroup = [];

  useEffect(() => {
    switch (type) {
      case "yearly": {
        setLabels(datas?.yearly.map((value) => value.date));
        setDataRes(datas?.yearly);
        break;
      }
      case "half": {
        setLabels(datas?.half.map((value) => value.date));
        setDataRes(datas?.half);
        break;
      }
      case "quarter": {
        setLabels(datas?.quarter.map((value) => value.date));
        setDataRes(datas?.quarter);
        break;
      }
      case "monthly": {
        setLabels(datas?.monthly.map((value) => value.date));
        setDataRes(datas?.monthly);
        break;
      }
      default:
        break;
    }
  }, [type, datas]);

  const handleSetType = (values) => {
    setType(values);
  };
  arrGroupA = dataRes.map((val) => val.a);
  arrGroupB = dataRes.map((val) => val.b);
  arrGroupC = dataRes.map((val) => val.c);
  arrGroupD = dataRes.map((val) => val.d);
  arrGroupOther = dataRes.map((val) => 100 - val.a - val.b - val.c - val.d);
  allDataGroup = [arrGroupA, arrGroupB, arrGroupC, arrGroupD, arrGroupOther];
  for (let i = 0; i < 5; i++) {
    dataSet.push({
      label: dataLabel[i],
      data: allDataGroup[i],
      backgroundColor: backgroundColor[i],
      fillColor: fillColor[i],
    });
  }

  return (
    <>
      <div>
        <Bar
          height={350}
          data={{
            labels: labels,
            datasets: dataSet,
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
              },
              legend: {
                position: "top",
                align: "end",
              },
              datalabels: {
                color: (chart) => {
                  return chart.dataset.fillColor;
                },
                formatter: (value, ctx) => {
                  if (value === 0) return "";
                  return `${value ? Number(value).toFixed(1) : 0}%`;
                },
                font: {
                  size: 11,
                },
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return (
                      context.chart.data.datasets[context.datasetIndex].label +
                      ": " +
                      Number(context.formattedValue).toFixed(1) +
                      "%"
                    );
                  },
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  autoSkip: false,
                },
              },
              y: {
                stacked: true,
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 20,
                  callback: (value, index, ticks) => {
                    return value + "%";
                  },
                },
                grid: {
                  borderDash: [2, 3],
                },
              },
            },
          }}
          plugins={[ChartDataLabels]}
        />
      </div>
      <GroupButton handleSetType={handleSetType} type={type} />
    </>
  );
}

export {
  DistributionByWinbid,
  MaterialRate,
  OnOffBar,
  RatioEntriesAndBid,
  RateByCategory,
  RatioTotalBid,
  BidWining,
  TrendsDomesticAuction,
  RatioCompany,
  RatioByGroupOfWork,
  Top10Chart,
  BidRateSecondary,
  BidRateDoughnutgraph,
  StackbargraphPriceSecondary,
};
