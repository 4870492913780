import styled from "styled-components";

export const HomeCard = styled.div`
  width: 100%;
  height: 100%;
  padding: 27px 30px 21px;
  border-radius: 20px;
  border: 1px solid #a49039;
  background-color: #fff;

  .priceBid {
    font-weight: bold;
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 24px 30px 29px;
    min-height: 529px;
  }

  @media screen and (max-width: 425px) {
    background-color: inherit;
  }
`;

export const Header = styled.div`
  margin-bottom: 11px;
  font-family: NotoSansKR-Bold;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 29px;
  color: #a49039;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    margin-bottom: 14px;
  }
`;

export const MoreInfor = styled.div`
  font-family: NotoSansKR-Regular;
  margin-bottom: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #918e8e;

  @media screen and (max-width: 768px) {
    margin-top: 4px;

    p {
      &:last-child {
        margin-bottom: 20px !important;
      }
    }
  }
`;

export const Image = styled.div`
  padding: 34.1px 24px;
  border: 1px solid #dfd6b0;
  border-radius: 20px;
  background-color: #fff;
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
  margin-bottom: 19px;

  img {
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const ContentTitle = styled.div`
  margin-bottom: 5px;

  div {
    &:first-child {
      font-family: NotoSansKR-Bold;
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      line-height: 29px;
      color: #2e2e2e;
    }
  }
`;

export const ContentText = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 18px;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6e6e6e;
  word-break: keep-all;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

export const Label = styled.div`
  font-family: NotoSansKR-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #918e8e;
`;

export const Value = styled.div`
  font-family: NotoSansKR-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #a49039;
`;

export const Unit = styled.span`
  font-family: NotoSansKR-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`;
