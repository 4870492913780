import { DownOutlined, UpOutlined } from "@ant-design/icons";
import korea_flag from "../../assets/images/korea_flag.png";
import vietnam_flag from "../../assets/images/vietnam_flag.png";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setKey } from "../../App";
import types from "../../store/actions/types";
import * as S from "./Lang.styles";

function useLangSelects() {
  const dispatch = useDispatch();
  const { lang } = useSelector((state) => state);
  const { i18n } = useTranslation("common");

  function changeLang(lang) {
    setKey("lang", lang);
    dispatch({
      type: types.LANG_INFO,
      payload: {
        isShowLangOption: false,
        lang: lang,
      },
    });
    i18n.changeLanguage(lang);
  }

  return (
    <S.Container>
      <S.SlectLang
        onClick={() => {
          dispatch({
            type: types.LANG_INFO,
            payload: {
              isShowLangOption: true,
              lang: lang.lang,
            },
          });
        }}
      >
        <S.LangItem>
          <img
            src={lang?.lang === "kr" ? korea_flag : vietnam_flag}
            alt="lang item"
          />
          <span>{lang?.lang === "kr" ? "Kor" : "Vi"}</span>
        </S.LangItem>
        {lang?.isShowLangOption ? (
          <UpOutlined size={32} className="icon_select" />
        ) : (
          <DownOutlined size={32} className="icon_select" />
        )}
      </S.SlectLang>
      <S.LangList isShow={lang?.isShowLangOption}>
        <S.LangItem onClick={() => changeLang("kr")}>
          <img src={korea_flag} alt="lang item" />
          <span>Kor</span>
        </S.LangItem>
        <S.LangItem onClick={() => changeLang("vi")}>
          <img src={vietnam_flag} alt="lang item" />
          <span>Vi</span>
        </S.LangItem>
      </S.LangList>
    </S.Container>
  );
}

export default useLangSelects;
