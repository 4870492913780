import * as S from "./ArtMarketChart.styles";

import { Button, Col, Row } from "antd";

import { DownloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Loading from "../../components/common/Loading";
import {
  BidRateSecondary,
  BidWining,
  DistributionByWinbid,
  MaterialRate,
  RatioEntriesAndBid,
  Top10Chart,
} from "../../containers/Chart/ChartSecondary";
import { downloadImage } from "../../utils/downloadChartImg";
import { ConvertHundredMillion, ConvertRatio, MoneyConvert } from "../../utils/util";
import BarPlotChart2 from "../BarPlotChart2";
import ButtonDownloadImg from "../ButtonDownloadImg";
import PiePlotChart from "../PiePlotChart";
import StackedColumnChart from "../StackedColumnChart";

function ArtMarketChart({ summaryInfo, dataArtMarket, err, loading }) {
  const { t } = useTranslation("common");

  return (
    <>
      <>
        {err ? (
          <h2 style={{ textAlign: "center", marginTop: 16 }}>{err}</h2>
        ) : (
          // <Loading isLoad={loading} isNotFixed>
            dataArtMarket && (
              <S.ResultContent>
                <S.Summary>
                  <S.ItemSummary>
                    <S.Lable>{t("NUMBER_OF_WORKS_ON_DISPLAY")}</S.Lable>
                    <S.Value>
                      {(summaryInfo?.total_lot).toLocaleString() ?? 0}{" "}
                      <span>(LOT)</span>
                    </S.Value>
                  </S.ItemSummary>
                  <S.ItemSummary>
                    <S.Lable>{t("NUMBER_OF_WINNING_WORKS")}</S.Lable>
                    <S.Value>
                      {(summaryInfo?.number_of_winbid).toLocaleString() ?? 0}{" "}
                      <span>(LOT)</span>
                    </S.Value>
                  </S.ItemSummary>
                  <S.ItemSummary>
                    <S.Lable>{t("BID_WINNING_RATE")}</S.Lable>
                    <S.Value>
                      {ConvertRatio(summaryInfo?.hammer_rate)} <span>%</span>
                    </S.Value>
                  </S.ItemSummary>
                  <S.ItemSummary>
                    <S.Lable>{t("TOTAL_WINNING_BID")}</S.Lable>
                    <S.Value>
                      {MoneyConvert(summaryInfo?.total_bid / 100000000, false, 1)}{" "}
                      <span>억원</span>
                    </S.Value>
                  </S.ItemSummary>
                </S.Summary>
                <S.WrapChart>
                  <Row gutter={[32, 40]}>
                    <Col xs={24} md={24} lg={24}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="Bid_Chart">
                          <S.BoxInner>
                            <S.InnerBoxItem>
                              <S.Title>{t("TREND_OF_TOTAL_WINNINGS_NUMBER_OF_WORKS_ON_DISPLAY_ORTHER")}</S.Title>
                              <ButtonDownloadImg
                                handleOnClick={() =>
                                  downloadImage("Bid_Chart", "Bid_Chart")
                                }
                              />
                            </S.InnerBoxItem>
                            <RatioEntriesAndBid
                              datas={dataArtMarket?.lotByPeriod ?? []}
                              isShowBtn={true}
                              unit={100000000}
                              unitText="억원"
                              height={372}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <S.BoxItem>
                        <S.BoxInnerWrap
                          id="SuccessfulBidRate"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <S.InnerBoxItem className="custom-label">
                            <S.Title>{t("BID_WINNING_RATE")}</S.Title>
                            <Button
                              type="text"
                              icon={<DownloadOutlined size={32} />}
                              size={"small"}
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #a8a8a8",
                                marginBottom: "10px",
                              }}
                              onClick={() =>
                                downloadImage(
                                  "SuccessfulBidRate",
                                  "ArtMaket_SuccessfulBidRate"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner doughnut customHeight>
                            <BidRateSecondary
                              below={dataArtMarket?.totalBid.below}
                              within={dataArtMarket?.totalBid.within}
                              above={dataArtMarket?.totalBid.above}
                              not_sold={dataArtMarket?.totalBid.notsold}
                              winRate={dataArtMarket?.totalBid.win_rate}
                              label="bid rate"
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={16} lg={16}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="SuccessfulBidRateTrend">
                          <S.InnerBoxItem>
                            <S.Title>{t("TENDER_WINNING_RATE_TREND")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "SuccessfulBidRateTrend",
                                  "ArtMaket_SuccessfulBidRateTrend"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <StackedColumnChart
                              colors={[
                                "#c23162",
                                "#70ad47",
                                "#4472c4",
                                "#ffc000",
                              ]}
                              data={dataArtMarket?.resultByPeriod ?? []}
                              typeChart="RESULT"
                              height={322}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="PercentageOfSuccessfulBidPriceBySection">
                          <S.InnerBoxItem>
                            <S.Title>{t("WEIGHT_OF_EACH_WINNING_PRICE_BRACKET")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "PercentageOfSuccessfulBidPriceBySection",
                                  "ArtMaket_PercentageOfSuccessfulBidPriceBySection"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <DistributionByWinbid
                              lot={dataArtMarket?.lotByWinbid}
                              price={dataArtMarket?.priceByWinbid}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={16} lg={16}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="TotalSpecificGravityOfEachWinningBidSection">
                          <S.InnerBoxItem>
                            <S.Title>{t("THE_TREND_OF_THE_PROPORTION_OF_TOTAL_MONEY_BY_EACH_BIDDING_PRICE_BRACKET")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "TotalSpecificGravityOfEachWinningBidSection",
                                  "ArtMaket_TotalSpecificGravityOfEachWinningBidSection"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <StackedColumnChart
                              colors={[
                                "#584577",
                                "#ed7d31",
                                "#a5a5a5",
                                "#ffc000",
                                "#4472c4",
                                "#70ad47",
                                "#ae4baf",
                              ]}
                              data={dataArtMarket?.bidByPeriod ?? []}
                              typeChart="BID_PERIOD"
                              height={322}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="OnlineOfflineSpecificGravity">
                          <S.InnerBoxItem>
                            <S.Title>{t("TREND_OF_ON_OFF_RATIO")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "OnlineOfflineSpecificGravity",
                                  "ArtMaket_OnlineOfflineSpecificGravity"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <StackedColumnChart
                              colors={["#4472c4", "#ed7d31"]}
                              data={dataArtMarket?.onoffByPeriod ?? []}
                              typeChart="ONOFF"
                              height={320}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={16} lg={16}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="TrendOfSuccessfulBidRateByLowEstimatedHouseholds">
                          <S.InnerBoxItem>
                            <S.Title>{t("TREND_OF_WINNING_RATE_ACCORDING_TO_EACH_PRICE_FRAME_ESTIMATE_MIN")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "TrendOfSuccessfulBidRateByLowEstimatedHouseholds",
                                  "ArtMaket_TrendOfSuccessfulBidRateByLowEstimatedHouseholds"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <BidWining datas={dataArtMarket?.esmByPeriod} />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <S.BoxItem>
                        <S.BoxInnerWrap
                          id="MarketShare"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <S.InnerBoxItem className="custom-label">
                            <S.Title>{t("TREND_OF_MARKET_SHARE_RATIO")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "MarketShare",
                                  "ArtMaket_MarketShare"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner style={{ paddingLeft: "16px" }}>
                            <MaterialRate
                              datas={dataArtMarket?.auctionOccupy}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={16} lg={16}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="MarketShareTrend">
                          <S.InnerBoxItem>
                            <S.Title>{t("TREND_OF_MARKET_SHARE_RATIO")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "MarketShareTrend",
                                  "ArtMaket_MarketShareTrend"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <StackedColumnChart
                              colors={["#b1174d", "#ef7a91", "#d8d8d8"]}
                              data={dataArtMarket?.occupyByPeriod ?? []}
                              ishowLabel={true}
                              typeChart="OCCUPY"
                              height={320}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="Top10Artist">
                          <S.InnerBoxItem>
                            <S.Title>TOP 10 ARTIST</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "Top10Artist",
                                  "ArtMaket_Top10Artist"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <Top10Chart datas={dataArtMarket?.top10} />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={24} lg={24}>
                      <S.BoxItem className="chart-cover">
                        <S.BoxInnerWrap id="PercentageOfTotalAmountByWriterGroup">
                          <S.InnerBoxItem>
                            <S.Title>{t("TRENDS_IN_THE_PROPORTION_OF_TOTAL_MONEY_BY_GROUP_OF_AUTHORS")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "PercentageOfTotalAmountByWriterGroup",
                                  "ArtMaket_PercentageOfTotalAmountByWriterGroup"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <StackedColumnChart
                              colors={[
                                "#c23162",
                                "#64daab",
                                "#6595f8",
                                "#f7c122",
                                "#cc5eff",
                                "#aaa9aa",
                              ]}
                              data={dataArtMarket?.groupByPeriod ?? []}
                              typeChart="GROUP_PERIOD"
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>

                    <Col xs={24} md={8} lg={8}>
                      <S.BoxItem>
                        <S.BoxInnerWrap
                          id="ShareGlobal"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <S.InnerBoxItem className="custom-label">
                            <S.Title>{t("THE_SHARE_OF_THE_GLOBAL_ART_MARKET")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage(
                                  "ShareGlobal",
                                  "ArtMaket_ShareGlobal"
                                )
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner
                            style={{
                              paddingLeft: "16px",
                              paddingRight: "16px",
                            }}
                          >
                            <PiePlotChart
                              data={dataArtMarket?.artMarketShare ?? {}}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="National1">
                          <S.InnerBoxItem>
                            <S.Title>{t("WEIGHT_BY_NATIONALITY_OF_AUTHOR_1_EACH_CONTINENT")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage("National1", "ArtMaket_National1")
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <BarPlotChart2
                              data={
                                dataArtMarket?.shareOfNationalityByContinents ??
                                {}
                              }
                              styleButton={{ marginTop: 14 }}
                              isCountries={false}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                    <Col xs={24} md={8} lg={8}>
                      <S.BoxItem>
                        <S.BoxInnerWrap id="National2">
                          <S.InnerBoxItem>
                            <S.Title>{t("WEIGHT_FOR_EACH_AUTHOR_NATIONALITY_1_COUNTRY")}</S.Title>
                            <ButtonDownloadImg
                              handleOnClick={() =>
                                downloadImage("National2", "ArtMaket_National2")
                              }
                            />
                          </S.InnerBoxItem>
                          <S.BoxInner>
                            <BarPlotChart2
                              data={
                                dataArtMarket?.shareOfNationalityByCountries ??
                                {}
                              }
                              styleButton={{ marginTop: 14 }}
                            />
                          </S.BoxInner>
                        </S.BoxInnerWrap>
                      </S.BoxItem>
                    </Col>
                  </Row>
                </S.WrapChart>
              </S.ResultContent>
            )
        )}
      </>
    </>
  );
}

export default ArtMarketChart;
