export const DMYFormat = "YYYY-MM-DD";
export const YearFormat = "YYYY";

export function dateToYMD(date) {
  var d = date.getDate();
  var m = date.getMonth() + 1;
  var y = date.getFullYear();
  return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
}
export function DMYCurrent() {
  const currentTime = new Date();

  var day = currentTime.getDate();
  var month = currentTime.getMonth() + 1;
  var year = currentTime.getFullYear();

  return `${year}-${month}-${day}`;
}
