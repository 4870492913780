import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { NAME_FILTERS } from "../../common/constants/filtersName";
import * as S from "../filterForm/FilterForm.style";
import Chip from "./Chip/Chip";

const FieldsValueFilters = ({ children, handleDeleteFilter }) => {
  const watchFields = useWatch();
  const { reset } = useFormContext();

  const { querySearch, ...resFields } = watchFields;
  const isValidFields = Object.keys(resFields).some((key) => {
    if (resFields[key]?.length <= 0 || !resFields[key]) return false;
    return true;
  });

  return (
    <>
      {isValidFields && (
        <S.SpaceStyle wrap>
          {Object.keys(NAME_FILTERS).map((key) => (
            <Chip
              key={key}
              label={key}
              content={watchFields[key]}
              handleDeleteFilter={handleDeleteFilter}
            />
          ))}
          {children}
          <S.ButtonStyle
            onClick={() => {
              reset((formValues) => {
                return Object.keys(formValues).reduce((objReset, field) => {
                  objReset["bidClass"] = "";
                  objReset["pricetp"] = "";
                  objReset["price"] = "";
                  objReset["material"] = "";
                  objReset["materialSearch"] = "";
                  objReset["transactDate"] = undefined;
                  objReset["mfgDate"] = "";
                  objReset["height"] = "";
                  objReset["width"] = "";
                  objReset["title"] = "";
                  objReset["company"] = "";
                  objReset["location"] = "";
                  objReset["onOff"] = "";
                  objReset["auctionName"] = "";
                  objReset["sizeTable"] = "";
                  objReset["querySearch"] = "";
                  objReset["mainColor"] = "";
                  objReset["mainColorSearch"] = "";
                  objReset["method"] = "";
                  objReset["series"] = "";
                  objReset["preference"] = "";
                  objReset["identicalRecord"] = "";
                  return objReset;
                }, {});
              });
              handleDeleteFilter?.(true);
            }}
          >
            초기화
          </S.ButtonStyle>
        </S.SpaceStyle>
      )}
    </>
  );
};

export default FieldsValueFilters;
