import { Input } from "antd";
import moment from "moment";
import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";
import { dataColors, dataLocationShort, dataMethods, dataPreferences } from "../../common/constants/dataSelect";
import {
  AUC_COM,
  BID_CLASS,
  BID_FILTER,
  MATERIAL,
  NAME_FILTERS,
  ON_OFF_LIST,
} from "../../common/constants/filtersName";
import { getParamUrlValue, compareParamsUrlValue, changeParamsUrl } from "../../utils/url";
import CheckboxActive from "./CheckboxActive";
import CheckboxRender from "./CheckboxRender";
import CheckboxRender2 from "./CheckboxRender2";
import FieldsValueFilters from "./FieldsValueFilters";
import * as S from "./FilterForm.style";
import PopoverEl from "./PopoverEl";
import RangeDatePicker from "./RangeDatePicker";
import SelectMinMax from "./SelectMinMax";
import { useTranslation } from "react-i18next";
import { getSelectLevel2Data } from "../../apis/auth";
import ButtonCus from "../Button";
import { formatDataFilter } from "../../utils/format/formatDataFilter";

const getDefaultValue = (value) =>
  typeof value === "string" ? [value] : value;

const FilterForm = forwardRef(({
  filter,
  search = false,
  searchName = false,
  className,
  children,
  onValueChangeFilter = () => { },
}, ref) => {
  const { t } = useTranslation("common");
  const location = useLocation();
  const history = useHistory();
  const currentDate = new Date();
  // const [openFilter, setOpenFilter] = useState(false);
  //initial value form
  const defaultValues = {
    // querySearch: getParamUrlValue(location, "querySearch"),
    bidClass: getDefaultValue(getParamUrlValue(location, "bidClass")),
    pricetp: getParamUrlValue(location, "pricetp"),
    price: getParamUrlValue(location, "price"),
    material:
      getDefaultValue(getParamUrlValue(location, "material")) ??
      (searchName ? ["Painting"] : undefined),
    materialSearch: getParamUrlValue(location, "materialSearch"),
    transactDate:
      getDefaultValue(getParamUrlValue(location, "transactDate")) ??
      (searchName
        ? [
          moment([
            new Date().getFullYear() - (new Date().getMonth() === 0 ? 4 : 3), 
            new Date().getMonth() === 0 
              ? 11 
              : new Date().getMonth() - 1]
            )
          .endOf('month').format("YYYY-MM-DD"),
          moment([
            new Date().getFullYear() - (new Date().getMonth() === 0 ? 1 : 0), 
            new Date().getMonth() === 0 
              ? 11 
              : new Date().getMonth() - 1]
            )
          .endOf('month').format("YYYY-MM-DD"),
        ]
        : undefined),
    mfgDate: getDefaultValue(getParamUrlValue(location, "mfgDate")),
    height: getDefaultValue(getParamUrlValue(location, "height")),
    width: getDefaultValue(getParamUrlValue(location, "width")),
    title: getParamUrlValue(location, "title"),
    company: getDefaultValue(getParamUrlValue(location, "company")),
    location: getDefaultValue(getParamUrlValue(location, "location")),
    locationSearch: getParamUrlValue(location, "locationSearch"),
    onOff: getParamUrlValue(location, "onOff"),
    certification: getParamUrlValue(location, "certification"),
    auctionName: getParamUrlValue(location, "auctionName"),
    sizeTable: getDefaultValue(getParamUrlValue(location, "sizeTable")),
    mainColor: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "mainColor")) : undefined,
    method: getDefaultValue(getParamUrlValue(location, "method")),
    series: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "series")) : undefined,
    preference: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "preference")) : undefined
  };
  const [reloadFilter, setReloadFilter] = useState(false);
  const [dataSelectLevel2, setDataSelectLevel2] = useState(null);

  const useFormMethods = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
    setValue
  } = useFormMethods;

  useImperativeHandle(ref, () => ({
    handleLevel2: getSelectLevel2
  }))

  /**
   *  event search of form search
   * @param {object} values value of form search
   * @returns {void}
   */
  const onSearch = (values) => {
    if (!checkExistFilterLevel1(values))
      clearLevel2Filter();
    onValueChangeFilter(values);
  };

  // get only fields changed in filter level1 
  const changeFilterLevel1 = (values) => {
    const newValues = { ...values }
    delete newValues.mainColor
    delete newValues.series
    delete newValues.preference
    return newValues
  }

  //check fields of filter level 1
  const checkExistFilterLevel1 = (values) => {
    if (!values.querySearch) return false;
    const filterLevel1 = changeFilterLevel1(values);
    for (const properties in filterLevel1) {
      if (filterLevel1[properties] && filterLevel1[properties]?.length > 0) return true;
    }
    return false;
  }

  //set values of tags when filter level 2 changed
  const handleChangeFilterLevel2 = (key, datas) => {
    if (watch(key)) {
      const newArray = watch(key)?.filter((item) => datas?.includes(item));
      setValue(key, newArray);
    }
  }

  // clear tag and filter level 2 when filter level 1 undefined
  const clearLevel2Filter = () => {
    setValue("mainColor", undefined);
    setValue("series", undefined);
    setValue("preference", undefined);
  }

  const getSelectLevel2 = async (values) => {
    try {
      const { querySearch, ...restValues } = values;
      if (checkExistFilterLevel1(values) && querySearch) {
        const resp = await getSelectLevel2Data({artistName: querySearch, ...restValues});
        if (resp) {
          setDataSelectLevel2({
            mainColor: resp?.data?.main_color,
            series: resp?.data?.series,
            preference: dataPreferences.filter(item => resp?.data?.preference.includes(item))
          });
          handleChangeFilterLevel2("mainColor", resp?.data?.main_color);
          handleChangeFilterLevel2("series", resp?.data?.series);
          handleChangeFilterLevel2("preference", resp?.data?.preference);
          onSearch({...watch(), querySearch});
          if (resp?.data?.main_color.length === 0 && resp?.data?.series.length === 0 && resp?.data?.preference.length === 0) {
            clearLevel2Filter(watch());
          }
        }
      } else {
        setDataSelectLevel2(null);
        clearLevel2Filter()
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  const handleReset = () => {
    onSearch(watch());
  }

  useEffect(() => {
    getSelectLevel2(filter);
  }, [])

  const dataLocationUsed = dataLocationShort.map((x) => {
    return x.label;
  });

  return (
    <FormProvider {...useFormMethods}>
      <S.WrapFilter>
        {children}
        <form onSubmit={handleSubmit(onSearch)}>
          <>
            <S.ContainerFilters className={className}>
              <PopoverEl
                name="company"
                text={t(NAME_FILTERS.company)}
                content={<CheckboxRender name="company" options={AUC_COM} />}
              />
              <PopoverEl
                name="auctionName"
                text={t(NAME_FILTERS.auctionName)}
                content={
                  <Controller
                    name="auctionName"
                    control={control}
                    render={({ field }) => (
                      <Input {...field} size="small" placeholder="경매명" />
                    )}
                  />
                }
              />
              <PopoverEl
                name="transactDate"
                text={t(NAME_FILTERS.transactDate)}
                content={<RangeDatePicker name="transactDate" />}
              />
              <PopoverEl
                name={["location", "locationSearch"]}
                text={t(NAME_FILTERS.location)}
                content={
                  <CheckboxRender2 name="location" options={dataLocationUsed} />
                }
              />
              <PopoverEl
                name="onOff"
                text={t(NAME_FILTERS.onOff)}
                content={<CheckboxActive name="onOff" options={ON_OFF_LIST} />}
              />
              <PopoverEl
                name="title"
                text={t(NAME_FILTERS.title)}
                content={
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <Input {...field} size="small" placeholder="작품명 입력" />
                    )}
                  />
                }
              />
              <PopoverEl
                name="mfgDate"
                text={t(NAME_FILTERS.mfgDate)}
                content={<RangeDatePicker name="mfgDate" />}
              />
              <PopoverEl
                name={["material", "materialSearch"]}
                text={t(NAME_FILTERS.material)}
                content={
                  <CheckboxRender
                    inputSearch
                    name="material"
                    options={MATERIAL}
                  />
                }
              />
              <PopoverEl
                name="height"
                text={t(NAME_FILTERS.height)}
                content={<SelectMinMax name="height" />}
              />
              <PopoverEl
                name="width"
                text={t(NAME_FILTERS.width)}
                content={<SelectMinMax name="width" />}
              />
              <PopoverEl
                name="sizeTable"
                text={t(NAME_FILTERS.sizeTable)}
                content={<SelectMinMax name="sizeTable" />}
              />
              <PopoverEl
                name={["pricetp", "price"]}
                text={t(NAME_FILTERS.price)}
                content={<SelectMinMax pricept name="price" />}
              />
              {search && (
                <>
                  <PopoverEl
                    name="bidClass"
                    text={t(NAME_FILTERS.bidClass)}
                    content={
                      <CheckboxRender name="bidClass" options={BID_CLASS} />
                    }
                  />
                </>
              )}
              <PopoverEl
                name="method"
                text={t(NAME_FILTERS.method)}
                content={
                  <CheckboxRender name="method" options={dataMethods} />
                }
              />
              {dataSelectLevel2 && <PopoverEl
                name={"mainColor"}
                text={<p className="filter-level-2">{t(BID_FILTER.mainColor)}</p>}
                content={
                  <CheckboxRender
                    name="mainColor"
                    options={dataSelectLevel2?.mainColor}
                  />
                }
                disabled={dataSelectLevel2?.mainColor?.length === 0}
              />
              }
              {dataSelectLevel2 && <PopoverEl
                name="series"
                text={<p className="filter-level-2">{t(NAME_FILTERS.series)}</p>}
                content={
                  <CheckboxRender name="series" options={dataSelectLevel2?.series} />
                }
                disabled={dataSelectLevel2?.series?.length === 0}
              />}
              {dataSelectLevel2 && <PopoverEl
                name="preference"
                text={<p className="filter-level-2">{t(NAME_FILTERS.preference)}</p>}
                content={
                  <CheckboxRender name="preference" options={dataSelectLevel2?.preference} />
                }
                disabled={dataSelectLevel2?.preference?.length === 0}
              />}
            </S.ContainerFilters>
          </>
        </form>
      </S.WrapFilter>
      <S.WrapTags>
        <FieldsValueFilters filter={filter} handleDeleteFilter={handleReset}>
          {compareParamsUrlValue(location, formatDataFilter(watch())) === 0 ? <ButtonCus onClick={() => getSelectLevel2({...filter, ...watch()})}>
            {t("FILTER")}
          </ButtonCus> : ""}
        </FieldsValueFilters>
      </S.WrapTags>
    </FormProvider>
  );
});

export default FilterForm;
