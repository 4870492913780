import moment from "moment";
import queryString from "query-string";

export function ConvertToDecimal(num) {
  num = num.toString(); //If it's not already a String
  num = num.slice(0, num.indexOf(".") + 3); //With 3 exposing the hundredths place
  return Number(num);
}

export const MoneyConvert = (value, fixed0 = true, numFixed = 2) => {
  if (value === undefined || value === null || value === "") return 0;
  if (fixed0) {
    return Number(value)
      .toFixed(0)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return Number(value)
    .toFixed(Number.isInteger(Number(value)) ? 0 : numFixed)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export function median(arr) {
  if (arr.length === 0) {
    return; // 0.
  }
  arr.sort((a, b) => a - b); // 1.
  const midpoint = Math.floor(arr.length / 2); // 2.
  const median =
    arr.length % 2 === 1
      ? arr[midpoint] // 3.1. If odd length, just take midpoint
      : (arr[midpoint - 1] + arr[midpoint]) / 2; // 3.2. If even length, take median of midpoints
  return median;
}

export function ConvertTenThousand(value, divisor = 10000) {
  if (!value || value === "") return 0;
  const num = (Number(value) / divisor)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return num.indexOf(".") !== -1 ? num.slice(0, num.indexOf(".")) : num;
}

export const checkNumberYoy = (value) => {
  if (Number(value) > 0) return "+" + value;
  return value;
};

export function ConvertHundredMillion(value, divisor = 100000000) {
  if (!value || value === "") return 0;
  const num = (Number(value) / divisor)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return num.indexOf(".") !== -1 ? num.slice(0, num.indexOf(".")) : num;
}

export function ConvertRatio(value) {
  if (!value || value === "") return 0;
  return Math.round(value * 10) / 10;
}

export const checkSort = (sort) => {
  if (!sort) return null;
  if (sort === "asc") {
    return "ascend";
  } else if (sort === "desc") {
    return "descend";
  }
};

export const formatTimeChart = (time, type, typeChart) => {
  if (typeChart) {
    return time;
  } else {
    if (type && type !== "yearly") {
      if (typeof time === "number" || !time.includes("-")) return time;
      const arrTime = time.split("-");
      const year = arrTime[1];
      let realTime = arrTime[0];
      if (type === "half") {
        realTime = arrTime[0] + "H";
      } else if (type === "quarter") {
        realTime = arrTime[0] + "Q";
      }
      return year + "." + realTime;
    }
  }
  return time + "";
};

export const formatTimeChart2 = (time, type) => {
  if (type && type !== "yearly") {
    if (typeof time === "number" || !time.includes(".")) return time;
    const arrTime = time.split(".");
    let realTime = arrTime[1];
    const year = arrTime[0];
    if (type === "half") {
      realTime = Number(arrTime[1]) / 6 + "H";
    } else if (type === "quarter") {
      realTime = Number(arrTime[1]) / 3 + "Q";
    }
    return year + "." + realTime;
  }
  return time;
};

export const getParamUrlValue = (location, paramName) => {
  const query = queryString.parse(location?.search);
  return query[paramName]?.length > 0 ? query[paramName] : undefined;
};

export const calculateRatioYear = (current, next) => {
  const newCurrent = current ?? 0;
  if (next === 0) {
    if (current === 0) return 0;
    else return 100;
  }
  return (newCurrent / next - 1) * 100;
};


export const labelNumberFormatter = (value, unit) => {
  let labelNumber = Number(value.split(unit)[0].replaceAll(",","") || 0);
  if (labelNumber > 0 && labelNumber < 1) {
    return MoneyConvert(labelNumber, false, 1)+unit;
  } else {
    return MoneyConvert(labelNumber, false, 0)+unit;
  }
}

export const sortDataEstimateMin = (data) => {
  return data.sort((a,b) => {
    let dateA = new Date((a.date + ".01").replace(".", "-")).getTime();
    let dateB = new Date((b.date + ".01").replace(".", "-")).getTime();
    if (dateA - dateB >= 0) return 1;
    return -1;
  })
}

export const formatDataEstimateMin = (data, unit, type) => {
  if (!data || data.length === 0) return [];
  if (type !== "yearly") data = sortDataEstimateMin(data);
  let newData = [data[data.length  - 1]];
  let lastDate = data[data.length  - 1]?.date;

  while (newData.length < 4) {
    if (type === "yearly") {
      lastDate = Number(lastDate) - 1;
    } else {
      let dateFormatted = (lastDate+".01").replace(".", "-");
      lastDate = moment(dateFormatted).subtract(unit, "month").format("yyyy.M");
    }
    
    const element = data.find((item) => item.date === String(lastDate));
    if (element) {
      newData.push(element);
    } else {
      newData.push({
        date: lastDate,
        from0: 0,
        from100: 0,
        from1000: 0,
        from5000: 0,
        from10000: 0,
        from30000: 0,
        from50000: 0
      })
    }
  }
  return newData;
}