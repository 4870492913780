import { Row, Col } from "antd";
import * as S from "./PageNotFound.styles";
import { URL } from "../../common/constants/endpoints";
import { useHistory } from "react-router-dom";

function PageNotFound() {
  const history = useHistory();
  return (
    <>
      <Row justify="center">
        <Col>
          <S.Container>
            <S.Text>page not found</S.Text>
            <S.Note>
              We're sorry, the page you requested could not be found. Please go
              back to the homepage.
            </S.Note>
            <S.ButtonStyled onClick={() => history.push(URL.MAIN)}>
              Back Main Page
            </S.ButtonStyled>
          </S.Container>
        </Col>
      </Row>
    </>
  );
}

export default PageNotFound;
