import styled from "styled-components";

export const Image = styled.div`
  img {
    width: 100px;
    max-height: 100px;
    object-fit: contain;
  }
`;

export const Container = styled.div`
  img {
    width: 50px;
    object-fit: contain;
    max-height: 50px;
  }

  .ant-image {
    display: flex;
    justify-content: center;
  }

  .ant-table-wrapper {
    width: 100%;
    table {
      width: 3000px !important;
    }
  }

  .ant-table-thead {
    & > tr {
      & > th {
        padding: 10px;
      }
    }
  }

  .ant-table-body {
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      &:horizontal {
        -webkit-appearance: none;
        height: 7px;
        width: 100px;
        display: block;
        border-radius: 5px;
      }
    }
  }

  .ant-table-cell-fix-left {
    background-color: rgb(245, 245, 245);
  }
`;
