import { useTranslation } from "react-i18next";
import * as S from "./TableList.styles";

const TableList = ({ data = [] }) => {
  const { t } = useTranslation("common");

  return (
    <S.Container>
      <S.Row>
        <S.Label>{t("NUMBER_OF_WORKS_ON_DISPLAY")}</S.Label>
        <S.Value>
          {data?.numberWorksOutStock} <span>(lot)</span>
        </S.Value>
      </S.Row>
      <S.Row>
        <S.Label>{t("NUMBER_OF_WINNING_WORKS")}</S.Label>
        <S.Value>
          {data?.numberWorksAuction} <span>(lot)</span>
        </S.Value>
      </S.Row>
      <S.Row>
        <S.Label>{t("TOTAL_WINNING_BID")}</S.Label>
        <S.Value>
          {data?.totalBidAmount} <span>(원)</span>
        </S.Value>
      </S.Row>
      <S.Row>
        <S.Label>{t("BID_WINNING_RATE")}</S.Label>
        <S.Value>{data?.biddingRate}%</S.Value>
      </S.Row>
    </S.Container>
  );
};

export default TableList;
