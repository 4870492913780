export const URL = {
  HOME: '/',
  LOGIN: "/login",
  MAIN: '/main',
  TOTAL_BID: '/totalbid',
  SEARCH: '/search',
  ARTMARKET: '/art-market',
  ARTIST: '/artist',
  JOINT_PURCHASE_MARKET: '/joint-purchase-market',
  PAGE_NOT_FOUND: '/404'
};
