import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { URL } from "./common/constants/endpoints";
import GlobalStyles from "./common/style/GlobalStyle.styles";
import Normalize from "./common/style/Normalize.styles";
import ArtMarket from "./pages/ArtMarket";
import Author from "./pages/Author";
import JointPurchaseMarket from "./pages/JointPurchaseMarket";
import { Login } from "./pages/Login/Login";
import Main from "./pages/Main";
import PageNotFound from "./pages/PageNotFound";
import Search from "./pages/Search";
import TotalBid from "./pages/TotalBid";
import { PrivateRoute, PublicRoute } from "./router";
import Test from "./pages/Test";
import { useSelector } from "react-redux";
import Loading from "./components/common/Loading";

export const setKey = (name, value) => {
  return localStorage.setItem(name, value);
};
export const getKey = (name) => {
  return localStorage.getItem(name);
};
export const removeKey = (name) => {
  return localStorage.removeItem(name);
};

function App() {
  const {isLoading} = useSelector((state) => state.isLoading);
  return (
    <div>
      <Normalize />
      <GlobalStyles />
      <Router>
        <Switch>
          <PublicRoute exact path={URL.LOGIN} component={Login} />
          <PrivateRoute exact path={URL.MAIN} component={Main} />
          <PrivateRoute exact path={URL.TOTAL_BID} component={TotalBid} />
          <PrivateRoute exact path={URL.SEARCH} component={Search} />
          <PrivateRoute exact path="/test" component={Test} />
          <PrivateRoute exact path={URL.ARTMARKET} component={ArtMarket} />
          <PrivateRoute exact path={URL.ARTIST} component={Author} />
          <PrivateRoute
            exact
            path={URL.JOINT_PURCHASE_MARKET}
            component={JointPurchaseMarket}
          />
          <PrivateRoute exact path={URL.HOME} component={Main} />
          <PrivateRoute exact={true} path={"*"} component={PageNotFound} />
        </Switch>
      </Router>
      {isLoading && <Loading isLoad={isLoading} />}
    </div>
  );
}

export default App;
