import { Redirect, Route, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { URL } from "../common/constants/endpoints";
import HeaderMenu from "../containers/Header/Header";
import FooterPage from "../components/common/Footer";
import OnToTop from "../components/OnToTop";

export function PublicRoute({ component: TargetPage, ...rest }) {
  return (
    <>
      <HeaderMenu className="header-menu" />
      <Route {...rest} render={(props) => <TargetPage {...props} />} />
    </>
  );
}

export function PrivateRoute({ component: TargetPage, ...rest }) {
  const history = useHistory();
  let userData = useSelector((state) => state.user);

  // useEffect(() => {
  //   if (!userData) {
  //     history.push(URL.LOGIN, { fromURL: history.location.path });
  //   }
  // }, [userData]);

  return (
    <>
      <HeaderMenu />
      <Route
        {...rest}
        render={(props) =>
          userData !== null ? (
            <TargetPage {...props} />
          ) : (
            <Redirect
              to={{
                pathname: URL.LOGIN,
                state: { fromURL: props?.location.pathname },
              }}
            />
          )
        }
      />
      <OnToTop />
      <FooterPage />
    </>
  );
}
