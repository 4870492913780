import * as S from "./Footer.styles";
import LogoImg from '../../../assets/images/logo.png';

function FooterPage() {
  return (
    <S.Footer>
      <S.Container>
        <S.FooterInner>
          <S.Logo>
            <img src={LogoImg} alt="logo.png"/>
          </S.Logo>
          <S.Company>
            <S.Title>COMPANY</S.Title>
            <S.TextContent>㈜열매컴퍼니</S.TextContent>
            <S.TextContent>대표자: 김재욱</S.TextContent>
            <S.TextContent>
              주소: 06182 서울 강남구 영동대로 407, 코니빌딩 4층 31470 충청남도
              아산시 배방읍 희망로46번길 45-11, 502호
            </S.TextContent>
            <S.TextContent>사업자등록번호: 664-88-00573</S.TextContent>
          </S.Company>
          <S.Contact>
            <S.Title>CONTACT</S.Title>
            <S.NumberContact>02-514-0772 ~ 070-5015-0772</S.NumberContact>
            <S.TextContent>평일-10:00~18:00</S.TextContent>
            <S.TextContent>점심시간-12:00~13:00</S.TextContent>
          </S.Contact>
        </S.FooterInner>
      </S.Container>
    </S.Footer>
  );
}

export default FooterPage;
