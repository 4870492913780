import { Column } from "@ant-design/plots";
import { useEffect, useState } from "react";
import GroupButton from "../common/GroupButton";
import { formatTimeChart2 } from "../../utils/util";
import { useTranslation } from "react-i18next";

const StackedColumnChart = ({
  data = [],
  ishowLabel,
  colors,
  typeChart,
  height,
  mt,
}) => {
  const { t } = useTranslation("common");
  const [type, setType] = useState("yearly");
  const [dataChart, setDataChart] = useState([]);

  const config = {
    height: height ?? 390,
    data: dataChart,
    xField: "year",
    yField: "value",
    seriesField: "type",
    isPercent: true,
    isStack: true,
    yAxis: {
      label: {
        formatter: (v) => {
          return v * 100 + "%";
        },
      },
    },
    meta: {
      value: {
        min: 0,
        max: 1,
      },
    },
    label: {
      position: "middle",
      content: (item) => {
        const val = parseFloat(item.value);
        if (val < 0.05) {
          return "";
        }
        return ishowLabel ? `${(item.value * 100).toFixed(1)}%` : "";
      },
      style: {
        fill: "#fff",
      },
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: "interval-adjust-position",
        }, // 数据标签防遮挡
        // {
        //   type: "interval-hide-overlap",
        // }, // 数据标签文颜色自动调整
        // {
        //   type: "adjust-color",
        // },
      ],
    },
    slider: {},
    color: colors,
    legend: {
      position: "bottom",
    },
    padding: [10, 10, 90, 35],
    tooltip: {
      formatter: (v) => {
        if (typeChart && typeChart === "GROUP_PERIOD") {
          return {
            name: v.type ? v.type.split(":")[0] : "",
            value: `${(v.value * 100).toFixed(1)}%`,
          };
        }
        return {
          name: v.type,
          value: `${(v.value * 100).toFixed(1)}%`,
        };
      },
    },
  };

  useEffect(() => {
    if (typeChart === "OCCUPY") {
      const dtSeoulAuction = data[type].map((item) => {
        const occupys = item.occupy;
        const seoulAuctionBid = occupys.find(
          (x) => x.auction === "서울옥션"
        )?.bid;
        return {
          year: formatTimeChart2(item.date, type),
          type: t("SEOUL_AUCTION"),
          value: seoulAuctionBid,
        };
      });
      const dtKAuction = data[type].map((item) => {
        const occupys = item.occupy;
        const kAuctionBid = occupys.find((x) => x.auction === "케이옥션")?.bid;
        return {
          year: formatTimeChart2(item.date, type),
          type: t("K_AUCTION"),
          value: kAuctionBid,
        };
      });
      const dtOtherAuction = data[type].map((item) => {
        const occupys = item.occupy;
        let other = occupys.filter(
          (val) => val.auction !== "서울옥션" && val.auction !== "케이옥션"
        );
        return {
          year: formatTimeChart2(item.date, type),
          type: t("OTHERS"),
          value: other.reduce((a, b) => a + b.bid, 0),
        };
      });
      setDataChart([...dtSeoulAuction, ...dtKAuction, ...dtOtherAuction]);
    } else if (typeChart === "ONOFF") {
      const dtOn = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: t("ONLINE"),
        value: item.online,
      }));
      const dtOff = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: t("OFFLINE"),
        value: item.offline,
      }));
      setDataChart([...dtOff, ...dtOn]);
    } else if (typeChart === "BID_PERIOD") {
      const from0 = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "100만원 미만",
        value: Number(item.from0),
      }));
      const from100 = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "100만원 – 1000만원 미만",
        value: Number(item.from100),
      }));
      const from1000 = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "1000만원 – 5000만원 미만",
        value: Number(item.from1000),
      }));
      const from5000 = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "5000만원 – 1억원 미만",
        value: Number(item.from5000),
      }));
      const from10000 = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "1억원 - 3억원 미만",
        value: Number(item.from10000),
      }));
      const from30000 = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "3억원 - 5억원 미만",
        value: Number(item.from30000),
      }));
      const from50000 = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "5억원 이상",
        value: Number(item.from50000),
      }));
      setDataChart([
        ...from50000,
        ...from30000,
        ...from10000,
        ...from5000,
        ...from1000,
        ...from100,
        ...from0,
      ]);
    } else if (typeChart === "GROUP_PERIOD") {
      const groupA = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "A : 단색화 전기",
        value: Number(item.a),
      }));
      const groupB = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "B : 단색화 후기 및 아방가르드",
        value: Number(item.b),
      }));
      const groupC = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "C : 원로 추상/구상",
        value: Number(item.c),
      }));
      const groupD = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "D : 컨템포레리",
        value: Number(item.d),
      }));
      const groupE = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "E : 슈퍼컨템",
        value: Number(item.e),
      }));
      const other = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "Others : 그 외",
        value: 100 - item.a - item.b - item.c - item.d - item.e,
      }));

      setDataChart([...groupA, ...groupB, ...groupC, ...groupD, ...groupE, ...other]);
    } else {
      const newDataNotSold = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "Unsold",
        value: Number(item.notsold),
      }));
      const newDataBelow = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "Below",
        value: Number(item.below),
      }));
      const newDataAbove = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "Above",
        value: Number(item.above),
      }));
      const newDataWithin = data[type].map((item) => ({
        year: formatTimeChart2(item.date, type),
        type: "Within",
        value: Number(item.within),
      }));
      setDataChart([
        ...newDataAbove,
        ...newDataWithin,
        ...newDataBelow,
        ...newDataNotSold,
      ]);
    }
  }, [type, JSON.stringify(data), t]);

  return (
    <>
      <div>
        <Column {...config} />
      </div>
      <GroupButton
        handleSetType={(v) => setType(v)}
        type={type}
        style={{ marginTop: `${mt ?? 5}px` }}
      />
    </>
  );
};

export default StackedColumnChart;
