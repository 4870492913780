import styled, { css } from "styled-components";

export const Container = styled.div`
  font-family: NotoSansKR-Medium;
  display: flex;
  margin: 0 -2.5px;
`;

export const Item = styled.div`
  position: relative;
  width: ${({ ratio }) => (ratio ? `calc(${ratio}%)` : 0)};
  flex-shrink: 0;
  justify-content: space-between;
  padding: 0 2.5px;
  align-items: center;

  ${({ ratio }) =>
    ratio <= 0 &&
    css`
      display: none;
    `};
`;

export const Ratio = styled.div`
  font-family: NotoSansKR-Medium;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  background-color: #fff;

  color: #2e2e2e;

  ${({ ratio }) =>
    ratio <= 0 &&
    css`
      display: none;
    `}

  span {
    font-size: 14px;
  }
`;

export const Label = styled.div`
  font-family: NotoSansKR-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
`;

export const Line = styled.div`
  width: 100%;
  height: 6px;
`;

export const LabelRatio = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
`;
