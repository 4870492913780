import { Image } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import NoImage from "../../../assets/images/noimg.jpeg";
import DataTable from "../../../containers/dataTable/DataTable";
import { MoneyConvert, checkSort } from "../../../utils/util";
import { useTranslation } from "react-i18next";
import * as S from "./SearchAuctionData.styles";

function SearchAuctionData({
  data,
  selectedRowKeys,
  getSelectedRowKeys,
  pageTable,
  onChangePageCurrent = () => {},
  load,
  onChangeSort,
  sorterData,
}) {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768 ? true : false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768 ? true : false);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "5%",
      fixed: isMobile ? false : "left",
      showSorterTooltip: false,
      sorter: (a, b) => null,
      defaultSortOrder:
        sorterData?.field === "id" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("EXHIBITION_PLACE"),
      dataIndex: "company",
      key: "company",
      editable: true,
      width: "6%",
      fixed: isMobile ? false : "left",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "company" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("DAY_TRADING"),
      dataIndex: "transactDate",
      key: "transactDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
      width: "6%",
      editable: true,
      fixed: isMobile ? false : "left",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "transact_date"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("IMAGE"),
      dataIndex: "img",
      key: "img",
      render: (text) => {
        return <Image src={text} className="image-table" onError={({currentTarget}) => {
          currentTarget.onerror = null;
          currentTarget.src = NoImage;
        }} />;
      },
      fixed: isMobile ? false : "left",
      width: "6%",
      align: "center",
      showSorterTooltip: false,
    },
    {
      title: "Lot",
      dataIndex: "lot",
      key: "lot",
      width: "3%",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "lot" ? checkSort(sorterData?.order) : null,
    },
    // {
    //   title: "작가명",
    //   dataIndex: "authorName",
    //   key: "authorName",
    //   render: (_, record) => {
    //     return (
    //       <div>
    //         <p>{record.artistKor}</p>
    //         <p>{record.artistEng}</p>
    //       </div>
    //     );
    //   },
    //   sorter: (a, b) => null,
    //   width: "8%",
    //   defaultSortOrder:
    //     sorterData?.field === "artist_kor"
    //       ? checkSort(sorterData?.order)
    //       : null,
    // },
    {
      title: t("NAME_OF_THE_WORK"),
      dataIndex: "title",
      key: "title",
      render: (_, record) => (
        <div>
          <p>{record.titleKor}</p>
          <p>{record.titleEng}</p>
        </div>
      ),
      width: "8%",
      editable: true,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "title_kor" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("CRAFTING_YEAR_ORTHER"),
      dataIndex: "mfgDate",
      key: "mfgDate",
      width: "5%",
      editable: true,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "mfg_date" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("MATERIAL_TYPE"),
      dataIndex: "materialKind",
      key: "materialKind",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      width: "8%",
      defaultSortOrder:
        sorterData?.field === "material_kind"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("MATERIAL_NAME"),
      dataIndex: "materialName",
      key: "materialName",
      width: "8%",
      render: (_, record) => {
        return (
          <div>
            <p>{record.materialKor}</p>
            <p>{record.materialEng}</p>
          </div>
        );
      },
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "material_kor"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("WIDE"),
      dataIndex: "height",
      key: "height",
      width: "4%",
      editable: true,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "height" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("WIDTH"),
      dataIndex: "width",
      key: "width",
      width: "4%",
      editable: true,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "width" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("HEIGHT"),
      dataIndex: "depth",
      key: "depth",
      width: "4%",
      editable: true,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "depth" ? checkSort(sorterData?.order) : null,
    },
    {
      title: t("HO_NUMBER"),
      dataIndex: "sizeTable",
      key: "sizeTable",
      width: "4%",
      editable: true,
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "size_table"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("WINNING_PRICE"),
      dataIndex: "hammerPrice",
      key: "hammerPrice",
      render: (text) => (text ? MoneyConvert(text) : ""),
      width: "7%",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "hammer_price"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("ESTIMATE_MIN"),
      dataIndex: "estimateMin",
      key: "estimateMin",
      render: (text) => (text ? MoneyConvert(text) : ""),
      width: "7%",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "estimate_min"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: t("ESTIMATE_MAX"),
      dataIndex: "estimateMax",
      key: "estimateMax",
      render: (text) => (text ? MoneyConvert(text) : ""),
      width: "7%",
      sorter: (a, b) => null,
      showSorterTooltip: false,
      defaultSortOrder:
        sorterData?.field === "estimate_max"
          ? checkSort(sorterData?.order)
          : null,
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
      width: "7%",
      editable: true,
      showSorterTooltip: false,
    },
    {
      title: "Series",
      dataIndex: "series",
      key: "series",
      width: "7%",
      editable: true,
      showSorterTooltip: false,
    },
    {
      title: "Main Color",
      dataIndex: "mainColor",
      key: "mainColor",
      width: "7%",
      editable: true,
      showSorterTooltip: false,
    },
    {
      title: "Preference",
      dataIndex: "preference",
      key: "preference",
      width: "7%",
      editable: true,
      showSorterTooltip: false,
    },
    {
      title: "Identical Record",
      dataIndex: "identicalRecords",
      key: "identicalRecords",
      render: (text) => {
        if (!text || text === "[]") return "";
        else {
          return text;
        }
      },
      width: "7%",
      editable: true,
      showSorterTooltip: false,
    },
  ];

  const dataSource = data?.map((dataItem) => {
    return {
      ...dataItem,
      key: dataItem.id,
    };
  });

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    getSelectedRowKeys?.(newSelectedRowKeys);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
    // selections: [
    //   Table.SELECTION_ALL,
    //   Table.SELECTION_NONE,
    //   {
    //     key: "20",
    //     text: "Select 20 Row",
    //     onSelect: (changableRowKeys) => {
    //       let newSelectedRowKeys = [];
    //       newSelectedRowKeys = changableRowKeys.filter((_, index) => {
    //         if (index < 20) {
    //           return true;
    //         }
    //         return false;
    //       });
    //       getSelectedRowKeys?.(newSelectedRowKeys);
    //     },
    //   },
    //   {
    //     key: "50",
    //     text: "Select 50 Row",
    //     onSelect: (changableRowKeys) => {
    //       let newSelectedRowKeys = [];
    //       newSelectedRowKeys = changableRowKeys.filter((_, index) => {
    //         if (index < 50) {
    //           return true;
    //         }
    //         return false;
    //       });
    //       getSelectedRowKeys?.(newSelectedRowKeys);
    //     },
    //   },
    //   {
    //     key: "100",
    //     text: "Select 100 Row",
    //     onSelect: (changableRowKeys) => {
    //       let newSelectedRowKeys = [];
    //       newSelectedRowKeys = changableRowKeys.filter((_, index) => {
    //         if (index < 100) {
    //           return true;
    //         }
    //         return false;
    //       });
    //       // getSelectedRowKeys?.((prev) =>
    //       //   Array.from(new Set([...prev, ...newSelectedRowKeys]))
    //       // );
    //       getSelectedRowKeys?.(newSelectedRowKeys);
    //     },
    //   },
    // ],
  };

  useEffect(() => {
    if (load) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [load]);

  return (
    <S.Container>
      <DataTable
        columns={columns}
        data={dataSource}
        pagination={pageTable}
        onChangePageCurrent={onChangePageCurrent}
        rowSelection={rowSelection}
        isLoading={isLoading}
        onChange={onChangeSort}
      />
    </S.Container>
  );
}

export default SearchAuctionData;
