import { Drawer, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { URL } from "../../common/constants/endpoints";
import LangSelect from "../../components/LangSelect";
import types from "../../store/actions/types";
import * as S from "./Header.style";

function HeaderMenu(props) {
  const location = useLocation();
  const [mainPath, setMainPath] = useState("main");
  const [activeLink, setActiveLink] = useState("");
  const [isScroll, setIsScroll] = useState(false);
  const history = useHistory();
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleScroll = (event) => {
    const position = window.pageYOffset;
    const isScroll = position > 100;
    setIsScroll(isScroll);
    if (event.cancelable) event.preventDefault();
  };

  const onLogout = () => {
    dispatch({
      type: types.REMOVE_USER_INFO,
    });
    history.push(URL.LOGIN);
  };

  const onLogin = () => {
    history.push(URL.LOGIN);
  };

  /**
   * used to change the background Header
   */
  useEffect(() => {
    let pathName = location.pathname;
    if (pathName.trim() === "/") {
      setMainPath("home");
      return;
    }
    pathName = pathName.replace("/", "");
    if (pathName !== "main") {
      setMainPath(undefined);
    } else {
      setMainPath(pathName);
    }

    setActiveLink(pathName);
  }, [mainPath, JSON.stringify(location)]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScroll]);

  const onClose = () => {
    setOpen(false);
  };

  const handleChangePage = (url) => {
    setOpen(false);
    history.push(url);
  };

  return (
    <S.HeaderBg main={mainPath} bgFixed={isScroll} className={props.className}>
      <S.Container>
        <S.HeaderMenu>
          <S.NavbarLink to={URL.MAIN}>
            <S.Logo main={mainPath} />
          </S.NavbarLink>
          <Space className="space">
            <S.Sidebar>
              <S.NavbarLink to={URL.MAIN} main={mainPath}>
                <S.NavText
                  main={mainPath}
                  className={`${mainPath === "main" ? "main" : ""}`}
                >
                  Market now
                </S.NavText>
              </S.NavbarLink>
              <S.NavbarLink to={URL.TOTAL_BID} main={mainPath}>
                <S.NavText
                  main={mainPath}
                  className={`${
                    activeLink === "totalbid" ? "active-link" : ""
                  }`}
                >
                  Top ranker
                </S.NavText>
              </S.NavbarLink>
              <S.NavbarLink to={URL.SEARCH} main={mainPath}>
                <S.NavText
                  main={mainPath}
                  className={`${activeLink === "search" ? "active-link" : ""}`}
                >
                  Data search
                </S.NavText>
              </S.NavbarLink>
              <S.NavbarLink to={URL.ARTIST} main={mainPath}>
                <S.NavText
                  main={mainPath}
                  className={`${activeLink === "artist" ? "active-link" : ""}`}
                >
                  Artist anlys
                </S.NavText>
              </S.NavbarLink>
              <S.NavbarLink to={URL.ARTMARKET} main={mainPath}>
                <S.NavText
                  main={mainPath}
                  className={`${
                    activeLink === "art-market" ? "active-link" : ""
                  }`}
                >
                  Market anlys
                </S.NavText>
              </S.NavbarLink>
              <S.NavbarLink to={URL.JOINT_PURCHASE_MARKET} main={mainPath}>
                <S.NavText
                  main={mainPath}
                  className={`${
                    activeLink === "joint-purchase-market" ? "active-link" : ""
                  }`}
                >
                  Fractional art
                </S.NavText>
              </S.NavbarLink>
            </S.Sidebar>
            <S.SidebarMobile>
              {/* <BarsOutlined
                style={{ fontSize: "20px", color: "#fff" }}
                onClick={() => setOpen(true)}
              /> */}
              <S.BarsIcon main={mainPath} onClick={() => setOpen(true)}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </S.BarsIcon>
              <Drawer
                title="Basic Drawer"
                placement={"left"}
                closable={false}
                onClose={onClose}
                open={open}
                key={"left"}
                width={200}
                headerStyle={{ display: "none" }}
              >
                <S.NavbarMobile onClick={() => handleChangePage(URL.MAIN)}>
                  <S.NavTextMobile
                    main={mainPath}
                    className={`${mainPath === "main" ? "main" : ""}`}
                  >
                    MARKET NOW
                  </S.NavTextMobile>
                </S.NavbarMobile>
                <S.NavbarMobile onClick={() => handleChangePage(URL.TOTAL_BID)}>
                  <S.NavTextMobile
                    main={mainPath}
                    className={`${
                      activeLink === "totalbid" ? "active-link" : ""
                    }`}
                  >
                    TOP RANKER
                  </S.NavTextMobile>
                </S.NavbarMobile>
                <S.NavbarMobile onClick={() => handleChangePage(URL.SEARCH)}>
                  <S.NavTextMobile
                    main={mainPath}
                    className={`${
                      activeLink === "search" ? "active-link" : ""
                    }`}
                  >
                    DATA SEARCH
                  </S.NavTextMobile>
                </S.NavbarMobile>
                <S.NavbarMobile onClick={() => handleChangePage(URL.ARTIST)}>
                  <S.NavTextMobile
                    main={mainPath}
                    className={`${
                      activeLink === "artist" ? "active-link" : ""
                    }`}
                  >
                    ARTIST ANLYS
                  </S.NavTextMobile>
                </S.NavbarMobile>
                <S.NavbarMobile onClick={() => handleChangePage(URL.ARTMARKET)}>
                  <S.NavTextMobile
                    main={mainPath}
                    className={`${
                      activeLink === "art-market" ? "active-link" : ""
                    }`}
                  >
                    MARKET ANLYS
                  </S.NavTextMobile>
                </S.NavbarMobile>
                <S.NavbarMobile
                  onClick={() => handleChangePage(URL.JOINT_PURCHASE_MARKET)}
                >
                  <S.NavTextMobile
                    main={mainPath}
                    className={`${
                      activeLink === "joint-purchase-market"
                        ? "active-link"
                        : ""
                    }`}
                  >
                    FRACTIONAL ART
                  </S.NavTextMobile>
                </S.NavbarMobile>
              </Drawer>
            </S.SidebarMobile>
          </Space>
          <S.Action>
            {/* <LangSelect /> */}
            {userData ? (
              <S.Button main={mainPath} onClick={onLogout}>
                <S.BtnText>Logout</S.BtnText>
              </S.Button>
            ) : (
              <S.Button main={mainPath} onClick={onLogin}>
                <S.BtnText>Login</S.BtnText>
              </S.Button>
            )}
          </S.Action>
        </S.HeaderMenu>
      </S.Container>
    </S.HeaderBg>
  );
}

export default HeaderMenu;
