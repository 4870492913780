import { getKey, removeKey } from "../../App";
import types from "../actions/types";

const defaultState = { isLoading: false };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_LOADING: {
      state = action.payload
      return state
    }
    default:
      return state;
  }
};
