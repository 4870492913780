import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.h3`
  max-width: 224px;
  font-family: NotoSansKR-Regular;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.4px;
  text-align: center;
  color: #3a3a3a;
  margin: 50px auto 18px;
  text-transform: uppercase;
  text-shadow: 1px 10px 10px;
`;

export const Note = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.5px;
  text-align: center;
  color: #3a3a3a;
  margin: 0 0 55px;
  max-width: 528px;
  text-shadow: 1px 10px 10px;
`;

export const ButtonStyled = styled.button`
  min-width: 250px;
  height: 50px;
  border-radius: 20px;
  background-color: #e5e8e7;
  text-align: center;
  line-height: 50px;
  padding: 0 16px;
  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #202020;
  cursor: pointer;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;

  &:hover {
    font-family: NotoSansKR-Bold;
    text-shadow: rgb(0 0 0 / 18%) 0px 1px;
    box-shadow: 0 2px 14px 0 rgb(0 0 0 / 18%);
  }
`;
