import styled from "styled-components";
import FooterBG from "../../../assets/images/footer-bg.png";

export const Footer = styled.div`
  /* background-image: url(${FooterBG});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  padding: 60px 0;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(${FooterBG});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @media screen and (max-width: 768px) {
    padding: 56px 15px 45px;

    &::after {
      top: -224px;
      height: calc(100% + 224px);
      background-position: top left;
    }
  }

  @media screen and (max-width: 425px) {
    padding: 42px 15px 60px;
    &::after {
      background-position: top left -368px;
    }
  }
`;

export const Container = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 0 auto;
`;

export const FooterInner = styled.div`
  padding: 69px 43px 69px 69px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #a49039;
    opacity: 0.03;
    top: 0;
    left: 0;
    z-index: -1;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
    padding: 50px 36px 46px;

    &::after {
      opacity: 0.06;
    }
  }

  @media screen and (max-width: 425px) {
    padding: 34px 24px 53px;
    gap: 32px;

    &::after {
      opacity: 0.06;
    }
  }
`;

export const Logo = styled.div`
  width: calc(100% / 3);

  img {
    width: 158px;
    height: 62px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Company = styled.div`
  width: calc(100% / 3);
  padding-right: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Contact = styled.div`
  width: calc(100% / 3);
  padding-left: 49px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }
`;

export const Title = styled.div`
  font-family: NotoSansKR-Bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
  margin-bottom: 13px;

  @media screen and (max-width: 425px) {
    margin-bottom: 17px;
  }
`;

export const TextContent = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
`;

export const NumberContact = styled.div`
  text-align: left;
  font-family: NotoSansKR-Bold;
  font-weight: bold;
  font-size: 19px;
  color: #a49039;
  line-height: 1.47;
`;
