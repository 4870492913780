import { Bar } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TYPE_BTN } from "../../common/constants/typeButton";
import {
  formatDatasetsNationalityByContinents,
  formatDatasetsNationalityByCountries,
} from "../../utils/chart/formatDatasets";
import { MoneyConvert } from "../../utils/util";
import GroupButton from "../common/GroupButton";

const BarPlotChart2 = ({
  data,
  styleButton,
  isShowButton = true,
  isCountries = true,
}) => {
  const { t } = useTranslation("common");
  const [type, setType] = useState(TYPE_BTN.VOLUME);
  const [dataChart, setDataChart] = useState([]);

  useEffect(() => {
    let newDataChart = [];
    if (isCountries) {
      newDataChart = formatDatasetsNationalityByCountries(data, type);
    } else {
      newDataChart = formatDatasetsNationalityByContinents(data, type, t);
    }
    setDataChart(newDataChart);
  }, [data, type]);

  const config = {
    height: 327,
    data: dataChart,
    xField: "sales",
    yField: "type",
    meta: {
      type: {
        alias: "类别",
      },
      sales: {
        alias: "Ratio",
      },
    },
    minBarWidth: 20,
    maxBarWidth: 20,
    color: "#4472c4",
    // padding: [10, 20, 80, 30],
    yAxis: {
      label: {
        autoRotate: false,
      },
    },
    xAxis: {
      label: {
        formatter: (val) => val + "%",
      },
    },
    tooltip: {
      formatter: (datum) => {
        return {
          name: datum.type,
          value: MoneyConvert(datum.sales, false, 2) + "%",
        };
      },
    },
  };

  return (
    <>
      <div>
        <Bar {...config} />
        {isShowButton && (
          <GroupButton
            isTop10={true}
            type={type}
            handleSetType={(v) => setType(v)}
            style={styleButton}
          />
        )}
      </div>
    </>
  );
};

export default BarPlotChart2;
