import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as UpIcon } from "../../assets/images/up-trend.svg";
import Loading from "../../components/common/Loading";
import {
  BidMedianTrend,
  ByMaterialChart,
  ColumnChart,
  DualAxesChart,
  ScatterChart,
  Top10AuctionWorks,
  WinningBid,
  WinningBid2,
} from "../../containers/Chart/ChartArtist";
import {
  BidRateSecondary,
  DistributionByWinbid,
} from "../../containers/Chart/ChartSecondary";
import * as S from "../../pages/Author/Author.style";
import { downloadImage } from "../../utils/downloadChartImg";
import { ConvertTenThousand, MoneyConvert } from "../../utils/util";
import BarPlotChart from "../BarPlotChart";
import BoxPlotChart from "../BoxPlotChart";
import TableTopTen from "../TableTopTen";

const AuthorCharts = ({
  summaryInfo,
  dataArtist,
  err,
  loading,
  dataWinning,
}) => {
  const { t } = useTranslation("common");
  return (
    <>
      {err ? (
        <h2 style={{ textAlign: "center", marginTop: 16 }}>{err}</h2>
      ) : (
        <>
          {/* <Loading isLoad={loading} isNotFixed> */}
            {dataArtist && (
              <>
                <S.LineDash className="first-line" />
                <S.Summary>
                  <S.ItemSummary>
                    <S.Lable>{t("AVERAGE_NUMBER_OF_WINNING_BIDS_PER_YEAR")}</S.Lable>
                    <S.Value>
                      {summaryInfo?.avgWinBid
                        ? MoneyConvert(summaryInfo?.avgWinBid, false, 2)
                        : 0}{" "}
                      <span>(LOT)</span>
                    </S.Value>
                  </S.ItemSummary>
                  <S.ItemSummary>
                    <S.Lable>{t("AVERAGE_WINNING_RATE")}</S.Lable>
                    <S.Value>
                      {summaryInfo?.bid_rate
                        ? (Number(summaryInfo.bid_rate) * 100).toFixed(1)
                        : 0}
                      <span> %</span>
                    </S.Value>
                  </S.ItemSummary>
                  <S.ItemSummary>
                    <S.Lable>{t("AVERAGE_WINNING_PRICE_ORTHER")}</S.Lable>
                    <S.Value>
                      {summaryInfo?.avg_hammer_price
                        ? ConvertTenThousand(summaryInfo.avg_hammer_price)
                        : 0}{" "}
                      <span>만원</span>
                    </S.Value>
                  </S.ItemSummary>
                  <S.ItemSummary>
                    <S.Lable>{t("LOWEST_EXPECTED_RATE")}</S.Lable>
                    <S.Value>
                      <UpIcon className="icon-up" />
                      {/* <FallOutlined className="icon-down" /> */}
                      {summaryInfo?.hammer_range
                        ? (Number(summaryInfo.hammer_range) * 100).toFixed(1)
                        : 0}
                      <span>%</span>
                    </S.Value>
                  </S.ItemSummary>
                  <S.ItemSummary>
                    <S.Lable>{t("WINNING_PRICE_RANGE")}</S.Lable>
                    <S.Value>
                      {summaryInfo?.range_min
                        ? ConvertTenThousand(summaryInfo?.range_min)
                        : 0}{" "}
                      -{" "}
                      {summaryInfo?.range_max
                        ? ConvertTenThousand(summaryInfo?.range_max)
                        : 0}{" "}
                      <span>만원</span>
                    </S.Value>
                  </S.ItemSummary>
                  <S.ItemSummary>
                    <S.Lable>{t("AVERAGE_PRICE_IN_HO")}</S.Lable>
                    <S.Value>
                      {summaryInfo?.avgHammerPricePerSizeTable
                        ? ConvertTenThousand(
                            summaryInfo?.avgHammerPricePerSizeTable
                          )
                        : 0}{" "}
                      <span>만원</span>
                    </S.Value>
                  </S.ItemSummary>
                </S.Summary>
                <S.WrapRow gutter={[32, 40]}>
                  <Col xs={24} md={24} lg={24}>
                    <S.BoxItem>
                      <div id="TrendOfTotalSuccessfulBidOrNumbeOfItems">
                        <S.BoxItemHeader>
                          <S.Title>
                            {t(
                              "TREND_OF_TOTAL_WINNINGS_NUMBER_OF_WORKS_ON_DISPLAY_ORTHER"
                            )}
                          </S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className="btn-download"
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "TrendOfTotalSuccessfulBidOrNumbeOfItems",
                                "TrendOfTotalSuccessfulBidOrNumbeOfItems"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner>
                          <DualAxesChart
                            data={dataArtist?.lotByPeriod}
                            styleButton={{ marginTop: 14 }}
                            unit={1000000}
                            unitText={t("UNIT_MILLION_WON")}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <S.BoxItem>
                      <div id="AMedianEstimateBidTrend">
                        <S.BoxItemHeader>
                          <S.Title>{t("TREND_OF_MEDIAN_VALUE_OF_EXPECTED_WINNING_PRICE")}</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "AMedianEstimateBidTrend",
                                "AMedianEstimateBidTrend"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner>
                          {/* <ColumnLineChart
                            datas={dataArtist?.medianByPeriod ?? {}}
                            styleButton={{ marginTop: 14 }}
                            isPadding={false}
                            isShowLegend
                            isShowSlider={false}
                            isShowAllYAxis={false}
                          /> */}
                          <BidMedianTrend
                            data={dataArtist?.medianByPeriod ?? []}
                            styleButton={{ marginTop: 14 }}
                            unit={1000000}
                            unitText={t("UNIT_MILLION_WON")}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <S.BoxItem>
                      <div id="AWinningBid">
                        <S.BoxItemHeader>
                          <S.Title>{t("WINNING_PRICE_TREND")}</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage("AWinningBid", "AWinningBid")
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner>
                          <WinningBid
                            data={dataArtist?.lotByPeriod ?? []}
                            dataHammer={dataArtist?.medianByPeriod ?? {}}
                            styleButton={{ marginTop: 14 }}
                            unit={1000000}
                            unitText={t("UNIT_MILLION_WON")}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <Col xs={24} md={12} lg={24}>
                    <S.BoxItem>
                      <div id="AWinningBid2">
                        <S.BoxItemHeader>
                          <S.Title>{t("WINNING_PRICE_TREND_IN_HO")}</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage("AWinningBid2", "AWinningBid2")
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner>
                          <WinningBid2
                            data={dataArtist?.lotByPeriod ?? []}
                            dataHammer={dataArtist?.medianByPeriod ?? {}}
                            styleButton={{ marginTop: 14 }}
                            unit={1000000}
                            unitText={t("UNIT_MILLION_WON")}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <S.BoxItem>
                      <div id="SuccessfulBidRate">
                        <S.BoxItemHeader>
                          <S.Title>{t("BID_WINNING_RATE")}</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "SuccessfulBidRate",
                                "SuccessfulBidRate"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner
                          doughnut
                          customHeight
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <BidRateSecondary
                            below={
                              summaryInfo?.belowPercent
                                ? summaryInfo?.belowPercent * 100
                                : 0
                            }
                            within={
                              summaryInfo?.withinPercent
                                ? summaryInfo?.withinPercent * 100
                                : 0
                            }
                            above={
                              summaryInfo?.abovePercent
                                ? summaryInfo?.abovePercent * 100
                                : 0
                            }
                            not_sold={
                              summaryInfo?.notsoldPercent
                                ? summaryInfo?.notsoldPercent * 100
                                : 0
                            }
                            sold={
                              summaryInfo?.soldPercent
                                ? summaryInfo?.soldPercent * 100
                                : 0
                            }
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <Col xs={24} md={12} lg={16}>
                    <S.BoxItem>
                      <div id="SuccessfulBidRateTrend">
                        <S.BoxItemHeader>
                          <S.Title>{t("TENDER_WINNING_RATE_TREND")}</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "SuccessfulBidRateTrend",
                                "SuccessfulBidRateTrend"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner>
                          <ColumnChart
                            data={dataArtist?.lotByPeriod}
                            styleButton={{ marginTop: 14 }}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>

                  <Col xs={24} md={12} lg={8}>
                    <S.BoxItem>
                      <div id="SpecificGravityByMaterial">
                        <S.BoxItemHeader>
                          <S.Title>{t("WEIGHT_OF_EACH_MATERIAL")}</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "SpecificGravityByMaterial",
                                "SpecificGravityByMaterial"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner style={{ paddingLeft: 0, paddingRight: 0 }}>
                          <ByMaterialChart
                            data={summaryInfo?.top5Material ?? []}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <S.BoxItem>
                      <div id="PercentageOfSuccessfulBidPriceBySection">
                        <S.BoxItemHeader>
                          <S.Title>{t("WEIGHT_OF_EACH_WINNING_PRICE_BRACKET")}</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "PercentageOfSuccessfulBidPriceBySection",
                                "PercentageOfSuccessfulBidPriceBySection"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner>
                          <DistributionByWinbid
                            lot={dataArtist?.lotByWinbid}
                            price={dataArtist?.priceByWinbid}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <Col xs={24} md={12} lg={8}>
                    <S.BoxItem>
                      <div id="PercentageByRegion">
                        <S.BoxItemHeader>
                          <S.Title>{t("WEIGHT_OF_EACH_AREA")}</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "PercentageByRegion",
                                "PercentageByRegion"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner>
                          <BarPlotChart
                            data={dataArtist?.geographicDistribution ?? []}
                            styleButton={{ marginTop: 14 }}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <Col xs={24} md={24} lg={24}>
                    <S.BoxItem>
                      <div id="SuccessfulBidPriceDistribution1">
                        <S.BoxItemHeader>
                          <S.Title>{t("ALLOCATION_OF_WINNING_BIDS")} 1</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "SuccessfulBidPriceDistribution1",
                                "SuccessfulBidPriceDistribution1"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner style={{padding: "16px 0"}}>
                          <ScatterChart
                            data={dataArtist?.distributionByPeriod}
                            styleButton={{ marginTop: 14 }}
                            unit={1000000}
                            unitText={t("UNIT_MILLION_WON")}
                          />
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>

                  <Col xs={24} md={24} lg={24}>
                    <S.BoxItem>
                      <div id="SuccessfulBidPriceDistribution2">
                        <S.BoxItemHeader>
                          <S.Title>{t("ALLOCATION_OF_WINNING_BIDS")} 2</S.Title>
                          <Button
                            type="text"
                            icon={<DownloadOutlined size={32} />}
                            className={"btn-download"}
                            size={"small"}
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #E6E6E6",
                            }}
                            onClick={() =>
                              downloadImage(
                                "SuccessfulBidPriceDistribution2",
                                "SuccessfulBidPriceDistribution2"
                              )
                            }
                          />
                        </S.BoxItemHeader>
                        <S.BoxInner>
                          <div
                            id="chart-container"
                            // style={{
                            //   width: "100%",
                            //   // height: "400px",
                            //   overflow: "auto",
                            //   paddingBottom: "10px",
                            // }}
                          >
                            {/* <div
                              style={{
                                width:
                                  dataArtist.distributionByPeriod.monthly
                                    .length *
                                    100 <
                                  1398
                                    ? "100%"
                                    : dataArtist.distributionByPeriod.monthly
                                        .length *
                                        100 +
                                      "px",
                              }}
                            >
                              
                            </div> */}
                            <BoxPlotChart
                              data={dataArtist?.distributionByPeriodSecond}
                              styleButton={{ marginTop: 14 }}
                              unit={1000000}
                            />
                          </div>
                        </S.BoxInner>
                      </div>
                    </S.BoxItem>
                  </Col>
                  <S.LineDash />
                  <Col
                    xs={24}
                    md={24}
                    lg={24}
                    style={{
                      paddingTop: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <S.Title
                      style={{ marginLeft: 16 }}
                      className="title-mobile"
                    >
                      TOP 10 WORKS
                    </S.Title>
                    <S.BoxInner className="h-auto">
                      <Top10AuctionWorks data={dataArtist?.top10} />
                    </S.BoxInner>
                  </Col>
                  <S.LineDash />
                  <Col xs={24} md={24} lg={24}>
                    <TableTopTen data={dataWinning} />
                  </Col>
                </S.WrapRow>
              </>
            )}
          {/* </Loading> */}
        </>
      )}
    </>
  );
};

export default AuthorCharts;
