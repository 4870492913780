import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NAME_FILTERS } from "../../common/constants/filtersName";
import * as S from "../filterForm/FilterForm.style";
import Chip from "./Chip/Chip";

const FieldsValueFilters = ({ handleDeleteFilter, children }) => {
  const { t } = useTranslation("common");
  const watchFields = useWatch();
  const { reset } = useFormContext();

  const { querySearch, ...resFields } = watchFields;
  const isValidFields = Object.keys(resFields).some((key) => {
    if (resFields[key]?.length <= 0 || !resFields[key]) return false;
    return true;
  });

  return (
    <>
      {isValidFields && (
        <S.SpaceStyle wrap>
          {Object.keys(NAME_FILTERS).map((key) => (
            <Chip
              key={key}
              label={key}
              content={watchFields[key]}
              handleDeleteFilter={handleDeleteFilter}
            />
          ))}
          {children}
          <S.ButtonStyle
            onClick={() => {
              reset((formValues) => {
                return Object.keys(formValues).reduce((objReset, field) => {
                  objReset[field] = "";
                  return objReset;
                }, {});
              });
              handleDeleteFilter(true);
            }}
          >
            {t("RESET")}
          </S.ButtonStyle>
        </S.SpaceStyle>
      )}
    </>
  );
};

export default FieldsValueFilters;
