import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../containers/dataTable/DataTable";
import * as S from "../../pages/Author/Author.style";
import types from "../../store/actions/types";
import {
  MoneyConvert,
  calculateRatioYear,
  formatTimeChart,
} from "../../utils/util";
import GroupButton from "../common/GroupButton";

const TableTopTen = ({ data }) => {
  const { t } = useTranslation("common");
  const { typeTime } = useSelector((state) => state.chart);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 600);
  }, [data]);

  const columns = useMemo(() => {
    return [
      {
        title: t("TIME"),
        dataIndex: "timeFilter",
        key: "timeFilter",
        render: (value) => formatTimeChart(value, typeTime),
        width: 100,
        align: "center",
      },
      {
        title: t("NUMBER_OF_WORKS_ON_DISPLAY"),
        dataIndex: "totalLot",
        key: "totalLot",
        width: 100,
        align: "center",
        render: (value) => MoneyConvert(value),
      },
      {
        title: t("NUMBER_OF_WINNING_WORKS"),
        dataIndex: "totalBid",
        key: "totalBid",
        width: 100,
        align: "center",
        render: (value) => MoneyConvert(value),
      },
      {
        title: t("BID_WINNING_RATE"),
        dataIndex: "winningRate",
        key: "winningRate",
        render: (value) => (Number(value) * 100).toFixed(1) + "%",
        width: 120,
        align: "center",
      },
      {
        title: t("TOTAL_WINNING_BID"),
        dataIndex: "groupPrice",
        key: "groupPrice",
        render: (values, record) => {
          return (
            <div>
              <div>₩ {MoneyConvert(record.totalWinningMoney)}</div>
              <div>$ {MoneyConvert(record.totalWinningUsd)}</div>
            </div>
          );
        },
        width: 200,
        align: "right",
      },

      {
        title: t("COMPARE_WITH_THE_PREVIOUS_PERIOD"),
        dataIndex: "ratioYear",
        key: "ratioYear",
        render: (value) => (
          <div style={{ color: value < 0 ? "blue" : "red" }}>
            {value > 0 ? "+" : ""}
            {Number(value).toFixed(1)}%
          </div>
        ),
        width: 150,
        align: "center",
      },
    ];
  }, [data, t]);

  const handleSetType = (val) => {
    dispatch({
      type: types.SET_TYPE_TIME,
      payload: {
        typeTime: val,
      },
    });
  };

  const dataSource =
    data &&
    data?.map((item, index) => {
      if (index === data.length - 1) {
        return {
          key: index,
          ...item,
          ratioYear: 0,
          groupPrice: item?.totalWinningMoney,
        };
      }
      return {
        key: index,
        ...item,
        // ratioYear: !data[index + 1]?.totalWinningMoney
        //   ? 0
        //   : (item?.totalWinningMoney / data[index + 1]?.totalWinningMoney - 1) *
        //     100,
        ratioYear: calculateRatioYear(
          item?.totalWinningMoney,
          data[index + 1]?.totalWinningMoney
        ),
        groupPrice: item?.totalWinningMoney,
      };
    });

  const handleChangePage = (pageNum, pageSize) => {
    setPage(pageNum.page);
  };

  return (
    <div style={{ borderRadius: 4 }}>
      <S.TopTenFilter>
        <S.Title style={{ textAlign: "left" }} className="title-mobile">
          {t("TOTAL_WINNING_AMOUNT_AND_WINNING_RATE")}
        </S.Title>
        <GroupButton
          type={typeTime}
          handleSetType={(val) => handleSetType(val)}
        />
      </S.TopTenFilter>
      <DataTable
        columns={columns}
        data={dataSource}
        pagination={{ total: dataSource.length, size: 10, page: page }}
        scroll={{ y: 600, x: 600 }}
        onChangePageCurrent={handleChangePage}
        isLoading={load}
      />
    </div>
  );
};

export default React.memo(TableTopTen);
