import IconImg from "../../../assets/images/icon.png";
import NoImg from "../../../assets/images/noimg.jpeg";
import { checkValidImage } from "../../../utils/checkValue";
import * as S from "./RisingArtistCard.styles";

function RisingArtistCard({ className, data }) {
  return (
    <S.RisingArtistCard className={className}>
      <S.Head>
        <img src={IconImg} alt="icon" />
        <span>{data?.titleHead}</span>
      </S.Head>
      <S.Image>
        <img
          src={data?.image ? checkValidImage(data?.image) : NoImg}
          alt="artist"
        />
      </S.Image>
      <S.TitleContent>{data?.titleContent}</S.TitleContent>
      {data?.contentText &&
        data?.contentText.map((value, index) => (
          <S.TextContent key={index}>
            <span>{value.label}:</span>
            <span>{value.context}</span>
          </S.TextContent>
        ))}
    </S.RisingArtistCard>
  );
}

export default RisingArtistCard;
