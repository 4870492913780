import styled, { css } from "styled-components";

export const Container = styled.div`
  font-family: NotoSansKR-Medium;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

export const Value = styled.div`
  font-size: 48px;
  font-weight: bold;
`;
export const WrapRatio = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .icon-down {
    color: #2549fb;
    font-size: 28px;
  }

  .icon-up {
    color: #ff0000;
    font-size: 28px;
  }
`;
export const Ratio = styled.div`
  font-size: 28px;
  display: flex;
  align-items: center;

  &.blue {
    color: #2549fb;
  }

  &.red {
    color: #ff0000;
  }

  span {
    font-size: 14px;
    margin-left: 6px;
  }
`;
