export const TYPE_BTN = {
  BID: "bid",
  HAMMER: "hammer",
  YEARLY: "yearly",
  HALF: "half",
  QUARTER: "quarter",
  MONTHLY: "monthly",
  VALUE: "Value",
  VOLUME: "Volume",
};
