/* eslint-disable import/no-anonymous-default-export */
import { TYPE_BTN } from "../../common/constants/typeButton";
import types from "../actions/types";

const defaultState = {
  typeTime: TYPE_BTN.YEARLY,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_TYPE_TIME: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
