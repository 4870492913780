import { Box } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatThousandths } from "../../utils/format/formatSumary";
import { MoneyConvert, formatTimeChart, median } from "../../utils/util";
import GroupButton from "../common/GroupButton";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useLayoutEffect } from "react";

const BoxPlotChart = ({ data, styleButton, unit }) => {
  const { t } = useTranslation("common");
  const [type, setType] = useState("yearly");
  const min = data && data[type][0]?.date;
  const max = data && data[type][data[type].length - 1]?.date;

  let outliers = { maxLength: 0, data: [] };
  const newData =
    data[type] &&
    data[type].map((item) => {
      const bids = item?.bid && item?.bid.sort((a, b) => a - b);
      let min = Math.min(...bids);
      let max = Math.max(...bids);
      const medianchart = median(bids);
      const indexMedian = bids.indexOf(medianchart);

      let arrQ1, arrQ3;
      if (indexMedian === -1) {
        if (bids.length === 1) {
          arrQ1 = bids;
          arrQ3 = bids;
        } else {
          arrQ1 = bids.slice(0, bids.length / 2);
          arrQ3 = bids.slice(bids.length / 2, bids.length);
        }
      } else {
        if (bids.length === 1) {
          arrQ1 = bids;
          arrQ3 = bids;
        } else {
          arrQ1 = bids.slice(0, indexMedian);
          arrQ3 = bids.slice(indexMedian + 1, bids.length);
        }
      }

      const q1 = median(arrQ1);
      const q3 = median(arrQ3);
      const q3Reverse = arrQ3.reverse();

      min = arrQ1.find((element) => element > q1 - 1.5 * (q3 - q1)) || min;
      max = q3Reverse.find((element) => element < q3 + 1.5 * (q3 - q1)) || max;

      let countOutlier = 0;
      let listOutlier = [];
      bids.map((bid) => {
        if ((bid > max || bid < min) && listOutlier[listOutlier.length - 1] !== bid) {
          outliers.maxLength = ++countOutlier > outliers.maxLength ? countOutlier : outliers.maxLength;
          listOutlier.push(bid);
        }
      });
      if (listOutlier && listOutlier.length > 0) {
        outliers.data = ([...outliers.data, { year: formatTimeChart(item.date, type), ...Object.assign({}, listOutlier) }]);
      }

      const totalBid = bids.reduce((total, current) => total + current);
      const avg = totalBid / bids.length;
      return {
        year: formatTimeChart(item.date, type),
        low: min,
        q1: q1,
        median: medianchart,
        q3: q3,
        high: max,
        average: avg
      };
    });

  useLayoutEffect(() => {
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    var root = am5.Root.new("chartdiv-scatter");

    const myTheme = am5.Theme.new(root);
    myTheme.rule("Candlestick").states.create("riseFromOpen", {
      fill: "#1890FF",
      stroke: "#545454"
    });
    myTheme.rule("Candlestick").states.create("dropFromOpen", {
      fill: "#1890FF",
      stroke: "#545454"
    });

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      am5themes_Animated.new(root),
      myTheme
    ]);

    root.numberFormatter.setAll({
      numberFormat: "#,###.0a",
      smallNumberThreshold: 0.01,
      bigNumberPrefixes: [
        { "number": unit, "suffix": "백만원" },
      ],
    });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        maxTooltipDistance: 0
      })
    );

    let xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
      fontSize: 12,
      fill: "#999999",
    });

    let yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
      fontSize: 12,
      fill: "#999999",
    });

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer
        //tooltip: am5.Tooltip.new(root, {})
      })
    );
    xAxis.data.setAll(newData);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#a",
        renderer: yRenderer,
        extraTooltipPrecision: 1,
      })
    );

    let tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      getStrokeFromSprite: false,
      autoTextColor: false,
      getLabelFillFromSprite: false,
      pointerOrientation: "horizontal",
      paddingBottom: 0,
      paddingTop: 0,
      paddingRight: 50,
      paddingLeft: 50,
      // labelText: "high: {highValueY}\nq3: {openValueY}\nmedian: {median}\nq1: {valueY}\nlow: {lowValueY}\naverage: {value}",
      labelHTML:
        "<div style=\"background: #ffffff; border-radius: 8px; padding: 8px 12px; border: 1px solid #a8a8a8\">" +
          "<div style=\"text-align: left; font-weight: 600\">{categoryX}</div>" + 
          "<div style=\"display: flex; justify-content: space-between\">" +
            "<div style=\"display: inline-block;  width: 90px; text-align: left; font-size: 12px\">" + 
            "<span style=\"font-size: 8px; color: #1890FF; margin-right: 8px\">●</span>high:</div>" + 
            "<span style=\"font-size: 12px\">{highValueY}</span>" +
          "</div>" +
          "<div style=\"display: flex; justify-content: space-between\">" +
            "<div style=\"display: inline-block;  width: 90px; text-align: left; font-size: 12px\">" + 
            "<span style=\"font-size: 8px; color: #1890FF; margin-right: 8px\">●</span>q3:</div>" + 
            "<span style=\"font-size: 12px\">{openValueY}</span>" +
          "</div>" +
          "<div style=\"display: flex; justify-content: space-between\">" +
            "<div style=\"display: inline-block;  width: 90px; text-align: left; font-size: 12px\">" + 
            "<span style=\"font-size: 8px; color: #1890FF; margin-right: 8px\">●</span>median:</div>" + 
            "<span style=\"font-size: 12px\">{median}</span>" +
          "</div>" +
          "<div style=\"display: flex; justify-content: space-between\">" +
            "<div style=\"display: inline-block;  width: 90px; text-align: left; font-size: 12px\">" + 
            "<span style=\"font-size: 8px; color: #1890FF; margin-right: 8px\">●</span>q1:</div>" + 
            "<span style=\"font-size: 12px\">{valueY}</span>" +
          "</div>" +
          "<div style=\"display: flex; justify-content: space-between\">" +
            "<div style=\"display: inline-block;  width: 90px; text-align: left; font-size: 12px\">" + 
            "<span style=\"font-size: 8px; color: #1890FF; margin-right: 8px\">●</span>low:</div>" + 
            "<span style=\"font-size: 12px\">{lowValueY}</span>" +
          "</div>" +
          "<div style=\"display: flex; justify-content: space-between\">" +
            "<div style=\"display: inline-block;  width: 90px; text-align: left; font-size: 12px\">" + 
            "<span style=\"font-size: 8px; color: #ff81ff; margin-right: 8px\">●</span>average:</div>" + 
            "<span style=\"font-size: 12px\">{value}</span>" + 
          "</div>" +
        "</div>",
      minWidth: 150,
    })

    tooltip.get("background").setAll({
      stroke: "#ffffff00"
    });

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
      am5xy.CandlestickSeries.new(root, {
        name: "MDXI",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "q1",
        openValueYField: "q3",
        lowValueYField: "low",
        highValueYField: "high",
        valueField: "average",
        categoryXField: "year",
        tooltip: tooltip
      })
    );

    // mediana series
    var medianSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        stroke: "#545454",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "median",
        categoryXField: "year",
        noRisers: true,
        stepWidth: am5.percent(50)
      })
    );

    // high series
    var highSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        stroke: "#545454",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "high",
        categoryXField: "year",
        noRisers: true,
        stepWidth: am5.percent(50)
      })
    );

    // low series
    var lowSeries = chart.series.push(
      am5xy.StepLineSeries.new(root, {
        stroke: "#545454",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "low",
        categoryXField: "year",
        noRisers: true,
        stepWidth: am5.percent(50)
      })
    );

    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));

    function createSeries(name, field, data) {
      let tooltipOutlier = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        getLabelFillFromSprite: false,
        pointerOrientation: "horizontal",
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 50,
        paddingLeft: 50,
        labelHTML: 
          "<div style=\"background: #ffffff; border-radius: 8px; padding: 8px 12px; border: 1px solid #a8a8a8\">" +
            "<div style=\"text-align: left; font-weight: 600\">{categoryX}</div>" + 
            "<div style=\"display: inline-block; width: 80px; text-align: left; font-size: 12px\">" + 
            "<span style=\"font-size: 8px; color: #54545490; margin-right: 8px\">●</span>outlier:</div>" + 
            "<span style=\"font-size: 12px\">{valueY}</span>" +
          "</div>",
      });

      tooltipOutlier.get("background").setAll({
        stroke: "#ffffff00"
      });

      let configLineSeries = {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        categoryXField: "year",
        fill: field === "average" ? "#ff81ff" : "#54545490",
        stroke: "#0000",
        tooltip: field !== "average" ? tooltipOutlier : null
      }

      var series = chart.series.push(
        am5xy.LineSeries.new(root, configLineSeries)
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 4,
            fill: series.get("fill"),
            stroke: field === "average" ? "#fff" : null
          })
        });
      });

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      series.set("setStateOnChildren", true);
      series.states.create("hover", {});
      series.mainContainer.set("setStateOnChildren", true);
      series.mainContainer.states.create("hover", {});
      series.strokes.template.states.create("hover", {
        strokeWidth: 4
      });
      series.data.setAll(data);
      series.appear(1000);
    }
    createSeries("average", "average", newData);
    for (let i = 0; i < outliers.maxLength; i++) {
      createSeries(`${i}`, `${i}`, outliers.data);
    }

    series.data.setAll(newData);
    medianSeries.data.setAll(newData);
    highSeries.data.setAll(newData);
    lowSeries.data.setAll(newData);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000, 100);
    medianSeries.appear(1000, 100);
    highSeries.appear(1000, 100);
    lowSeries.appear(1000, 100);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    }
  }, [type, newData]);

  const handleSetType = (val) => {
    setType(val);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          overflow: "auto",
          paddingBottom: "10px",
        }}
      >
        <div id="chartdiv-scatter" style={{ width: "100%", height: 327 }}></div>
      </div>
      <GroupButton
        handleSetType={handleSetType}
        type={type}
        style={{ marginTop: "16px" }}
      />
    </>
  );
}

export default BoxPlotChart;
