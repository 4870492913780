import styled from "styled-components";

export const ChartCard = styled.div`
  width: 446px;
  height: 317px;
  padding: 37px 53px;
  border-radius: 20px;
  box-shadow: 0px 0px 7.5px rgba(0, 0, 0, 0.129412);
  background-color: #fff;
  position: relative;
  flex-shrink: 0;

  &::after {
    content: "";
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: solid 1px #e1d4ff;
    z-index: -1;
    transform: rotate(-4deg);
  }

  @media screen and (max-width: 1397px) {
    width: calc(100%/3 - 20px);
  }

  @media screen and (max-width: 1020px) {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }

  @media screen and (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media screen and (max-width: 540px) {
    width: 100%;
  }

`;

export const Chart = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  
  img {
    width: 261px;
    height: 124px;
  }
`;

export const Title = styled.div`
  font-family: NotoSansKR-Bold;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  color: #202020;
  margin-bottom: 6px;
`;

export const ContentText = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #6e6e6e;

  span {
    &:first-child {
      margin-right: 5px;
    }
  }
`;

export const Ratio = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.49;
  letter-spacing: normal;
  text-align: center;
  color: #5934cb;
  position: absolute;
  top: 30px;
  right: 29%;

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;