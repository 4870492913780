import { FallOutlined, RiseOutlined } from "@ant-design/icons";
import * as S from "./YoyChange.styles";
import { MoneyConvert } from "../../utils/util";

const YoyChange = ({ data, typeYoy }) => {
  return (
    <S.Container>
      <S.Title>{data?.title}</S.Title>
      <S.Value>{data?.value}</S.Value>
      <S.WrapRatio>
        {data?.ratio < 0 ? (
          <FallOutlined className="icon-down" />
        ) : (
          <RiseOutlined className="icon-up" />
        )}
        <S.Ratio className={data?.ratio > 0 ? "red" : "blue"}>
          {data?.ratio}
          {typeYoy === "BID" ? "%" : "%p"}
          {data?.yoysum && <span>({MoneyConvert(data?.yoysum)})</span>}
        </S.Ratio>
      </S.WrapRatio>
    </S.Container>
  );
};

export default YoyChange;
