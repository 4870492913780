import React from 'react';
import * as S from "./style";

function ButtonCus({onClick, children, htmlType, style}) {
  return (
    <S.ButtonStyle htmlType={htmlType} onClick={onClick} style={style}>
      {children}
    </S.ButtonStyle>
  )
}

export default ButtonCus