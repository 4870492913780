import { Button } from "antd";
import fileDownload from "js-file-download";
import debounce from "lodash.debounce";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { downloadExcelAuction, getMainColorSelectData, searchApi } from "../../apis/auth";
import { HTTP_STATUS_CODE } from "../../common/constants/httpsConstant";
import SearchAuctionData from "../../components/SearchAuction/SearchAuctionData";
import FilterForm2 from "../../components/filterForm2/FilterForm";
import Title from "../../containers/title/Title";
import { checkValidForm } from "../../utils/checkValue";
import { formatDataFilter } from "../../utils/format/formatDataFilter";
import { addParamUrl, getParamUrlValue } from "../../utils/url";
import { MoneyConvert } from "../../utils/util";
import { useTranslation } from "react-i18next";
import * as S from "./Search.style";
import { useDispatch } from "react-redux";
import types from "../../store/actions/types";

const getDefaultValue = (value) =>
  typeof value === "string" ? [value] : value;

const Search2 = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [dataSearchAuction, setDataSearchAuction] = useState(null);
  const [dataSummary, setDataSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [filterData, setFilterData] = useState({
    field: getParamUrlValue(location, "field"),
    order: getParamUrlValue(location, "order"),
    page: Number(getParamUrlValue(location, "page") ?? 1),
    size: Number(getParamUrlValue(location, "size") ?? 20),
    querySearch: getParamUrlValue(location, "querySearch"),
    bidClass: getDefaultValue(getParamUrlValue(location, "bidClass")),
    pricetp: getParamUrlValue(location, "pricetp"),
    price: getParamUrlValue(location, "price"),
    material: getDefaultValue(getParamUrlValue(location, "material")),
    materialSearch: getParamUrlValue(location, "materialSearch"),
    transactDate: getDefaultValue(getParamUrlValue(location, "transactDate")),
    mfgDate: getDefaultValue(getParamUrlValue(location, "mfgDate")),
    height: getDefaultValue(getParamUrlValue(location, "height")),
    width: getDefaultValue(getParamUrlValue(location, "width")),
    title: getParamUrlValue(location, "title"),
    company: getDefaultValue(getParamUrlValue(location, "company")),
    location: getDefaultValue(getParamUrlValue(location, "location")),
    locationSearch: getParamUrlValue(location, "locationSearch"),
    onOff: getParamUrlValue(location, "onOff"),
    auctionName: getParamUrlValue(location, "auctionName"),
    sizeTable: getDefaultValue(getParamUrlValue(location, "sizeTable")),
    mainColor: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "mainColor")) : undefined,
    method: getDefaultValue(getParamUrlValue(location, "method")),
    series: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "series")) : undefined,
    preference: getParamUrlValue(location, "querySearch") ? getDefaultValue(getParamUrlValue(location, "preference")) : undefined
  });
  const [totalElement, setTotalElement] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  /**
   *  used to get data search auction
   * @param {requestParams} - value form and page current
   * @returns {promise}
   */
  const getDataSearch = async (valuesForm = {}) => {
    setMessage("");
    setLoading(true);
    addParamUrl(history, valuesForm);

    const { page, size, field, order, ...resValuesForm } = valuesForm;
    const isValidFields = checkValidForm(resValuesForm);
    if (!isValidFields) {
      setDataSummary(null);
      setDataSearchAuction(null);
      setLoading(false);
      setTotalElement(0);
      setError("");
      return;
    }

    dispatch({type: types.SET_LOADING, payload: {isLoading: true}});
    const dataFilter = formatDataFilter(valuesForm);
    const dataSearchResponse = await searchApi(dataFilter);
    if (!dataSearchResponse.result) {
      if (Object.values(HTTP_STATUS_CODE).includes(dataSearchResponse.err)) {
        setError(dataSearchResponse.err_desc);
        setDataSummary(null);
        return;
      }
      window.location.replace("/login");
    } else {
      setDataSearchAuction(dataSearchResponse.resp);
      setTotalElement(dataSearchResponse?.page?.totalElement);
      setDataSummary(dataSearchResponse.summary);
      setError("");
    }
    setTimeout(() => {
      setLoading(false);
    }, 10);
    dispatch({type: types.SET_LOADING, payload: {isLoading: false}});
  };

  /**
   * event when change pagination
   * @param {*} values
   * @returns{void}
   */
  const handleChangePageCurrent = (values) => {
    setFilterData({ ...filterData, page: values.page });
  };

  const handleValueChangeFilter = async (values) => {
    setFilterData({ ...filterData, ...values, page: 1 });
    setSelectedRowKeys([]);
  };

  const handleValueChangeSearchDebounce = debounce(async (values) => {
    setFilterData({ ...filterData, ...values, page: 1 });
    setSelectedRowKeys([]);
  }, 800)

  /**
   * get data search auction when change page
   */
  useEffect(() => {
    getDataSearch({
      ...filterData,
    });
  }, [JSON.stringify(filterData)]);

  /**
   * get data search when change sort
   * @param {object} sorter - values order and column sort
   * @returns {void}
   */
  const onChangeSort = (pagination, filters, sorter, extra) => {
    let newField = sorter.columnKey;
    if (extra.action === "sort") {
      switch (sorter.field) {
        case "authorName":
          newField = "artist_kor";
          break;
        case "materialKind":
          newField = "material_kind";
          break;
        case "materialName":
          newField = "material_kor";
          break;
        case "transactDate":
          newField = "transact_date";
          break;
        case "title":
          newField = "title_kor";
          break;
        case "mfgDate":
          newField = "mfg_date";
          break;
        case "sizeTable":
          newField = "size_table";
          break;
        case "hammerPrice":
          newField = "hammer_price";
          break;
        case "estimateMin":
          newField = "estimate_min";
          break;
        case "estimateMax":
          newField = "estimate_max";
          break;
        default:
          break;
      }
      const filterDataSortChange = {
        order:
          sorter.order === "ascend"
            ? "asc"
            : sorter.order === "descend"
            ? "desc"
            : null,
        field: !!sorter.order ? newField : null,
      };
      setFilterData({ ...filterData, ...filterDataSortChange });
    }
  };

  const handleDownloadExcel = async () => {
    dispatch({type: types.SET_LOADING, payload: {isLoading: true}});
    const filename =
      "DataSearch".toLowerCase() +
      "_" +
      moment(new Date()).format("YYYYMMDD_HHmmss") +
      ".xlsx";
    const resp = await downloadExcelAuction(
      { records: selectedRowKeys, ...filterData },
      "blob"
    );
    const blobData = new Blob([resp], {
      type: "application/json",
    });
    fileDownload(blobData, filename);
    setSelectedRowKeys([]);
    dispatch({type: types.SET_LOADING, payload: {isLoading: false}});
  };

  const handleChangePage = (value) => {
    setFilterData({ ...filterData, size: value, page: 1 });
  };

  return (
    <S.ContainerAll>
      <Title>Data Search</Title>
      <FilterForm2
        filter={filterData}
        search
        searchName
        onValueChangeFilter={handleValueChangeFilter}
        onValueChangeSearch={handleValueChangeSearchDebounce}
      />
      <S.Container>
        <div>
          <>
            {error ? (
              <h2 style={{ textAlign: "center" }}>{error}</h2>
            ) : (
              <>
                {message && (
                  <div style={{ marginBottom: 16, color: "red" }}>
                    {message}
                  </div>
                )}
                {dataSearchAuction && (
                  <>
                    <S.Summary>
                      <S.ItemSummary>
                        <S.Lable>{t("TOTAL_WINNING_BID")}</S.Lable>
                        <S.Value>
                          <p>₩ {MoneyConvert(dataSummary?.total_bid)}</p>
                          <p>$ {MoneyConvert(dataSummary?.usd)}</p>
                        </S.Value>
                      </S.ItemSummary>
                      <S.ItemSummary>
                        <S.Lable>{t("NUMBER_OF_WORKS_ON_DISPLAY")}</S.Lable>
                        <S.Value>
                          {dataSummary?.total_lot
                            ? MoneyConvert(dataSummary?.total_lot)
                            : 0}{" "}
                          <span>(LOT)</span>
                        </S.Value>
                      </S.ItemSummary>
                      <S.ItemSummary>
                        <S.Lable>{t("NUMBER_OF_WINNING_WORKS")}</S.Lable>
                        <S.Value>
                          {dataSummary?.total_lot
                            ? MoneyConvert(dataSummary?.wining_lot)
                            : 0}{" "}
                          <span>(LOT)</span>
                        </S.Value>
                      </S.ItemSummary>
                    </S.Summary>
                    <S.WrapButton>
                      <S.SelectOptions
                        defaultValue={filterData.size}
                        onChange={handleChangePage}
                        options={[
                          {
                            value: 20,
                            label: "20 / page",
                          },
                          {
                            value: 50,
                            label: "50 / page",
                          },
                          {
                            value: 100,
                            label: "100 / page",
                          },
                          {
                            value: 200,
                            label: "200 / page",
                          },
                          {
                            value: 300,
                            label: "300 / page",
                          },
                          {
                            value: 400,
                            label: "400 / page",
                          },
                          {
                            value: 500,
                            label: "500 / page",
                          },
                        ]}
                      />
                      <Button
                        onClick={handleDownloadExcel}
                        disabled={selectedRowKeys.length <= 0}
                      >
                        {t("DOWNLOAD_EXCEL")}
                      </Button>
                    </S.WrapButton>
                    <SearchAuctionData
                      data={dataSearchAuction}
                      pageTable={{
                        page: filterData.page,
                        size: filterData.size,
                        totalElement: totalElement,
                      }}
                      onChangePageCurrent={handleChangePageCurrent}
                      selectedRowKeys={selectedRowKeys}
                      getSelectedRowKeys={setSelectedRowKeys}
                      load={loading}
                      onChangeSort={onChangeSort}
                      sorterData={{
                        field: filterData.field,
                        order: filterData.order,
                      }}
                    />
                  </>
                )}
              </>
            )}
          </>
        </div>
      </S.Container>
    </S.ContainerAll>
  );
};

export default Search2;
