import { Popover } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { LiStyle } from "./FilterForm.style";
import { useSelector } from "react-redux";

const PopoverEl = ({ content, text, name }) => {
  const watchFields = useWatch();
  const { setValue, getValues } = useFormContext();
  const [active, setActive] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const { isLoading } = useSelector((state) => state.isLoading);

  useEffect(() => {
    if(isLoading) setOpenPopover(false)
  }, [isLoading])

  useEffect(() => {
    if (Array.isArray(name)) {
      const valueField1 = watchFields[name[0]];
      const valueField2 = watchFields[name[1]];
      if (valueField1?.length > 0 || valueField2?.length > 0) {
        return setActive(true);
      } else {
        return setActive(false);
      }
    }
    if (watchFields[name]?.length > 0) {
      setActive(true);
    } else {
      setActive(false);
      setValue(name, undefined);
    }
    // const active = watchFields[name]?.length > 0;
    // setActive(active);
  }, [JSON.stringify(watchFields)]);

  return (
    <Popover content={content} trigger="click" placement="bottomLeft" open={openPopover} onOpenChange={(value) => setOpenPopover(value)}>
      <LiStyle className={`${active ? "active-li" : ""}`}>{text}</LiStyle>
    </Popover>
  );
};

export default PopoverEl;
