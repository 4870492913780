import { Row, Space, Typography } from "antd";
import styled, { css } from "styled-components";

export const ContainerAll = styled.div`
  #AMedianEstimateBidTrend {
    canvas {
      max-height: 327px !important;
    }
  }
  @media (max-width: 768px) {
    padding: 0 15px;
  }
  .pd-5 {
    padding: 0 5px;
  }

  @media (max-width: 425px) {
    .pd-5 {
      padding: 0;
    }
  }
`;

export const Inner = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 18px auto 36px;
  gap: 16px;
  display: flex;
  justify-content: space-between;

  background: #ffffff;
  border: 1px solid #a49039;
  border-radius: 22px;
  padding: 25px;

  .ant-row {
    margin-top: 0;
    min-height: 50px;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px 20px;
    margin: 30px 0;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 25px 15px;
  }
`;

export const Container = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  min-height: 942px;
  margin: 0 auto 0;
  padding: 55px 60px 77px 50px;
  border-radius: 0 0 45px 45px;
  box-shadow: 0px 5px 7.5px rgba(0, 0, 0, 0.129412);
  background-color: #fff;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 30px 20px;
    padding-bottom: 70px;
    border-radius: 0 0 20px 20px;
  }

  @media (max-width: 425px) {
    margin-top: 30px;
    padding-bottom: 57px;
  }
`;
export const WrapRow = styled(Row)`
  // margin-top: 30px;

  @media (max-width: 768px) {
    row-gap: 40px !important;
  }
  @media (max-width: 425px) {
    row-gap: 20px !important;
  }
`;
export const BoxItem = styled.div`
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  padding: 16px 16px 16px;
  border: solid 1px #a49039;
  border-radius: 20px;
  height: 100%;

  .boxItem-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
  }

  .btn-download {
    background-color: transparent;
    border: 1px solid #a8a8a8;
    width: 40px;
    height: 35px;
    border-radius: 8px;
    margin: 0;
  }

  @media screen and (max-width: 992px) {
    padding: 30px 16px 20px;
  }
  @media screen and (max-width: 425px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const BoxInner = styled.div`
  background-color: white;
  /* border: solid 1px #e5e8e7; */
  border-radius: 45px;
  padding: 16px;
  /* height: 408px; */
  width: 100%;
  text-align: center;
  align-items: center;
  white-space: normal;

  canvas {
    max-height: 350px;
    object-fit: contain;
  }

  &.h-auto {
    height: auto;
  }

  ${({ doughnut }) =>
    doughnut
      ? css`
          canvas {
            height: 380px !important;
            object-fit: contain;
          }
        `
      : ""}

  ${({ customHeight }) =>
    customHeight
      ? css`
          & > div:first-child {
            height: unset !important;
          }
        `
      : ""}

  @media (max-width: 425px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`;
export const Title = styled.div`
  width: 100%;
  font-family: NotoSansKR-Bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #a49039;

  @media (max-width: 425px) {
    font-size: 20px;
    line-height: 29px;

    &.title-mobile {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;
export const TopTenFilter = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
`;
export const WrapTotal = styled(Space)`
  font-family: NotoSansKR-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #989898;
`;

export const WrapInput = styled.div`
  display: flex;
  justify-content: center;

  .ant-select {
    height: 50px;
  }

  .ant-input {
    border-radius: 10px;
    height: 50px;
    padding-left: 20px;
    font-family: NotoSansKR-Medium;
    font-size: 15px;
    width: 260px;
    border-radius: 10px 0 0 10px !important;
    border: 1px solid #d6d0b3;
  }
  .ant-input::placeholder {
    text-overflow: unset;
  }
  .ant-btn {
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    /* background-image: linear-gradient(to bottom, #b291ff, #A49039); */
    background-color: #a49039;
    border: none;
    outline: none;
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
    border-radius: 0 10px 10px 0;

    &:hover {
      background-color: initial;
      background-image: linear-gradient(to bottom, #f7e387, #a49039);
      color: #fff;
    }

    &:focus {
      background-color: initial;
      background-image: linear-gradient(to bottom, #f7e387, #a49039);
      color: #fff;
    }

    &:disabled {
      background-image: none;
      background-color: #afafaf;
      color: #6a6a6a;
      cursor: not-allowed;
    }
  }

  @media (max-width: 768px) {
    .ant-select {
      flex: 1;
    }
  }
`;

export const Summary = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    gap: 16px;
  }
  @media screen and (max-width: 425px) {
    gap: 16px 8px;
    margin-bottom: 30px;
  }
`;

export const ItemSummary = styled.div`
  min-width: 180px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f9f5e3;
  border-radius: 24px;
  padding: 0 17px;
  flex: 2;

  &:nth-child(5) {
    flex: 3;
  }

  @media (max-width: 768px) {
    min-width: 140px;
    width: calc((100% - 16px * 2) / 3);
    padding: 0 5px;
    flex: initial;

    &:nth-child(5) {
      flex: initial;
      width: calc(100% - (100% - 16px * 2) / 3 - 16px);
    }
  }

  @media (max-width: 425px) {
    min-width: 130px;
    width: calc(50% - 4px);
    padding: 0 5px;

    &:nth-child(5) {
      width: 100%;
    }
  }
`;

export const Lable = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 23px;
  letter-spacing: normal;
  color: #2e2e2e;
  text-align: center;
`;

export const Value = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 30px;
  letter-spacing: normal;
  color: #2e2e2e;
  text-align: center;

  span {
    font-size: 16px;
    line-height: 24px;
    font-family: NotoSansKR-Regular;
    font-weight: normal;
    white-space: nowrap;
  }

  .icon-down {
    color: #2549fb;
    font-size: 28px;
    margin-right: 5px;
  }

  .icon-up {
    color: #ff0000;
    font-size: 28px;
    margin-right: 5px;
  }
`;

export const ResultAuthor = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 28px;

  .author-name {
    font-family: NotoSansKR-Medium;
    font-size: 32px;
    line-height: 46px;
  }

  .author-info {
    font-family: NotoSansKR-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #a49039;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    align-items: start;
    gap: 0;
    margin-bottom: 16px;

    .author-name {
      font-size: 24px;
      line-height: 36px;
    }
    .author-info {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const LineDash = styled.div`
  width: 100%;
  border-bottom: 1px dashed #a49039;
  margin: 0 16px;
  margin-top: 40px;

  &.first-line {
    margin: 29px 0 30px;
  }

  @media (max-width: 768px) {
    margin: 20px 16px 5px;

    &.first-line {
      margin: 32px 0 22px;
    }
  }

  @media (max-width: 425px) {
    margin: 30px 16px 10px;

    &.first-line {
      margin: 16px 0 30px;
    }
  }
`;

export const BoxItemHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
  padding-top: 22px;

  @media (max-width: 768px) {
    padding-top: 5px;
  }
  @media (max-width: 425px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
