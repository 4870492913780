import styled from "styled-components";

export const Container = styled.div`
  width: auto;
  position: relative;
`;

export const SlectLang = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon_select {
    margin-left: 10px;
    color: gray;
  }
`;

export const LangList = styled.div`
  width: 10rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: -10px;
  margin-top: 10px;
  position: absolute;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(45deg, #a49039, white);
  box-shadow: 1px 1px 1px gray;
  border-radius: 10px;
  opacity: ${(props) => (props.isShow ? 1 : 0)};
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
`;

export const LangItem = styled.div`
  width: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  img {
    width: 28px;
    height: auto;
  }

  span {
    font-family: "NotoSansKR-Regular";
  }
`;
