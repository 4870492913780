import { DatePicker, Select } from "antd";
import styled from "styled-components";

export const ContainerAll = styled.div`
  @media (max-width: 768px) {
    padding: 0 16px;

    .ant-space,
    .ant-space-item {
      //width: 100%;
    }
  }
`;

export const Container = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  min-height: 942px;
  margin: 0 auto;
  background-color: #fff;
  padding: 50px 50px 80px;
  box-shadow: 0px 5px 7.5px rgba(0, 0, 0, 0.129412);
  border-radius: 0 0 45px 45px;
  position: relative;
  z-index: 1;

  .ant-empty.ant-empty-normal {
    text-align: left;
    padding-left: 21%;
  }

  @media (max-width: 768px) {
    border-radius: 0 0 20px 20px;
    padding: 24px 20px 70px;
    box-shadow: 0px 7.5px 7.5px rgba(0, 0, 0, 0.129412);
  }

  @media (max-width: 425px) {
    padding: 24px 15px 50px;
  }
`;

export const DateTime = styled(DatePicker)`
  &.ant-picker {
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    width: 200px;
    height: 50px;

    @media screen and (max-width: 1024px) {
      width: 160px;
      height: 40px;
    }
    @media screen and (max-width: 768px) {
      width: 120px;
      height: 40px;
    }
  }
  &.ant-picker input::placeholder {
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #9a9a9a;
  }
`;

export const WrapFilter = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 18px auto 36px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #a49039;
  border-radius: 22px;
  padding: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px 20px;
    margin: 30px 0;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 25px 15px;
  }
`;

export const WrapSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  @media (max-width: 425px) {
    gap: 20px;
  }
`;

export const Button = styled.button`
  min-width: 131px;
  height: 50px;
  line-height: 50px;
  padding: 0 16px;
  border-radius: 11px;
  background-color: #a49039;
  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Summary = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  /* gap: 20px; */

  @media screen and (max-width: 768px) {
    justify-content: center;
    gap: 10px 20px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 425px) {
    gap: 8px;
  }
`;

export const ItemSummary = styled.div`
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
  background: #f9f5e3;
  border-radius: 24px;
  padding: 0 17px;
  width: calc(100% / 3 - 13px);

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const Lable = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 23px;
  letter-spacing: normal;
  color: #2e2e2e;
  text-align: center;
`;

export const Value = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #000;
  text-align: right;

  span {
    font-size: 16px;
    font-family: NotoSansKR-Regular;
    font-weight: normal;
    line-height: 23px;
  }

  .icon-down {
    color: #2549fb;
    font-size: 28px;
    margin-right: 5px;
  }

  .icon-up {
    color: #ff0000;
    font-size: 28px;
    margin-right: 5px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 45px;
  }

  @media screen and (max-width: 425px) {
    font-size: 18px;
    line-height: 33px;
  }
`;

export const WrapButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  .ant-btn {
    font-family: "NotoSansKR-Regular";
    border-radius: 10px;
    height: 50px;
    background-color: #a49039;
    font-size: 16px;
    color: #fff;
    border: none;

    &:disabled {
      background-color: #a8a69d;
      color: #6a6a6a;
    }
  }
`;

export const SelectOptions = styled(Select)`
  height: 50px;
  width: 200px;
  border-radius: 10px;
  border: solid 1px #e6e6e6;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 18px;
    font-family: NotoSansKR-Regular;
    border: none;
    border-radius: 10px;
  }
  .ant-select-selection-item {
    font-family: NotoSansKR-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #202020;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;
