import styled from "styled-components";
import { Button, Input, Row, Space } from "antd";
const { Search } = Input;

export const BtnFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 150px;
  height: 50px;
  padding: 8px 32px;
  border-radius: 15px;
  /* box-shadow: 0px 0px 7.5px rgba(0, 0, 0, 0.129412); */
  background-color: #fff;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  position: relative;
  z-index: 1;
  font-weight: 400;
  line-height: 22px;

  /* @media screen and (max-width: 1024px) {
    max-width: 120px;
    height: 40px;
  }
  @media screen and (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    padding: 0 8px;
  } */

  :hover,
  :focus {
    span {
      opacity: 0.6;
    }
  }
  span {
    font-family: NotoSansKR-Regular;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
  }

  &.active-btn {
    box-shadow: 0px 0px 7.5px rgba(0, 0, 0, 0.160784);
    span {
      color: #a49039;
    }
  }

  @media (max-width: 768px) {
    padding: 8px 20px;
    min-width: 120px;
    width: 120px;
  }

  @media (max-width: 425px) {
    width: 100%;
    border-radius: 10px;
  }
`;
export const SearchStyle = styled(Search)`
  .ant-input-group-addon {
    background-color: inherit;
  }

  .ant-btn {
    box-shadow: none;
  }

  &.ant-input-search .ant-input-group .ant-input-affix-wrapper {
    border-color: #d6d0b3;
  }

  &.ant-input-search
    .ant-input-group
    .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 50px;
    width: 260px;
    padding-left: 20px;

    /* @media screen and (max-width: 1024px) {
      max-width: 140px;
      height: 40px;
    }
    @media screen and (max-width: 768px) {
      min-width: 120px;
      height: 40px;
    } */
  }
  .ant-input:placeholder-shown {
    text-overflow: unset;
  }

  &.ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    min-width: 60px;
    height: 50px;
    border: none;
    border-radius: 0 10px 10px 0;
    color: #fff;
    background-color: #a49039;
    font-family: NotoSansKR-Medium;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
  }
  &.ant-input-group-wrapper {
    width: auto;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: solid 1px #d6d0b3;
  }
  :hover,
  :focus {
    border: none;
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  @media (max-width: 768px) {
    &.ant-input-search
      .ant-input-group
      .ant-input-affix-wrapper:not(:last-child) {
      max-width: 100%;
      width: 100%;
    }
  }

  @media (max-width: 425px) {
    margin-left: 0;
    &.ant-input-search {
      max-width: 100%;
      width: 100%;
    }
    &.ant-input-search
      .ant-input-group
      .ant-input-affix-wrapper:not(:last-child) {
      max-width: initial;
    }
  }
`;
export const ButtonStyle = styled(Button)`
  min-width: 80px;
  height: 40px;
  margin: 0 10px;
  border: none;
  border-radius: 10px;
  background-color: #f5f5f5;

  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #202020;

  :hover,
  :focus {
    color: #202020;
    background-color: #f5f5f5;
    border: none;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }

  @media (max-width: 425px) {
    margin: 0;
    width: 100%;
  }
`;
export const ContainerFilters = styled(Row)`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  text-align: right;

  @media (max-width: 768px) {
    padding: 16px;
    height: auto;
    gap: 16px 40px;
    margin-top: 16px;

    &.ant-row {
      height: auto;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  @media (max-width: 425px) {
    &.ant-row {
      flex-direction: row;
      padding: 16px;
      gap: 15px 20px;
    }
  }
`;

export const SpaceStyle = styled(Space)`
  width: 100%;
  margin-top: 4px;
  .ant-checkbox-group-item {
    font-family: NotoSansKR-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #3f444d;
  }

  .ant-input {
    border: none;
    padding: 8px 16px 8px 0;
    border-bottom: solid 1px #e8e8e8;
    outline: none;
    font-family: NotoSansKR-Regular;
    font-size: 14px;

    &::placeholder {
      font-family: NotoSansKR-Regular;
      color: #949494;
    }

    &:focus {
      box-shadow: none;
    }
  }
`;
export const LiStyle = styled.span`
  font-family: NotoSansKR-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #3f444d;
  margin: 0 20px;
  cursor: pointer;

  :hover,
  :focus {
    opacity: 0.8;
  }

  &.active-li {
    font-family: NotoSansKR-Bold;
    font-weight: bold;
    color: #a49039;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-color: #a49039;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const Summary = styled.div`
  margin-left: 20px;
  flex-grow: 1;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }

  @media (max-width: 425px) {
    margin-top: 0;
  }
`;

export const ContainerInput = styled.div`
  position: relative;

  .icon-search {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 16px;
    transform: translateY(-50%);
    color: #3f444d;
    cursor: pointer;
  }
`;

export const WrapInputSearch = styled.div`
  flex-shrink: 0;

  .ant-input-group-wrapper {
    width: 100%;
  }
`;

export const WrapFilterItem = styled.div`
  /* flex-grow: 1; */
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const WrapForm = styled.form`
  width: 100%;
`;

export const WrapFilter = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 18px auto 36px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #a49039;
  border-radius: 22px;
  padding: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px 20px;
    margin: 30px 0;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 25px 15px;
  }
`;

export const WrapTags = styled.div`
  /* max-width: 1398px; */
  max-width: 1600px;
  margin: 18px auto 0;
  display: flex;
  justify-content: space-between;
  border-radius: 45px 45px 0 0;
  background: #ffffff;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 0px 7.5px;
  padding: 50px 50px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 25px 20px;
    margin-top: 30px;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 25px 15px;
  }
`;