import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 38px;
    height: 48px;
  }
  span {
    margin: 0 0 0 10px;
    font-family: NotoSansKR-Regular;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #202020;
  }
`;
