import styled from "styled-components";
import {Button} from "antd"

export const ButtonStyle = styled(Button)`
  height: 32px;
  border-radius: 11px;
  margin-left: 14px;
  padding: 4px 15px;
  background-color: rgb(164, 144, 57);
  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: rgb(255, 255, 255);
  border: none;

  :hover,
  :focus {
    background-color: rgb(164, 144, 57);
    color: #ffffff;
    border: none;
    opacity: 0.8;
  }

  @media screen and (max-width: 1024px) {
    min-width: 60px;
    height: 40px;
  }
  @media screen and (max-width: 768px) {
    min-width: 60px;
    height: 40px;
    margin: 0;
  }
`;