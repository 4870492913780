import styled from "styled-components";

export const Container = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #989898;

  .ant-btn {
    min-width: 40px;
    height: 35px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    font-family: NotoSansKR-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
`;
