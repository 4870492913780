import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as S from "./ButtonDownloadImg.styles";

const ButtonDownloadImg = ({ handleOnClick }) => {
  return (
    <S.Container>
      <Button
        type="text"
        icon={<DownloadOutlined size={32} />}
        size={"small"}
        onClick={handleOnClick}
      ></Button>
    </S.Container>
  );
};

export default ButtonDownloadImg;
