export const SUCCESS = ["Sold", "Not Sold", "Withdraw"];
export const BID_CLASS = ["ABOVE", "WITHIN", "BELOW", "UNSOLD", "W/D"];
export const MATERIAL = [
  "Painting",
  "Works on Paper",
  "Photo",
  "Sculpture",
  "NFT",
  "Edition",
  "Decorative Art",
  "Furniture&Design",
  "Wine&Whisky&Sprits",
  "Watch&Jewelry&Handbags",
  "Books&Manuscripts",
  "Others",
];
export const NATIONAL = [
  "Korean",
  "Japanese",
  "American",
  "French",
  "German",
  "British",
  "Chinese",
  "Vietnamese",
  "Italian",
  "Spanish",
  "Swiss",
  "Russian",
];
export const AUC_COM = [
  "아이옥션",
  "칸옥션",
  "케이옥션",
  "서울옥션",
  "아트데이옥션",
  "에이옥션",
  "마이아트옥션",
  "라이즈아트옥션",
  "토탈아트옥션",
  "Phillips",
  "Sothebys",
  "Christies",
];
export const ON_OFF_LIST = [
  { label: "On-Line", value: "online" },
  { label: "Off-Line", value: "offline" },
];
export const ON_OFF_MAP = {
  online: "On-Line",
  offline: "Off-Line",
};
export const CERTIFICATION_LIST = [
  { label: "Yes", value: "online" },
  { label: "No", value: "offline" },
];
export const CERTIFICATION_MAP = {
  0: "No",
  1: "Yes",
};
export const LOCAL = ["국내", "해외"];

export const NAME_FILTERS = {
  success: "판매여부",
  bidClass: "SORTING_AUCTION_RESULTS",
  pricetp: "가격 종류",
  price: "PRICE",
  material: "MATERIAL_TYPE",
  materialSearch: "MATERIAL_SEARCH",
  // date: "거래일",
  transactDate: "DAY_TRADING",
  mfgDate: "CRAFTING_YEAR",
  height: "HEIGHT",
  width: "WIDTH",
  sizeTable: "HO_NUMBER",
  title: "TITLE",
  // auc: "옥션사",
  company: "BIDDING_COMPANY",
  location: "LOCATION",
  locationSearch: "지역 검색",
  onOff: "ON_OFF",
  certification: "인증 및 감정서",
  auctionName: "AUCTION_NAME",
  artistBirth: "TIME_OF_BIRTH_YEAR",
  nationality: "NATIONALITY",
  nationalitySearch: "국적 검색",
  materialKind: "MATERIAL_TYPE",
  mainColor: "MAIN_COLOR",
  mainColorSearch: "MAIN_COLOR_SEARCH",
  method: "Method",
  series: "Series",
  preference: "Preference",
  identicalRecord: "Identical Record"
};

export const BID_FILTER = {
  transactDate: "TRANSACTION_TIME",
  company: "BIDDING_COMPANY",
  area: "LOCATION",
  material: "MATERIAL",
  birthAndDeath: "TIME_OF_BIRTH_YEAR",
  nationality: "NATIONALITY",
  mainColor: "MAIN_COLOR"
};

export const PRICE_MAP = {
  hammer_price: "낙찰가",
  selling_price: "판매가",
  estimate_min: "낮은추정가",
  estimate_max: "높은추정가",
};
