import fileDownload from "js-file-download";
import debounce from "lodash.debounce";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { exportExcelBidApi, totalBidApi } from "../../apis/auth";
import { HTTP_STATUS_CODE } from "../../common/constants/httpsConstant";
import FilterForm3 from "../../components/filterForm3/FilterForm";
import TotalBidDataTable from "../../components/totalBid/TotalBidDataTable";
import Button from "../../containers/button/Button";
import Title from "../../containers/title/Title";
import { formatDataFilter } from "../../utils/format/formatDataFilter";
import { addParamUrl, getParamUrlValue } from "../../utils/url";
import * as S from "./TotalBid.style";
import { useDispatch } from "react-redux";
import types from "../../store/actions/types";

const getDefaultValue = (value) =>
  typeof value === "string" ? [value] : value;

const TotalBid = () => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [dataTotalBid, setDataTotalBid] = useState([]);
  const [filterData, setFilterData] = useState({
    field: getParamUrlValue(location, "field"),
    order: getParamUrlValue(location, "order"),
    page: Number(getParamUrlValue(location, "page") ?? 1),
    size: Number(getParamUrlValue(location, "size") ?? 20),
    materialKind: getDefaultValue(getParamUrlValue(location, "materialKind")),
    materialSearch: getParamUrlValue(location, "materialSearch"),
    nationality: getDefaultValue(getParamUrlValue(location, "nationality")),
    nationalitySearch: getParamUrlValue(location, "nationalitySearch"),
    transactDate: getDefaultValue(
      getParamUrlValue(location, "transactDate")
    ) ?? [
      moment(`01/01/${new Date().getFullYear()}`).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
    ],
    artistBirth: getDefaultValue(getParamUrlValue(location, "artistBirth")),
    company: getDefaultValue(getParamUrlValue(location, "company")),
    location: getDefaultValue(getParamUrlValue(location, "location")),
    locationSearch: getParamUrlValue(location, "locationSearch"),
    // mainColor: getDefaultValue(getParamUrlValue(location, "mainColor")),
    // mainColorSearch: getParamUrlValue(location, "mainColorSearch")
  });
  const [totalElement, setTotalElement] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  /**
   *  used to get data total bid
   * @param {requestParams} - value form and page current
   * @returns {promise}
   */
  const getDataTotalBid = async (requestParams = {}) => {
    dispatch({type: types.SET_LOADING, payload: {isLoading: true}});
    const { totalElement, ...resParams } = requestParams;
    addParamUrl(history, resParams);
    setLoading(true);

    const payload = formatDataFilter(resParams);
    const response = await totalBidApi(payload);
    if (!response?.result) {
      if (Object.values(HTTP_STATUS_CODE).includes(response.err)) {
        setError(response.err_desc);
      } else {
        history.push("/login");
      }
    } else {
      setDataTotalBid(response.resp);
      setTotalElement(response?.totalElement);
      setError("");
    }
    setTimeout(() => {
      setLoading(false);
    }, 10);

    dispatch({type: types.SET_LOADING, payload: {isLoading: false}});
  };

  /**
   * event when change pagination
   * @param {*} values
   * @returns{void}
   */
  const handleChangePageCurrent = (values) => {
    const { pageSize, ...rest } = values;
    setFilterData({ ...filterData, ...rest });
  };

  const handleValueChangeFilter = (values) => {
    setFilterData({ ...filterData, ...values, page: 1 });
  };

  const handleValueChangeSearchDebounce = debounce(async (value) => {
    setFilterData({ ...filterData, querySearch: value });
  }, 800)

  const handleExcelDownload = async () => {
    const { page, size, ...resParams } = filterData;
    dispatch({type: types.SET_LOADING, payload: {isLoading: true}});
    const filename =
      "Top-Ranker".toLowerCase() +
      "_" +
      moment(new Date()).format("YYYYMMDD_HHmmss") +
      ".xlsx";
    const resp = await exportExcelBidApi(resParams);
    if (resp) {
      const blobData = new Blob([resp], {
        type: "application/json",
      });
      fileDownload(blobData, filename);
    }
    dispatch({type: types.SET_LOADING, payload: {isLoading: false}});
  };

  /**
   * get data search auction when change page
   */
  useEffect(() => {
    getDataTotalBid({
      ...filterData,
    });
  }, [JSON.stringify(filterData)]);

  const onChangeSorter = (pagination, filters, sorter, extra) => {
    let newField = sorter.columnKey;
    if (extra.action === "sort") {
      switch (sorter.field) {
        case "artist":
          newField = "artist_eng";
          break;
        case "artistBirth":
          newField = "artist_birth";
          break;
        case "artistDeath":
          newField = "artist_death";
          break;
        case "totalLot":
          newField = "total_lot";
          break;
        case "winLot":
          newField = "win_lot";
          break;
        case "bidRate":
          newField = "bid_rate";
          break;
        case "total_winbid":
          newField = "total_bid";
          break;
        default:
          break;
      }
      const filterDataSortChange = {
        order:
          sorter.order === "ascend"
            ? "asc"
            : sorter.order === "descend"
            ? "desc"
            : null,
        field: !!sorter.order ? newField : null,
      };
      setFilterData({ ...filterData, ...filterDataSortChange });
    }
  };

  return (
    <S.ContainerAll>
      <Title>Top Ranker</Title>
      <FilterForm3
        searchName
        onValueChangeFilter={handleValueChangeFilter}
        onValueChangeSearch={handleValueChangeSearchDebounce}
        // onValueChangeFilter={getDataTotalBid}
        className={"pd-5"}
      />
      <S.Container>
        <div className="paging">
          <S.SelectOptions
            defaultValue={20}
            value={filterData.size}
            onChange={(value) =>
              handleChangePageCurrent({ page: 1, size: value })
            }
            options={[
              {
                value: 20,
                label: "20 / page",
              },
              {
                value: 50,
                label: "50 / page",
              },
              {
                value: 100,
                label: "100 / page",
              },
            ]}
          />
          <Button
            onClick={handleExcelDownload}
            disabled={totalElement <= 0}
            style={{ marginLeft: "0" }}
          >
            {t("DOWNLOAD_EXCEL_ORTHER")}
          </Button>
        </div>

        {error ? (
          <h2 style={{ textAlign: "center" }}>{error}</h2>
        ) : (
          <TotalBidDataTable
            data={dataTotalBid}
            pageTable={{
              size: filterData.size,
              page: filterData.page,
              totalElement: totalElement,
            }}
            onChangePageCurrent={handleChangePageCurrent}
            load={loading}
            onChangeSorter={onChangeSorter}
            sorterData={{
              field: filterData.field,
              order: filterData.order,
            }}
          />
        )}
      </S.Container>
    </S.ContainerAll>
  );
};

export default TotalBid;
