import styled from "styled-components";
import { Button } from "antd";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;

  .ant-space {
    gap: 10px !important;
  }
`;

export const ContainerInner = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

export const ButtonFilter = styled(Button)`
  min-width: 80px;
  background-color: #e5e8e7;
  border: none;
  outline: none;
  height: 45px;
  line-height: 26px;

  padding: 0;

  span {
    font-family: NotoSansKR-Regular;
    color: #202020;
    font-size: 13px;
  }

  &:hover {
    background-color: #e5e8e7;
    opacity: 0.7;
  }

  &.item-active {
    background-color: #a49039;
    span {
      color: #fff;
    }
  }

  @media screen and (max-width: 425px) {
    min-width: 65px;
  }
`;
