import styled, { css } from "styled-components";

export const Container = styled.div`
  @keyframes ani {
    0% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
    }
  }

  width: 100%;
  z-index: 10;
  flex-grow: 1;

  ${({ isLoading }) =>
    isLoading
      ? css`
          background-color: #ffffffff;
          position: absolute;
          top: 26px;
          z-index: 1;
          left: 0;
        `
      : ""}
`;
