import styled, {css} from "styled-components";

export const WrapToal = styled.div`
  font-family: NotoSansKR-Medium;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #989898;

  span {
    margin-right: 5px;
  }
`;

export const ResultContent = styled.div`
`;

export const WrapChart = styled.div`
  margin-top: 30px;
`;

export const BoxItem = styled.div`
  
  @media screen and (max-width: 992px) {
    padding: 30px 16px 0;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
  }

  canvas {
    height: 350px !important;
    object-fit: contain;
  }
`;

export const BoxInner = styled.div`
  background-color: white;
  border: solid 1px #e5e8e7;
  border-radius: 45px;
  padding: 16px 16px 0;
  height: 408px;
  width: 100%;
  text-align: center;
  align-items: center;
  white-space: normal;

  ${({doughnut}) => doughnut ? css`
    canvas {
      height: 380px !important;
      object-fit: contain;
    }
  ` : ''}

  ${({customHeight}) => customHeight ? css`
    & > div:first-child {
      height: unset !important;
    }
  ` : ''}
`;

export const Title = styled.div`
  width: 100%;
  margin-top: 20px;
  font-family: NotoSansKR-Bold;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #202020;
  word-break: keep-all;
`;

export const Group = styled.div`
  padding: 45px 0 0;
`;

export const BoxGray = styled.div`
  border-radius: 45px;
  background-color: #f5f5f5;
`;

export const TitleMainGroup = styled.div`
  font-family: NotoSansKR-Bold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #202020;
  margin-bottom: 14px;
`;

export const TextGroup = styled.div`
  font-family: NotoSansKR-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #6e6e6e;

  &.ml-60 {
    margin-left: 60px;
  }
`;