import { Checkbox, Input } from "antd";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TfiSearch } from "react-icons/tfi";
import { ContainerInput, SpaceStyle } from "../filterForm/FilterForm.style";

const styleLayout = {
  minWidth: 150,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: 8,
};

const CheckboxRender = ({ options = [], inputSearch = false, name, nameSearch="materialSearch" }) => {
  const { control } = useFormContext();

  const handleKeyDown = (e) => {
    if (e.key.match(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|.<>\?~\d]/g) && nameSearch === "mainColorSearch") {
      e.preventDefault();
    }
  };

  return (
    <SpaceStyle direction="vertical">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox.Group {...field} options={options} style={styleLayout} />
        )}
      />

      {inputSearch && (
        <Controller
          name={nameSearch}
          control={control}
          render={({ field }) => (
            <ContainerInput className="material-input">
              <Input
                {...field}
                size="small"
                placeholder={nameSearch === "mainColorSearch" ? "주조색 찾기" : "재료명 입력"}
                allowClear
                id="material_search"
                onKeyPress={handleKeyDown}
              />
              <TfiSearch className="icon-search" />
            </ContainerInput>
          )}
        />
      )}
    </SpaceStyle>
  );
};

export default CheckboxRender;
